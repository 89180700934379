import {Component} from '@angular/core';
import {Router} from '@angular/router';

import {Feature} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/feature_pb';

import {ICP_LAYER_ID} from '../../constants/layer';
import {QUERY_PARAMS, ROUTE} from '../../constants/paths';
import {
  AnalyticsService,
  EventActionType,
  EventCategoryType,
} from '../../services/analytics_service';
import {ConfigService} from '../../services/config_service';
import {LayersService} from '../../services/layers_service';
import {getFeederName} from '../../utils/feature';

/**
 * The multi-marker details that should be passed to this component.
 */
export interface MultiMarkerDetailsInput {
  feature: Feature;
  iconUrl: string;
  layerId: string;
}

const MISSING_EVENT_LABEL = 'Missing layer name';

/**
 * Component for displaying multi-marker details.
 */
@Component({
  templateUrl: './multi_marker_details.ng.html',
  styleUrls: ['./multi_marker_details.scss'],
})
export class MultiMarkerDetails {
  details: MultiMarkerDetailsInput[] = [];

  constructor(
    private readonly analyticsService: AnalyticsService,
    private readonly layersService: LayersService,
    private readonly router: Router,
    private readonly configService: ConfigService,
  ) {}

  routeTo(feature: Feature, layerId: string) {
    this.analyticsService.sendEvent(EventActionType.FEATURE_SELECTED, {
      event_category: EventCategoryType.MAP,
      event_label: this.layersService.getLayerName(layerId) || MISSING_EVENT_LABEL,
    });
    if (layerId === ICP_LAYER_ID && this.configService.solar2Enabled) {
      this.router.navigate([ROUTE.FEEDER_DETAILS_MAP, encodeURIComponent(getFeederName(feature))], {
        queryParams: {
          [QUERY_PARAMS.FEATURE_ID]: feature.id,
          [QUERY_PARAMS.LAYER_ID]: layerId,
        },
      });
    } else {
      this.router.navigate([ROUTE.MAP, layerId, feature.id], {
        queryParams: {
          [QUERY_PARAMS.FEATURE_ID]: feature.id,
        },
      });
    }
  }
}
