import {NguCarouselConfig} from '@ngu/carousel';
import {BehaviorSubject, Subject} from 'rxjs';
import {first, map, takeUntil} from 'rxjs/operators';

import {AfterViewInit, ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';

import {Image} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/image_pb';

import {IMAGE_DATE_SORT_RECENT_FIRST} from '../constants/image';
import {PhotosService} from '../services/photos_service';
import {StaticSortableImageField, sortImages} from '../utils/sort';

/**
 * Component for rendering image entry in feature timeline.
 */
@Component({
  selector: 'timeline-image',
  templateUrl: './timeline_image.ng.html',
  styleUrls: ['./timeline_image.scss'],
})
export class TimelineImage implements OnInit, OnDestroy, AfterViewInit {
  @Input() featureId!: string;

  imageUrls = new BehaviorSubject<string[]>([]);

  readonly carouselConfig: NguCarouselConfig = {
    // Slides to show on different screen sizes.
    grid: {xs: 1, sm: 1, md: 1, lg: 1, all: 0},

    // Used to indicate the number of slides.
    point: {
      visible: true,
    },

    // Advance one slide at a time.
    slide: 1,

    // Loop back when auto-advancing.
    loop: true,
  };

  private readonly destroyed = new Subject<void>();

  /**
   * Constructs timeline image component. We need ChangeDetectorRef to
   * explicitly trigger change detection cycle because we inject the data from
   * carousel component and its data is loaded and updated asynchronously.
   * TODO(halinab): check if there is any way we can work around ngu-carousel
   * state update without forcing change detection.
   */
  constructor(
    private readonly changeDetector: ChangeDetectorRef,
    private readonly photosService: PhotosService,
  ) {}

  ngOnInit() {
    if (this.featureId) {
      this.getFeatureImages();
    }
  }

  ngOnDestroy() {
    this.destroyed.next();
    this.destroyed.complete();
  }

  ngAfterViewInit() {
    // This will force the change detection after ngu-carousel component we use
    // has updated it's state.
    this.changeDetector.detectChanges();
  }

  getFeatureImages() {
    this.photosService
      .getFeatureImages(this.featureId)
      .pipe(
        first(),
        map((images: Image[]): string[] =>
          sortImages(
            StaticSortableImageField.UPLOADED_AT,
            IMAGE_DATE_SORT_RECENT_FIRST,
            images,
          ).map((image: Image): string => image.url),
        ),
        takeUntil(this.destroyed),
      )
      .subscribe((urls: string[]) => {
        this.imageUrls.next(urls);
      });
  }
}
