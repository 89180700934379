<div class="container">
  @for (detail of details; track detail) {
    <div class="row" (click)="routeTo(detail.feature, detail.layerId)">
      <img class="icon" [src]="detail.iconUrl" alt="icon" />
      <div>
        {{ detail.feature.name }}
      </div>
    </div>
  }
</div>
