import {BehaviorSubject, Observable, Subject, of} from 'rxjs';
import {catchError, filter, first, map, switchMap, take, takeUntil, tap} from 'rxjs/operators';

import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';

import {Image} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/image_pb';

import {AnnotationSource, LABELS} from '../../constants/annotations';
import {FilterField} from '../../constants/filters';
import {ASSETS_LAYER_ID, AUTOTOP_LAYER_ID, DEFECTS_LAYER_ID} from '../../constants/layer';
import {QUERY_PARAMS, ROUTE} from '../../constants/paths';
import {AnnotationsService} from '../../services/annotations_service';
import {GalleryService} from '../../services/gallery_service';
import {LayersFilterService} from '../../services/layers_filter_service';
import {
  Annotation,
  AnnotationEditorMode,
  AnnotationType,
  PendingAnnotatedImage,
} from '../../typings/annotations';
import {FilterMap} from '../../typings/filter';
import {isPathContainsLayer, isTablePath} from '../../utils/path';
import {AnnotationTypeSelector} from './annotation_type_selector';

const RETURN_TO_MAP_TEXT = 'Return to map';
const RETURN_TO_TABLE_TEXT = 'Return to table';

const ML_GENERATED_FILTER_NAME = 'ML Generated labels';
const USER_GENERATED_FILTER_NAME = 'User Generated labels';

const DEFECT_FILTER_NAME = 'Defects';
const ASSET_FILTER_NAME = 'Assets';

const TOAST_DURATION_MS = 2500;

/**
 * Component for listing and selecting annotations shown in the image studio.
 */
@Component({
  selector: 'annotation-selector',
  templateUrl: './annotation_selector.ng.html',
  styleUrls: ['./annotation_selector.scss'],
  // Required in order to override the spinner's stroke color.
  encapsulation: ViewEncapsulation.None,
})
export class AnnotationSelector implements OnInit, OnDestroy {
  // Used to expose the AnnotationSource enum for use in the template.
  ANNOTATION_SOURCE = AnnotationSource;
  // Used to expose the AnnotationEditorMode enum for use in the template.
  EDITOR_MODE = AnnotationEditorMode;

  imageId = '';
  sourceUrl = '';
  returnToText = RETURN_TO_MAP_TEXT;
  // Annotation types hierarchy in the form of selector tree.
  annotationTypeSelector = new BehaviorSubject<AnnotationTypeSelector | null>(null);
  // Latest state of annotations and their visibility.
  annotationsSnapshot: Annotation[] = [];
  // Affects the selector to be displayed with relevant edit controls.
  editorMode: AnnotationEditorMode = AnnotationEditorMode.OFF;
  rootSelector: AnnotationTypeSelector;
  // Indicates whether upon finishing any edits on current page user should be
  // taken back to the originating url. If set to false, upon exiting the editor
  // user would stay in ImageStudio.
  returnOnExit = false;

  // Indicates whether the image has not yet been uploaded and instead comes
  // from the pending store. This is the case when user tries to edit the not
  // yet uploaded image as part of upload process.
  isNewUpload = false;

  // Indicates whether the changes on image have not yet been saved.
  savingIsInProgress = false;

  private readonly destroyed = new Subject<void>();

  // All possible labels that can be applied.
  labels = LABELS.sort((a, b) => a.label.localeCompare(b.label));

  constructor(
    private readonly annotationsService: AnnotationsService,
    private readonly galleryService: GalleryService,
    private readonly layersFilterService: LayersFilterService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly snackBar: MatSnackBar,
  ) {
    this.listenForSourceUrlUpdates();
    this.rootSelector = this.buildTypeSelector();
  }

  ngOnInit() {
    this.init();
  }

  ngOnDestroy() {
    this.galleryService.setEditorMode(AnnotationEditorMode.OFF);
    this.destroyed.next();
    this.destroyed.complete();
  }

  // Initializes the necessary listeners and state.
  init() {
    this.galleryService
      .getIsNewUpload()
      .pipe(takeUntil(this.destroyed))
      .subscribe((isNewUpload: boolean) => {
        this.isNewUpload = isNewUpload;
      });

    this.listenForImageUpdates();

    this.galleryService
      .getEditorMode()
      .pipe(takeUntil(this.destroyed))
      .subscribe((mode: AnnotationEditorMode) => {
        this.editorMode = mode;
      });
  }

  goBack() {
    const routePath = isTablePath(this.sourceUrl) ? ROUTE.TABLE : ROUTE.MAP;
    this.router.navigate([
      routePath,
      this.composeLayerId(routePath),
      this.galleryService.featureId,
    ]);
  }

  switchToAnnotationsEditor() {
    this.galleryService.setEditorMode(AnnotationEditorMode.DRAW);
  }

  setEditing(isOn: boolean) {
    this.galleryService.setEditorMode(isOn ? AnnotationEditorMode.DRAW : AnnotationEditorMode.PAN);
  }

  private composeLayerId(routePath: string): string {
    if (isPathContainsLayer(routePath, ROUTE.ASSETS, this.sourceUrl)) {
      return ASSETS_LAYER_ID;
    } else if (isPathContainsLayer(routePath, ROUTE.AUTOTOP, this.sourceUrl)) {
      return AUTOTOP_LAYER_ID;
    }
    return DEFECTS_LAYER_ID;
  }

  toggleGroupVisibility(selector: AnnotationTypeSelector, isChecked: boolean) {
    this.toggleAnnotationsBySelector(selector, isChecked);
    this.toggleSelectorNode(selector, isChecked);
  }

  toggleAnnotation(annotation: Annotation, isChecked: boolean) {
    annotation.isHidden = !isChecked;
    if (!isChecked) {
      // Reset the preferred visibility on explicit user action.
      annotation.isExplicitlyVisible = false;
    }
    this.updateAnnotationsState();
  }

  toggleAnnotationsBySelector(selector: AnnotationTypeSelector, isChecked: boolean) {
    if (selector.annotations.length === 0) {
      return;
    }
    for (const annotation of selector.annotations) {
      annotation.isHidden = !isChecked;
    }
    this.updateAnnotationsState();
  }

  getAnnotationBoxType(annotation: Annotation) {
    const classPrefix = annotation.info?.isMachineGenerated ? 'ml' : 'user';
    const isDefect = annotation.info?.label?.type === AnnotationType.DEFECT;

    return isDefect ? `${classPrefix}-defect-box` : `${classPrefix}-asset-box`;
  }

  allAnnotationsSelected(selector: AnnotationTypeSelector): boolean {
    if (selector.annotations.length === 0) {
      return false;
    }
    return selector.annotations.every((item) => !item.isHidden);
  }

  someAnnotationsSelected(selector: AnnotationTypeSelector): boolean {
    if (selector.annotations.length === 0) {
      return false;
    }
    const selectedLength = selector.annotations.filter((item) => !item.isHidden).length;
    return selectedLength > 0 && selectedLength < selector.annotations.length;
  }

  save() {
    // Skip saving pending annotations for not yet uploaded images and instead
    // let annotations be saved as part of a general upload flow.
    if (this.isNewUpload) {
      this.savingIsInProgress = false;
      this.galleryService.setEditorMode(AnnotationEditorMode.OFF);
      this.returnToPreviousPage();
      return;
    }
    this.savingIsInProgress = true;
    this.annotationsService
      .saveAnnotationsForImage(this.imageId)
      .pipe(
        catchError((error: Error) => {
          console.error(`Failed to save annotations. ${error.message}`);
          this.snackBar.open('Failed to save annotations for image.', '', {
            duration: TOAST_DURATION_MS,
          });
          return of(null);
        }),
        take(1),
      )
      .subscribe(() => {
        this.savingIsInProgress = false;
        this.galleryService.setEditorMode(AnnotationEditorMode.OFF);
        if (this.returnOnExit) {
          this.returnToPreviousPage();
        }
      });
  }

  cancel() {
    this.resetAnnotations();
    this.galleryService.setEditorMode(AnnotationEditorMode.OFF);
    if (this.returnOnExit) {
      this.returnToPreviousPage();
    }
  }

  nonEmptySelectors(selectors: AnnotationTypeSelector[]): AnnotationTypeSelector[] {
    return selectors.filter((item) => item.annotations.length > 0);
  }

  private returnToPreviousPage() {
    this.router.navigateByUrl(this.sourceUrl || ROUTE.MAP);
  }

  private resetAnnotations() {
    this.annotationsService.getSavedAnnotations(this.imageId).pipe(take(1)).subscribe();
  }

  private resetTypeSelector() {
    const nodes = [this.rootSelector];
    while (nodes.length > 0) {
      const current = nodes.pop()!;
      current.annotations = [];
      nodes.push(...current.subSelectors);
    }
  }

  private populateTypeSelector(annotations: Annotation[]) {
    for (const annotation of annotations) {
      this.rootSelector.annotations.push(annotation);
      const majorTypeTitle = annotation.info.isMachineGenerated
        ? ML_GENERATED_FILTER_NAME
        : USER_GENERATED_FILTER_NAME;
      const majorTypeNode = this.getAnnotationTypeSelectorNode(majorTypeTitle, this.rootSelector)!;
      majorTypeNode.annotations.push(annotation);
      const label = annotation?.info?.label?.label;
      const labelType = annotation?.info?.label?.type || AnnotationType.ASSET;
      if (!label || labelType === undefined) {
        console.error('Misconfigured annotation label');
      }
      const minorTypeTitle =
        labelType === AnnotationType.DEFECT ? DEFECT_FILTER_NAME : ASSET_FILTER_NAME;
      const minorTypeNode = this.getAnnotationTypeSelectorNode(minorTypeTitle, majorTypeNode)!;
      minorTypeNode.annotations.push(annotation);

      const subTypeNode = this.getAnnotationTypeSelectorNode(label!, minorTypeNode);
      if (subTypeNode) {
        subTypeNode.annotations.push(annotation);
      }
    }
  }

  private buildTypeSelector(): AnnotationTypeSelector {
    const rootSelector = this.getOrCreateAnnotationTypeSelectorNode('ROOT');
    const majorTypeNodeMl = this.getOrCreateAnnotationTypeSelectorNode(
      ML_GENERATED_FILTER_NAME,
      rootSelector,
    );
    const majorTypeNodeUser = this.getOrCreateAnnotationTypeSelectorNode(
      USER_GENERATED_FILTER_NAME,
      rootSelector,
    );
    this.populateMajorTypeNodes(majorTypeNodeMl);
    this.populateMajorTypeNodes(majorTypeNodeUser);

    const requestById = this.sourceUrl.includes(ASSETS_LAYER_ID)
      ? ASSETS_LAYER_ID
      : DEFECTS_LAYER_ID;

    this.layersFilterService
      .getFilterMap(requestById)
      .pipe(takeUntil(this.destroyed))
      .subscribe((filters: FilterMap) => {
        // Pre-select the labels user picked in annotation filters.
        // Skip if nothing was pre-selected, make defects visible by default.
        const appliedLabels = filters[FilterField.ANNOTATION_INCLUDE] || new Set([]);
        if (appliedLabels.size === 0) {
          return;
        }
        for (const selector of this.getAllSelectors(rootSelector.subSelectors)) {
          this.toggleSelectorNode(selector, appliedLabels.has(selector.title));
        }
      });

    return rootSelector;
  }

  private populateMajorTypeNodes(majorSelectorNode: AnnotationTypeSelector) {
    const minorTypeNodeAssets = this.getOrCreateAnnotationTypeSelectorNode(
      ASSET_FILTER_NAME,
      majorSelectorNode,
    );
    const minorTypeNodeDefects = this.getOrCreateAnnotationTypeSelectorNode(
      DEFECT_FILTER_NAME,
      majorSelectorNode,
    );
    for (const label of this.labels) {
      const parent =
        label.type === AnnotationType.ASSET ? minorTypeNodeAssets : minorTypeNodeDefects;
      this.getOrCreateAnnotationTypeSelectorNode(label.label, parent);
    }
    // Defects are visible by default.
    this.toggleSelectorNode(minorTypeNodeDefects, true);
  }

  private getAnnotationTypeSelectorNode(
    name: string,
    root: AnnotationTypeSelector,
  ): AnnotationTypeSelector | null {
    const nodes = [root];
    while (nodes.length > 0) {
      const current = nodes.pop()!;
      if (current.title === name) {
        return current;
      }
      nodes.push(...current.subSelectors);
    }
    return null;
  }

  private getOrCreateAnnotationTypeSelectorNode(
    name: string,
    parent: AnnotationTypeSelector | null = null,
  ): AnnotationTypeSelector {
    const existing = parent?.subSelectors.find((node) => node.title === name) || null;
    if (existing) {
      return existing;
    }
    const node = {
      title: name,
      subSelectors: [],
      annotations: [],
      isChecked: false,
    };
    if (parent && !existing) {
      parent.subSelectors.push(node);
    }
    return node;
  }

  private toggleSelectorNode(selector: AnnotationTypeSelector, isChecked: boolean) {
    selector.isChecked = isChecked;
    const allChildSelectors = this.getAllSelectors(selector.subSelectors);
    for (const childSelector of allChildSelectors) {
      childSelector.isChecked = isChecked;
    }
  }

  private getAllSelectors(selectors: AnnotationTypeSelector[]): AnnotationTypeSelector[] {
    const all: AnnotationTypeSelector[] = [];
    return selectors.reduce((allSelectors, selector) => {
      return allSelectors
        .concat(selector)
        .concat(selector.subSelectors ? this.getAllSelectors(selector.subSelectors) : []);
    }, all);
  }

  private setUpInitialAnnotationsVisibility() {
    this.annotationsSnapshot.forEach((annotation) => {
      annotation.isHidden = true;
    });
    for (const selector of this.getAllSelectors(this.rootSelector.subSelectors)) {
      this.toggleAnnotationsBySelector(selector, selector.isChecked || false);
    }
    this.annotationsSnapshot.forEach((annotation) => {
      if (annotation.isExplicitlyVisible) {
        annotation.isHidden = false;
      }
    });
    this.updateAnnotationsState();
  }

  private updateAnnotationsState() {
    this.annotationsService.annotationsStateChanged.next([...this.annotationsSnapshot]);
  }

  private listenForImageUpdates() {
    this.galleryService.selectedImage
      .pipe(
        filter((image: Image | null) => image !== null),
        map((image: Image | null): Image => image!),
        tap(() => {
          this.annotationTypeSelector.next(null);
        }),
        switchMap(
          (image: Image): Observable<[Image, PendingAnnotatedImage]> =>
            this.annotationsService.getPendingAnnotations(image.id).pipe(
              map((annotationsState: PendingAnnotatedImage): [Image, PendingAnnotatedImage] => [
                image,
                annotationsState,
              ]),
              takeUntil(this.destroyed),
            ),
        ),
        takeUntil(this.destroyed),
      )
      .subscribe(([image, annotationsState]) => {
        this.imageId = image.id;
        this.resetTypeSelector();
        this.populateTypeSelector(annotationsState.annotations);
        this.annotationsSnapshot = this.rootSelector.annotations;
        this.setUpInitialAnnotationsVisibility();
        this.annotationTypeSelector.next(this.rootSelector);
      });
  }

  private listenForSourceUrlUpdates() {
    // This route fires one time and saves the source URL.
    this.route.queryParamMap
      .pipe(
        filter((queryParamMap: ParamMap) => !!queryParamMap.get(QUERY_PARAMS.SOURCE_URL)),
        map((queryParamMap: ParamMap): [string, boolean] => {
          return [
            queryParamMap.get(QUERY_PARAMS.SOURCE_URL)!,
            queryParamMap.get(QUERY_PARAMS.RETURN_ON_EXIT) === 'true',
          ];
        }),
        first(),
      )
      .subscribe(([sourceUrl, returnOnExit]) => {
        this.sourceUrl = sourceUrl;
        this.returnOnExit = returnOnExit;
        if (isTablePath(sourceUrl)) {
          this.returnToText = RETURN_TO_TABLE_TEXT;
        }
      });
  }
}
