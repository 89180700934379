import { Log } from "./log.js";
// DEFAULT EXPORT IS A LOG INSTANCE
export default new Log({
  id: '@probe.gl/log'
});
// LOGGING
export { Log } from "./log.js";
export { COLOR } from "./utils/color.js";
// UTILITIES
export { addColor } from "./utils/color.js";
export { leftPad, rightPad } from "./utils/formatters.js";
export { autobind } from "./utils/autobind.js";
export { LocalStorage } from "./utils/local-storage.js";
export { getHiResTimestamp } from "./utils/hi-res-timestamp.js";
import "./init.js";