import {FirebaseApp, getApps, initializeApp} from 'firebase/app';
import {
  GoogleAuthProvider,
  OAuthProvider,
  User,
  getAuth,
  getRedirectResult,
  signInWithRedirect,
} from 'firebase/auth';
import {Observable, Observer} from 'rxjs';

import {Injectable} from '@angular/core';
import {Router} from '@angular/router';

import {ROUTE} from '../constants/paths';
import {AnalyticsService, EventActionType, EventCategoryType} from '../services/analytics_service';
import {ConfigService} from '../services/config_service';

// import {LocalStorageService} from '../services/local_storage_service';

const GOOGLE_OAUTH2_PROFILE_SCOPE = 'https://www.googleapis.com/auth/userinfo.profile';

const GOOGLE_PROVIDER_ID = 'google.com';

/**
 * The auth providers available for the log in process.
 */
export enum AuthProviderType {
  // A generic OAuth/OIDC provider. Used to integrate with customers'
  // SSO systems.
  OAUTH,
  // Google login provider. Used to enable developers to log into
  // GridAware using corp GAIA accounts.
  GOOGLE,
}

/**
 * Service for authorizing users
 */
@Injectable()
export class AuthService {
  private app!: FirebaseApp;
  private routeUrl!: string;
  private errorObservable$!: Observable<string>;
  private errorObserver!: Observer<string>;

  private googleProvider: GoogleAuthProvider | null = null;
  private readonly oAuthProviders: OAuthProvider[] = [];

  constructor(
    private readonly analyticsService: AnalyticsService,
    private readonly configService: ConfigService,
    // private readonly localStorageService: LocalStorageService,
    private readonly router: Router,
  ) {
    this.errorObservable$ = new Observable<string>((observer: Observer<string>) => {
      observer.next('');
      this.errorObserver = observer;
    });
    if (!this.configService.authOverrideEnabled) {
      this.app = initializeApp(this.configService.firebaseConfig as object);
      this.initOAuth();
      this.initGoogleAuth();
    }
  }

  getRedirectionResult() {
    if (!this.googleProvider) {
      return;
    }
    const auth = getAuth(this.app);
    getRedirectResult(auth)
      .then((userCredential) => {
        if (!userCredential?.user) {
          this.sendLoginErrorEvent(`Missing user credential: ${JSON.stringify(userCredential)}`);
          this.router.navigateByUrl(ROUTE.LOGIN);
          return;
        }
        // Need the setTimeout because of an issue when router navigation is
        // called rapidly in succession.
        setTimeout(() => {
          this.router.navigateByUrl(ROUTE.MAP);
        });
      })
      .catch((error: Error) => {
        this.sendLoginErrorEvent(`'Error logging in:' ${error.message}`);
        this.router.navigateByUrl(ROUTE.LOGIN);
      });
  }

  oAuthSignInWithRedirection(providerIndex: number) {
    return signInWithRedirect(getAuth(this.app), this.oAuthProviders[providerIndex]).catch(
      (error: Error) => {
        this.sendLoginErrorEvent(`Error logging in with OAuth: ${error.message}`);
      },
    );
  }

  googleSignInWithRedirection() {
    if (!this.googleProvider) {
      return;
    }
    signInWithRedirect(getAuth(this.app), this.googleProvider).catch((error) => {
      this.sendLoginErrorEvent(`Error logging in with Google: ${error.message}`);
    });
  }

  /**
   * Returns true if Firebase is enabled for the application.
   */
  oAuthEnabled(): boolean {
    return this.configService.authEnabled;
  }

  /**
   * Get the current Firebase user, if present.
   *
   * @return A Firebase user object, if Firebase is
   * enabled and an account is logged in. Otherwise, null.
   */
  currentUserIfPresent(): User | null {
    return getApps().length > 0 ? getAuth(this.app).currentUser : null;
  }

  /**
   * Gets a Firebase Auth JWT for the current user, if one is logged in.
   * @return A promise that either contains a JWT string for the
   * currently logged-in user or else a rejected promise.
   */
  getToken(): Promise<string> {
    const user = this.currentUserIfPresent();
    if (user) {
      // this.localStorageService.writeLoginStatus(true);
      return user.getIdToken();
    }
    return Promise.reject(new Error('No Firebase user logged in'));
  }

  /**
   * Gets a header containing Firebase Auth JWT for the current user,
   * if one is logged in.
   * @return A promise that either contains auth headers for the
   * currently logged-in user or else a rejected promise.
   */
  getAuthHeaders(): Promise<{[key: string]: string}> {
    const headers: {[key: string]: string} = {};

    return this.getToken().then(
      (token) => {
        // Request extension conveying user's Firebase ID token. See
        // go/grpc-sidechannel for details on this header format.
        headers['x-goog-ext-275505673-bin'] = window.btoa(token);
        return headers;
      },
      () => headers,
    );
  }

  /**
   * Gets a header containing Firebase Auth JWT for the current user,
   * if one is logged in.
   * @return A promise that either contains auth headers for the
   * currently logged-in user or else a rejected promise.
   */
  getHttpHeaders(): Promise<Headers> {
    const headers = new Headers();

    return this.getToken().then(
      (token) => {
        headers.set('Authorization', `Bearer ${window.btoa(token)}`);
        return headers;
      },
      () => headers,
    );
  }

  /**
   * Adds an observer for changes to the Firebase user's sign-in state.
   *
   * @see https://firebase.google.com/docs/reference/js/firebase.auth.Auth.html#on-auth-state-changed
   */
  // This function is a wrapper for
  // firebase.auth.Auth.onAuthStateChanged, which is implemented in
  // JavaScript and thus doesn't have types.
  onAuthStateChanged(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    nextOrObserver: (u: any) => void,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    error?: any,
  ): () => void {
    if (getApps().length === 0) {
      this.sendAuthFailureEvent('Error auth: Firebase not initialized');
      nextOrObserver(null);
      return () => {};
    }
    return getAuth(this.app).onAuthStateChanged(nextOrObserver, error);
  }

  /**
   * Signs out the current Firebase user, if present.
   */
  signOut(): Promise<void> {
    // this.localStorageService.writeLoginStatus(false);
    return getApps().length === 0 ? Promise.resolve() : getAuth(this.app).signOut();
  }

  /**
   * Set the router Url.
   * @param url.
   */
  setUrl(url: string) {
    this.routeUrl = url;
  }

  /**
   * Get the Router url.
   * @return url.
   */
  getUrl() {
    return this.routeUrl;
  }

  /**
   * Set the Error Message.
   * @param message.
   */
  setErrorMessage(message: string) {
    if (this.errorObserver) {
      this.errorObserver.next(message);
    }
  }

  /**
   * Get the Error Meesage.
   * @return url.
   */
  getErrorMessage(): Observable<string> {
    return this.errorObservable$;
  }

  /**
   * Gets image profile url of logged in user.
   * @return user image url.
   */
  getUserImage(): string {
    return this.currentUserIfPresent()?.photoURL || '';
  }

  /**
   * Gets name of logged in user.
   * @return user name.
   */
  getUserName(): string {
    const user = this.currentUserIfPresent();
    if (!user || !user.displayName) {
      return 'Unknown user';
    }
    return user.displayName;
  }

  /**
   * Gets id of logged in user.
   * @return user id.
   */
  getUserId(): string {
    const user = this.currentUserIfPresent();
    if (!user) {
      throw new Error('Unable to examine identity of logged-in user.');
    }
    return user.uid;
  }

  isGoogleUser(): boolean {
    return this.currentUserIfPresent()?.providerData[0]?.providerId === GOOGLE_PROVIDER_ID;
  }

  private initGoogleAuth() {
    this.googleProvider = new GoogleAuthProvider();
    this.googleProvider.setCustomParameters({hd: 'google.com'});
    this.googleProvider.addScope(GOOGLE_OAUTH2_PROFILE_SCOPE);
  }

  private initOAuth() {
    if (this.oAuthEnabled()) {
      for (const firebaseProviderConfig of this.configService.firebaseProviderConfig) {
        const oauthProvider = new OAuthProvider(firebaseProviderConfig.id);
        oauthProvider.setCustomParameters(firebaseProviderConfig.customParameters);
        this.oAuthProviders.push(oauthProvider);
      }
    }
  }

  private sendAuthFailureEvent(label: string): void {
    console.error(label);
    this.analyticsService.sendEvent(EventActionType.AUTH_FAILURE, {
      event_category: EventCategoryType.ERROR,
      event_label: label,
    });
  }

  private sendLoginErrorEvent(label: string): void {
    console.error(label);
    this.analyticsService.sendEvent(EventActionType.LOGIN_ERROR, {
      event_category: EventCategoryType.ERROR,
      event_label: label,
    });
  }
}
