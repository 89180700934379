@if (imageSourceFilterEnabled) {
  <ng-container class="container">
    <div class="filter-header">Show map results</div>
    <div *ngFor="let option of options" class="filter-container">
      <div class="legend-item">
        <img src="{{ option.iconSrc }}" class="legend-icon" alt="{{ option.alt }}" />
        <span class="layer-name">{{ option.label }}</span>
      </div>
      <mat-checkbox
        class="image-association-filter-checkbox"
        [(ngModel)]="option.selected"
        (change)="onSelectedOptionsChanged()">
      </mat-checkbox>
    </div>
  </ng-container>
}
@if (!imageSourceFilterEnabled) {
  <ng-container class="container">
    <div class="legend-item">
      <img src="assets/images/image_untagged.svg" class="legend-icon" alt />
      <span class="layer-name">Without user images</span>
    </div>
    <div class="legend-item">
      <img src="assets/images/image_tagged.svg" class="legend-icon" alt />
      <span class="layer-name">With user images</span>
    </div>
  </ng-container>
}
