import {BehaviorSubject, Observable, ReplaySubject, Subject, combineLatest, of} from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  mergeMap,
  switchMap,
  take,
  takeUntil,
} from 'rxjs/operators';

import {Clipboard} from '@angular/cdk/clipboard';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';

import {LifecycleStage} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/common_pb';
import {Feature, Property} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/feature_pb';
import {Point} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/geometry_pb';
import {AnnotatedImage} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/image_annotation_pb';
import {Image} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/image_pb';
import {Layer_LayerType} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/layer_pb';
import {
  RelatedFeature,
  RelatedFeaturesGroup_RelatedFeatureRole as RelatedFeatureRole,
  RelatedFeaturesGroup,
} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/related_feature_pb';
import {Tag} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/tag_pb';

import {LABEL_BY_RELATED_ROLE} from '../constants/common';
import {
  ASSETS_LAYER_ID,
  CIRCUITS_LAYER_NAME,
  DEFECTS_LAYER_ID,
  SUNROOF_LAYER_ID,
} from '../constants/layer';
import {FEATURE_PARAM_KEY, LAYER_PARAM_KEY, QUERY_PARAMS, ROUTE} from '../constants/paths';
import {ThumbnailMetadata} from '../gallery/lightbox/lightbox';
import {AnalyticsService, EventActionType, EventCategoryType} from '../services/analytics_service';
import {ConfigService} from '../services/config_service';
import {DialogService} from '../services/dialog_service';
import {FeaturesService} from '../services/features_service';
import {GoogleMapsService} from '../services/google_maps_service';
import {LayersService} from '../services/layers_service';
import {MapService} from '../services/map_service';
import {NetworkService} from '../services/network_service';
import {PhotosService, ensureURL} from '../services/photos_service';
// import {
//   OfflineAssetsInfo,
//   OfflineAssetsService,
//   offlineAssetToFeature,
// } from '../services/offline_assets_service';
import {TagsService} from '../services/tags_service';
// import {FORM_DIALOG_WIDTH} from '../styles/constants';
import {StreetViewResponse} from '../typings/street_view';
import {buildRelatedFeatureString, groupRelatedFeaturesByLayerId} from '../utils/feature';
import {
  ImageUrlOptions,
  THUMBNAIL_SIZE_PX,
  convertGCSImageURL,
  getNewImageIndex,
  isGCSImageUrl,
} from '../utils/image';
import {removeSensitiveProperties} from '../utils/properties';
import {sortImageGroup} from '../utils/sort';

// import {UploadFormDialog} from '../upload/upload_form_dialog';

interface UrlParams {
  layerId: string;
  featureId: string;
}

// Used in the template for displaying groups of related features.
interface RelatedFeatureDisplay {
  layerName: string;
  relatedFeatures: RelatedFeature[];
}

const MS_PER_SECOND = 1000;
const DEFAULT_RELATED_FEATURES_TITLE = 'Related features';
const OFFLINE_LAYER_NAME = 'Assets';

@Component({
  selector: 'feature-details',
  templateUrl: './feature_details.html',
  styleUrl: './feature_details.scss',
})

/**
 * Component for rendering Feature Details Page. Reads a layer key and feature
 * from the URL and displays the details of the feature.
 */
export class FeatureDetails implements OnInit, OnDestroy {
  private heroSatellite!: ElementRef<HTMLElement>;
  private streetViewContainer!: ElementRef<HTMLElement>;

  // Set satelliteContent will fire when the ViewChild enters the DOM. This is
  // required because the ViewChild is wrapped in an *ngIf Directive.
  @ViewChild('heroSatellite', {static: false})
  set satelliteContent(satelliteRef: ElementRef) {
    if (!satelliteRef || this.heroSatellite) return;
    this.heroSatellite = satelliteRef;
    if (this.streetViewContainer) {
      this.initSatelliteStreetViewListener();
    }
  }

  @ViewChild('streetViewContainer', {static: false})
  set streetViewContent(streetViewRef: ElementRef) {
    if (!streetViewRef || this.streetViewContainer) return;
    this.streetViewContainer = streetViewRef;
    if (this.heroSatellite) {
      this.initSatelliteStreetViewListener();
    }
  }

  // Function queue stores functions that will run after ViewChilds have entered
  // the DOM.
  private readonly satelliteStreetViewTasks = new ReplaySubject<Function>();
  private readonly destroyed = new Subject<void>();
  // Used to render the table columns

  features = new ReplaySubject<Feature>(1);
  feature: Feature | null = null;
  lastUpdatedAt: Date | null = null;
  featureInactive: boolean = false;
  featureProperties: Property[] = [];
  thumbnailMetadata: ThumbnailMetadata | null = null;
  annotationCountByImageId = new Map<string, number>();
  address$: Observable<string | null> = new BehaviorSubject(null);
  relatedFeatureGroups: RelatedFeaturesGroup[] = [];
  // Related features grouped by layer name. This is used for displaying a list
  // of related features grouped by layer.
  relatedFeatureDisplays: RelatedFeatureDisplay[] = [];
  layerId: string = '';
  layerName: string = '';
  image: Image | null = null;
  // A feature's images if they exist.
  images: Image[] = [];
  // The index of the feature's image which is being displayed.
  currentImageIndex = 0;
  location!: google.maps.LatLngLiteral;
  tags = new Set<string>();
  // Display tags and allow them to be added for this layer. Currently, tags are
  // only allowed on the defect layer.
  tagsEnabled = false;
  // Display upload button. Currently, only allowed for asset layer.
  uploadsEnabled = false;
  // Display edit icon. Currently, edits are only allowed for the defect layer
  // if upload view is enabled.
  editsEnabled = false;
  commentFormVisible = false;
  isOffline = false;
  // TODO(b/288092797): Remove thumbnailCarouselEnabled when enabling gallery
  // thumbnail feature.
  thumbnailCarouselEnabled = false;
  // TODO(b/305203794): Remove feature flag.
  breadcrumbsEnabled = false;
  // Specify a thumbnail size slightly larger than the thumbnail height that
  // balances quality versus image load time.
  thumbnailUrlOptions: ImageUrlOptions = {
    height: THUMBNAIL_SIZE_PX,
    width: THUMBNAIL_SIZE_PX,
  };
  // Show Solar insights if feature flag enabled and viewing a circuit.
  // TODO(b/323415506): Remove old flag.
  showSolarInsights = false;

  constructor(
    private readonly analyticsService: AnalyticsService,
    private readonly changeDetectionRef: ChangeDetectorRef,
    private readonly clipboard: Clipboard,
    private readonly configService: ConfigService,
    private readonly dialogService: DialogService,
    private readonly featuresService: FeaturesService,
    private readonly googleMapsService: GoogleMapsService,
    private readonly layersService: LayersService,
    private readonly mapService: MapService,
    private readonly networkService: NetworkService,
    // private readonly offlineAssetsService: OfflineAssetsService,
    private readonly photosService: PhotosService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly snackBar: MatSnackBar,
    private readonly tagsService: TagsService,
  ) {}

  ngOnInit() {
    this.initFeatureFlags();
    this.layersService.getAllLayersMetadata().pipe(take(1), takeUntil(this.destroyed)).subscribe();
    this.networkService
      .getOffline$()
      .pipe(takeUntil(this.destroyed))
      .subscribe((offline: boolean) => {
        this.isOffline = offline;
      });
    this.route.paramMap
      .pipe(
        distinctUntilChanged(),
        map((paramMap: ParamMap) => ({
          layerId: paramMap.get(LAYER_PARAM_KEY) as string,
          featureId: paramMap.get(FEATURE_PARAM_KEY) as string,
        })),
        switchMap((urlParams: UrlParams) => {
          const {layerId, featureId} = urlParams;
          this.layerId = layerId;
          this.setSolarInsights();

          return combineLatest([
            this.getFeature(layerId, featureId),
            this.getLayerVisibility(layerId),
          ]);
        }),
        takeUntil(this.destroyed),
      )
      .subscribe(([feature, isVisible]: [Feature | null, boolean]) => {
        if (!this.layerId || !feature?.id) {
          this.goToMap();
          return;
        }
        if (!isVisible) {
          console.warn(`Layer with ID ${this.layerId} is not visible`);
        }
        this.features.next(feature);
        this.feature = feature;
        this.setActions();
        this.setHeaderImage();
        this.setTags();
        this.setSolarInsights();
        this.layerName = this.isOffline
          ? OFFLINE_LAYER_NAME
          : this.layersService.getLayerName(this.layerId) || '';
        this.lastUpdatedAt = this.getLastUpdatedAt(feature);
        this.featureInactive = this.feature.lifecycleStage === LifecycleStage.INACTIVE;

        this.featureProperties = removeSensitiveProperties(this.feature.properties);

        // Filter out child image relations. Images are part of image
        // groups which are their own related feature group.
        this.relatedFeatureGroups = this.feature.relatedFeaturesGroups.filter(
          (group: RelatedFeaturesGroup) => group.role !== RelatedFeatureRole.CHILD_IMAGE,
        );
        const relatedFeaturesByLayerId = groupRelatedFeaturesByLayerId(this.relatedFeatureGroups);
        this.relatedFeatureDisplays = [];
        for (const [layerId, relatedFeatures] of relatedFeaturesByLayerId) {
          this.relatedFeatureDisplays.push({
            layerName: this.layersService.getLayerName(layerId) || '',
            relatedFeatures,
          });
        }
        // For offline features skip the geolocation and satellite
        // configuration.
        if (!this.isOffline) {
          feature.geometry?.geometry?.case === 'point'
            ? this.handlePointFeature()
            : this.hideSatellite();
        }
        this.changeDetectionRef.detectChanges();
      });
    // TODO(b/288092797): Remove thumbnailCarouselEnabled when enabling gallery
    // thumbnail feature.
    if (!this.thumbnailCarouselEnabled) {
      return;
    }
    this.route.paramMap
      .pipe(
        distinctUntilChanged(),
        map((paramMap: ParamMap) => paramMap.get(FEATURE_PARAM_KEY) as string),
        filter((featureId) => !!featureId),
        switchMap((featureId: string) => this.photosService.getFeatureImages(featureId)),
        takeUntil(this.destroyed),
      )
      .subscribe((images: Image[]) => {
        if (!images.length) {
          this.images = [];
          this.thumbnailMetadata = null;
          return;
        }
        this.images = sortImageGroup(images);
        this.currentImageIndex = 0;
        this.thumbnailMetadata = {
          totalImageCount: images.length,
          selectedImageIndex: 0,
          // annotationCount set in fetchAnnotationsAndUpdateLabelCount()
          annotationCount: null,
        };
        this.changeDetectionRef.detectChanges();
        // TODO(reubenn): Remove this nastiness. Annotations should either be
        // returned with images by default or fetched by feature ID so that
        // image IDs don't have to be fetched before fetching annotations.
        this.fetchAnnotationsAndUpdateLabelCount(images.map((image: Image): string => image.id));
      });
  }

  // TODO(reubenn): Remove this nastiness. Annotations should either be
  // returned with images by default or fetched by feature ID so that
  // image IDs don't have to be fetched before fetching annotations.
  fetchAnnotationsAndUpdateLabelCount(imageIds: string[]) {
    this.photosService
      .getAnnotatedImagesByIds(imageIds)
      .pipe(takeUntil(this.destroyed))
      .subscribe((annotatedImages: AnnotatedImage[]) => {
        if (!annotatedImages.length) {
          return;
        }
        for (const annotatedImage of annotatedImages) {
          this.annotationCountByImageId.set(
            annotatedImage.imageId,
            annotatedImage.annotations.length,
          );
        }
        // Update the current thumbnail with the annotation count.
        const imageId = this.images[this.currentImageIndex].id;
        if (!this.annotationCountByImageId.has(imageId)) {
          console.error(
            `Annotation count of current image with ID ${imageId} not found: annotationCountByImageId ${this.annotationCountByImageId}`,
          );
          return;
        }
        this.thumbnailMetadata = {
          ...this.thumbnailMetadata!,
          annotationCount: this.annotationCountByImageId.get(imageId)!,
        };
        this.changeDetectionRef.detectChanges();
      });
  }

  ngOnDestroy() {
    this.destroyed.next();
    this.destroyed.complete();
    this.googleMapsService.destroy();
  }

  refreshOnNewComment() {
    this.commentFormVisible = false;
    // Refresh feature-related panels e.g. timeline.
    this.features.next(this.feature || new Feature());
  }

  /**
   * Retrieves feature by ID from backend or, if offline mode is on, from
   * offline assets store.
   */
  getFeature(layerId: string, featureId: string): Observable<Feature | null> {
    // return this.isOffline
    //   ? this.getOfflineFeature(featureId)
    return this.featuresService.getFeature(layerId, featureId, false);
  }

  /**
   * Retrieves layer visibility from backend. Visibility check is skipped if
   * offline mode is on.
   */
  getLayerVisibility(layerId: string): Observable<boolean> {
    return this.isOffline ? of(true) : this.mapService.showLayer(layerId, true);
  }

  /**
   * Retrieves feature by ID from offline assets store.
   */
  // getOfflineFeature(featureId: string): Observable<Feature | null> {
  //   return this.offlineAssetsService.getAssetByID(featureId).pipe(
  //     first(),
  //     map((asset: OfflineAssetsInfo | null): Feature | null =>
  //       asset ? offlineAssetToFeature(asset) : null,
  //     ),
  //     takeUntil(this.destroyed),
  //   );
  // }

  private setSolarInsights() {
    if (!this.layerId) {
      return;
    }
    this.showSolarInsights =
      this.configService.solarEnabled &&
      this.layersService.getLayerName(this.layerId) === CIRCUITS_LAYER_NAME;
  }

  private setActions() {
    // Allow image uploads for assets in offline mode.
    this.uploadsEnabled =
      (this.isOffline && this.layerId === ASSETS_LAYER_ID) ||
      this.layersService.getLayerType(this.layerId) === Layer_LayerType.ASSETS;
    // Disable tags and edits in offline mode.
    this.tagsEnabled = this.shouldEnableTags();
    this.editsEnabled = this.shouldEnableEdits();
  }

  private setHeaderImage() {
    if (!this.feature?.headerImage) {
      this.image = null;
      return;
    }
    const headerImage = this.feature.headerImage;
    let imageURL;
    if (isGCSImageUrl(headerImage.url)) {
      convertGCSImageURL(headerImage.url)
        .pipe(takeUntil(this.destroyed))
        .subscribe({
          next: (url: string) => {
            imageURL = url;
          },
          error: (error) => {
            throw new Error(error);
          },
        });
    } else {
      imageURL = headerImage.url;
    }
    this.image = ensureURL(
      new Image({
        id: headerImage.id,
        url: imageURL,
        exifMetadata: headerImage.exifMetadata,
      }),
    );
  }

  /**
   * Set location, get street-view data and possibly update map
   * position.
   */
  private handlePointFeature() {
    const point = this.feature?.geometry?.geometry?.value as Point;
    if (!point?.location) {
      return;
    }
    const pointLocation = point.location;
    this.location = {
      lat: pointLocation.latitude,
      lng: pointLocation.longitude,
    };
    if (this.mapService.getShouldRepositionMap()) {
      this.mapService.setMapCenter(pointLocation);
      this.mapService.setShouldRepositionMap(false);
    }
    this.address$ = this.googleMapsService.getAddressFromLatLng(this.location);
    this.googleMapsService
      .getStreetViewData(this.location)
      .subscribe((streetViewResponse: StreetViewResponse | null) => {
        this.satelliteStreetViewTasks.next(() => {
          this.showSatellite();
        });
        if (streetViewResponse) {
          this.initStreetView(streetViewResponse);
        } else {
          // If Street View is not available show satellite in its place.
          this.googleMapsService.renderSatelliteMap(
            this.streetViewContainer.nativeElement,
            this.location,
          );
        }
      });
  }

  /**
   * Set up a subscription for streetView and satellite.
   */
  initSatelliteStreetViewListener() {
    // The functions that were passed to the replay subject that have to do
    // with showing satellite, street view, or updating photo can now be
    // executed because the DOM is ready.
    this.satelliteStreetViewTasks
      .pipe(takeUntil(this.destroyed))
      .subscribe((callback: Function) => {
        callback();
      });
    this.changeDetectionRef.detectChanges();
  }

  initStreetView(streetViewResponse: StreetViewResponse) {
    if (this.googleMapsService.shouldRenderStreetView(streetViewResponse)) {
      this.satelliteStreetViewTasks.next(() => {
        this.googleMapsService.renderStreetView(
          this.streetViewContainer.nativeElement,
          streetViewResponse.data,
          streetViewResponse.location,
          '',
        );
      });
    }
  }

  doUpdateTags(tagNames: Set<string>) {
    const tags = [...tagNames].map((t: string): Tag => new Tag({name: t}));
    this.featuresService
      .updateTags(this.layerId, this.feature!.id, tags)
      .pipe(
        mergeMap(() => this.tagsService.getTags(this.layerId, true)),
        take(1),
        takeUntil(this.destroyed),
      )
      .subscribe(() => {
        this.snackBar.open('Tags updated.', '', {duration: 2500});
      });
  }

  /**
   * Copies a feature's name to clipboard.
   */
  copyFeatureName() {
    if (!this.feature?.name) {
      // TODO(reubenn): Add some type of debug-time logging.
      return;
    }
    this.clipboard.copy(this.feature.name);
    this.snackBar.open(`${this.feature.name} copied to clipboard.`, '', {
      duration: 2500,
    });
  }

  private hideSatellite() {
    // Hide any satellite that may be in the DOM.
    this.satelliteStreetViewTasks.next(() => {
      this.heroSatellite.nativeElement.style.visibility = 'hidden';
    });
  }

  private showSatellite() {
    this.satelliteStreetViewTasks.next(() => {
      this.heroSatellite.nativeElement.style.visibility = 'visible';
      this.googleMapsService.renderSatelliteMap(this.heroSatellite.nativeElement, this.location);
    });
  }

  private getLastUpdatedAt(feature: Feature): Date | null {
    if (!feature.updatedAt || !feature.updatedAt!.seconds) {
      return null;
    }
    const updateMS = Number(feature.updatedAt!.seconds) * MS_PER_SECOND;
    return new Date(updateMS);
  }

  private shouldEnableTags(): boolean {
    return (
      (!this.isOffline && this.layerId === DEFECTS_LAYER_ID) || this.layerId !== SUNROOF_LAYER_ID
    );
  }

  private shouldEnableEdits(): boolean {
    return (
      !this.isOffline && this.layerId === DEFECTS_LAYER_ID && this.configService.uploadViewEnabled
    );
  }

  shouldEnableInspectionStatus(): boolean {
    return !this.isOffline && this.layerId === DEFECTS_LAYER_ID;
  }

  private setTags() {
    this.tags = new Set(this.feature!.tags.map((tag: Tag): string => tag.name));
  }

  roleTitle(role: RelatedFeatureRole): string {
    return LABEL_BY_RELATED_ROLE.get(role) || DEFAULT_RELATED_FEATURES_TITLE;
  }

  goToRelatedFeature(feature: RelatedFeature) {
    this.mapService.setShouldRepositionMap(true);
    this.router.navigate([ROUTE.MAP, feature.layerId, feature.id]);
  }

  relatedFeatureString(related: RelatedFeature, role: RelatedFeatureRole) {
    return buildRelatedFeatureString(related, role);
  }

  editDefect() {
    if (this.configService.uploadFormImprovementsEnabled) {
      // this.dialogService.render<UploadFormDialog, boolean>(UploadFormDialog, {
      //   maxWidth: FORM_DIALOG_WIDTH,
      //   width: FORM_DIALOG_WIDTH,
      //   data: {
      //     featureId: this.feature!.id,
      //     layerId: this.layerId,
      //     edit: true,
      //   },
      //   panelClass: 'form-dialog',
      // });
    } else {
      this.router.navigate([ROUTE.PHOTO_UPLOAD], {
        queryParams: {
          [QUERY_PARAMS.FEATURE_ID]: this.feature!.id,
          [QUERY_PARAMS.LAYER_ID]: this.layerId,
          [QUERY_PARAMS.EDIT]: true,
        },
      });
    }
  }

  private getImageId(): string {
    return this.thumbnailCarouselEnabled ? this.images[this.currentImageIndex].id : this.image!.id;
  }

  openLightbox() {
    this.analyticsService.sendEvent(EventActionType.LIGHTBOX_OPEN, {
      event_category: EventCategoryType.IMAGE,
      event_label: 'Feature page', // From whence the lightbox was opened.
    });
    const imageId = this.getImageId();
    this.router.navigate([ROUTE.LIGHTBOX], {
      queryParams: {
        [QUERY_PARAMS.LAYER_ID]: this.layerId,
        [QUERY_PARAMS.FEATURE_ID]: this.feature!.id,
        [QUERY_PARAMS.IMAGE_ID]: imageId,
        [QUERY_PARAMS.SOURCE_URL]: this.router.url,
      },
    });
  }

  editImage() {
    const queryParams: {[key: string]: string | boolean} = {
      [QUERY_PARAMS.IMAGE_ID]: this.getImageId(),
      [QUERY_PARAMS.SOURCE_URL]: this.router.url,
      [QUERY_PARAMS.LAYER_ID]: this.layerId,
      [QUERY_PARAMS.FEATURE_ID]: this.feature!.id,
      [QUERY_PARAMS.EDIT]: true,
      [QUERY_PARAMS.RETURN_ON_EXIT]: true,
    };
    this.router.navigate([ROUTE.LIGHTBOX], {queryParams});
  }

  goToMap() {
    this.router.navigateByUrl(ROUTE.MAP);
  }

  goToImageUpload() {
    const queryParams =
      // Pass the internal GA ID to pull all the feature details
      // from backend in online scenarios.
      {
        [QUERY_PARAMS.ASSET_ID]: this.feature!.id,
        // Also pass the external ID to associate
        // the upload in offline scenarios.
        [QUERY_PARAMS.EXT_ASSET_ID]: this.feature!.externalId,
      };
    this.router.navigate([ROUTE.PHOTO_UPLOAD], {queryParams});
  }

  selectImage(direction: 'next' | 'prev') {
    const index = getNewImageIndex(this.currentImageIndex, this.images.length, direction);
    if (index === -1) {
      console.error(`selectImage(${direction}): failed to return new image index.`);
      return;
    }
    this.currentImageIndex = index;
    // Update the current thumbnail with the annotation count.
    const imageId = this.images[this.currentImageIndex].id;
    this.thumbnailMetadata = {
      ...this.thumbnailMetadata!,
      selectedImageIndex: this.currentImageIndex,
      annotationCount: this.annotationCountByImageId.get(imageId)!,
    };
  }

  initFeatureFlags() {
    // TODO(b/288092797): Remove thumbnailCarouselEnabled when enabling gallery
    // thumbnail feature.
    this.thumbnailCarouselEnabled = this.configService.thumbnailCarouselEnabled;
    // TODO(b/305203794): Remove flag when feature is well tested.
    this.breadcrumbsEnabled = this.configService.breadcrumbsEnabled;

    this.showSolarInsights = this.configService.solarEnabled;
  }
}
