import {BehaviorSubject, Observable, Subject, combineLatest} from 'rxjs';
import {switchMap, takeUntil} from 'rxjs/operators';

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';

import {
  LayerStyle,
  Layer_LayerType,
} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/layer_pb';

import {FEEDER_NAME} from '../constants/asset';
import {ICP_LAYER_ID, SOLAR_INSIGHTS_LAYER_ID} from '../constants/layer';
import {AnalyticsService, EventActionType, EventCategoryType} from '../services/analytics_service';
import {ConfigService} from '../services/config_service';
import {LayersFilterService} from '../services/layers_filter_service';
import {LayersService} from '../services/layers_service';
import {SolarInsightsService} from '../services/solar_insights_service';
import {Filter, FilterEditingStateEvent, FilterMap} from '../typings/filter';
import {removeIgnoredPropertyKeys, sortPropertyKeysByPriority} from '../utils/properties';

const DEFAULT_ASSET_PRIORITY_KEYS = ['Feeder ID', 'Asset type'];

/**
 * This component displays the property filters and filter modification controls
 * for a single layer within the sidepanel.
 */
@Component({
  selector: 'property-filters',
  templateUrl: './property_filters.ng.html',
  styleUrls: ['./property_filters.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PropertyFilters implements OnInit, OnDestroy {
  @Input() layerId = '';
  @Input() includeInactiveResults = false;
  @Input() reset: Observable<void> = new Subject<void>();
  @Output()
  readonly editingChange = new EventEmitter<FilterEditingStateEvent>();

  protected readonly icpLayerId = ICP_LAYER_ID;
  protected readonly solarInsightsLayerId = SOLAR_INSIGHTS_LAYER_ID;

  /**
   * All filter names less filter names that are applied.
   */
  remainingFilterNames: string[] = [];
  selectedFilterMap: FilterMap = {};
  changingFilter: Filter | null = null;
  editing = false;
  optionsByFilterName$ = new BehaviorSubject<string[]>([]);
  destroyed = new Subject<void>();

  solar2Enabled = this.configService.solar2Enabled;

  constructor(
    private readonly analyticsService: AnalyticsService,
    private readonly layersFilterService: LayersFilterService,
    private readonly layersService: LayersService,
    readonly changeDetectorRef: ChangeDetectorRef,
    private readonly configService: ConfigService,
    private readonly solarInsightsService: SolarInsightsService,
  ) {}

  ngOnInit() {
    this.initLayerPropertyKeysListener();
    this.listenForFilterUpdates();
    this.reset.pipe(takeUntil(this.destroyed)).subscribe(() => {
      this.cancelEditing();
    });
  }

  ngOnDestroy() {
    this.destroyed.next();
    this.destroyed.complete();
  }

  private initLayerPropertyKeysListener() {
    return combineLatest([
      this.layersFilterService.getFilterMap(this.layerId),
      this.layersFilterService.includeInactive(this.layerId),
    ])
      .pipe(
        switchMap(([filterMap, includeInactive]: [FilterMap, boolean]) => {
          this.selectedFilterMap = filterMap;
          return this.layersService.getLayerPropertyKeys(this.layerId, false, includeInactive);
        }),
        takeUntil(this.destroyed),
      )
      .subscribe((allFilterNames: string[]) => {
        this.updateRemainingFilterNames(allFilterNames);
      });
  }

  private listenForFilterUpdates() {
    this.layersFilterService
      .includeInactive(this.layerId)
      .pipe(
        switchMap((includeInactiveResults: boolean) => {
          return this.layersService.getLayerPropertyKeys(
            this.layerId,
            false,
            includeInactiveResults,
          );
        }),
        takeUntil(this.destroyed),
      )
      .subscribe((allFilterNames: string[]) => {
        this.updateRemainingFilterNames(allFilterNames);
      });
  }

  private updateRemainingFilterNames(allFilterNames: string[]) {
    const layerType = this.layersService.getLayerType(this.layerId);
    const layerStyle = this.layersService.getLayerStyle(this.layerId);
    this.remainingFilterNames =
      this.solar2Enabled && this.layerId === SOLAR_INSIGHTS_LAYER_ID
        ? [FEEDER_NAME]
        : this.prepareFilterNamesForDisplay(allFilterNames, layerStyle, layerType);
    this.changeDetectorRef.detectChanges();
  }

  /**
   * Adds a filter to the layer.
   */
  addFilter(filter: Filter) {
    this.layersFilterService.addFilter(this.layerId, filter.name, filter.selectedValues);
    let eventType: EventActionType;
    if (this.changingFilter !== null) {
      this.changingFilter = null;
      eventType = EventActionType.CHANGE_FILTER;
    } else {
      eventType = EventActionType.ADD_FILTER;
    }
    this.analyticsService.sendEvent(eventType, {
      event_category: EventCategoryType.MAP,
      event_label: filter.name,
    });

    this.cancelEditing();
  }

  fetchAndSetOptions(filterNameValue: string) {
    if (
      this.solar2Enabled &&
      this.layerId === SOLAR_INSIGHTS_LAYER_ID &&
      filterNameValue === FEEDER_NAME
    ) {
      const allFeeders = this.solarInsightsService.getAllFeeders();
      this.solarInsightsService.setSelectedFeeders(allFeeders);

      const options = allFeeders.map((feeder) => feeder?.aggregationType?.value?.feederCode || '');
      this.optionsByFilterName$.next(options);
    } else {
      this.layersService
        .getLayerPropertyValues(this.layerId, filterNameValue, this.includeInactiveResults)
        .pipe(takeUntil(this.destroyed))
        .subscribe((options: string[]) => {
          this.optionsByFilterName$.next(options);
        });
    }
  }

  /**
   * Shows or hides the filter form.
   * @param editing: indicates whether or not to show the add filter form.
   */
  setEditing(editing: boolean) {
    this.editing = editing;
    this.editingChange.emit({layerId: this.layerId, editing, isReset: false});
  }

  cancelEditing() {
    this.setEditing(false);
    this.changingFilter = null;
    this.optionsByFilterName$.next([]);
  }

  changeFilter(filter: Filter) {
    this.changingFilter = filter;
    this.setEditing(true);
  }

  /**
   * Remove the filter from the layer.
   * @param filterName: the name of the filter to remove.
   */
  removeFilter(filterName: string) {
    this.layersFilterService.removeFilter(this.layerId, filterName);
    this.cancelEditing();
  }

  private prepareFilterNamesForDisplay(
    filterNames: string[],
    layerStyle: LayerStyle | null,
    layerType: Layer_LayerType | null,
  ): string[] {
    const ignorePropertyKeys = layerStyle?.ignorePropertyKeys || [];
    let priorityPropertyKeys = layerStyle?.priorityPropertyKeys || [];
    if (priorityPropertyKeys.length === 0 && layerType === Layer_LayerType.ASSETS) {
      priorityPropertyKeys = DEFAULT_ASSET_PRIORITY_KEYS;
    }
    const filteredPropertyKeys = removeIgnoredPropertyKeys(filterNames, ignorePropertyKeys);
    const sortedPropertyKeys = sortPropertyKeysByPriority(
      filteredPropertyKeys,
      priorityPropertyKeys,
    );
    const filterNamesForDisplay = removeAppliedFilterNames(
      sortedPropertyKeys,
      this.selectedFilterMap,
    );
    return filterNamesForDisplay;
  }
}

/**
 * Returns available filters names less filters that exist in the filter map.
 */
export function removeAppliedFilterNames(
  availableFilterNames: string[],
  selectedFilterMap: FilterMap,
): string[] {
  return availableFilterNames.filter((filterName: string) => {
    return selectedFilterMap[filterName] === undefined;
  });
}
