<div class="top-header">
  <a class="title" routerLink="/map"> GridAware </a>
  <!--  <button mat-button *ngIf="showUserType && userType" (click)="openUserTypesDialog()" class="type">-->
  <!--    {{userType.getShortName()}}-->
  <!--  </button>-->
  <div class="spacer">
    <!-- Allow for additional center content, such as a search bar. -->
    <ng-content></ng-content>
  </div>
  <div class="icon-container">
    <button
      mat-icon-button
      matTooltip="Photo upload"
      i18n-aria-label
      class="upload-photos-button"
      aria-label="Photo upload"
      routerLink="/upload">
      <mat-icon class="link-icon" fontSet="material-icons-outlined"> add_a_photo </mat-icon>
    </button>
    <button
      mat-icon-button
      matTooltip="Network settings"
      [matMenuTriggerFor]="offlineMenu"
      i18n-aria-label
      class="offline-button"
      aria-label="Network settings">
      <mat-icon
        class="link-icon"
        [matBadge]="pendingUploadCount"
        [matBadgeHidden]="pendingUploadCount === 0"
        matBadgeColor="warn">
        {{ offline ? 'cloud_off' : 'cloud_queue' }}
      </mat-icon>
    </button>
    <button
      mat-icon-button
      class="start-feedback"
      matTooltip="Send feedback"
      i18n-aria-label
      aria-label="Send feedback"
      (click)="startFeedback()">
      <mat-icon class="link-icon" fontSet="material-icons-outlined">feedback</mat-icon>
    </button>
    @if (themingEnabled) {
      <button
        mat-icon-button
        (click)="toggleTheme()"
        [matTooltip]="getThemeLabel()"
        [attr.aria-label]="getThemeLabel()"
        i18n-aria-label>
        @if (colorScheme === ColorScheme.LIGHT) {
          <mat-icon class="link-icon" fontSet="material-icons-outlined"> dark_mode </mat-icon>
        } @else {
          <mat-icon
            *ngIf="colorScheme === ColorScheme.DARK"
            class="link-icon"
            fontSet="material-icons-outlined">
            light_mode
          </mat-icon>
        }
      </button>
    }
    <button
      mat-icon-button
      [matMenuTriggerFor]="menu"
      class="action"
      i18n-aria-label
      aria-label="User settings"
      matTooltip="User settings">
      @if (userImage) {
        <img class="avatar" [src]="userImage" alt="User image" />
      } @else {
        <mat-icon fontSet="material-icons-outlined" class="avatar link-icon">
          person_black
        </mat-icon>
      }
    </button>
  </div>
  <mat-menu #menu="matMenu">
    <button mat-menu-item (click)="signOut()">Sign out</button>
  </mat-menu>
  <mat-menu #offlineMenu>
    <div mat-menu-item class="offline-mode-toggle-container" (click)="$event.stopPropagation()">
      <div>Offline mode</div>
      <mat-slide-toggle
        [checked]="isInOfflineMode"
        color="primary"
        aria-label="Toggle offline mode"
        class="offline-mode-toggle"
        i18n-aria-label>
      </mat-slide-toggle>
    </div>
    <button mat-menu-item (click)="goToPendingUploads()" class="pending-uploads">
      Pending uploads ({{ pendingUploadCount }})
    </button>
  </mat-menu>
</div>
@if (isOfflineBannerVisible) {
  <div class="banner" [@enterAnimation]>
    <banner
      (onDismiss)="dismissOfflineBanner()"
      [level]="offlineBannerLevel"
      message="Offline mode on"
      linkLabel="Go to Pending uploads"
      link="/upload/pending">
    </banner>
  </div>
}
@if (bannerTemplate) {
  <div class="banner" [@enterAnimation]>
    <ng-container [ngTemplateOutlet]="bannerTemplate"></ng-container>
  </div>
}
