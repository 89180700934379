import {LatLng} from '@tapestry-energy/npm-prod/google/type/latlng_pb';
import {Observable, Subject, Subscriber, firstValueFrom, lastValueFrom, of} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {DatePipe} from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {MatDialogConfig} from '@angular/material/dialog';
import {Router} from '@angular/router';

import {Feature} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/feature_pb';
import {Image} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/image_pb';
import {Tag} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/tag_pb';

import {ASSETS_LAYER_ID, DEFECTS_LAYER_ID} from '../constants/layer';
import {QUERY_PARAMS, ROUTE} from '../constants/paths';
import {DeleteImageConfirmationDialog} from '../delete_image_confirmation_dialog/delete_image_confirmation_dialog';
import {FileViewerService} from '../file_viewer/file_viewer_service';
import {AnalyticsService, EventActionType, EventCategoryType} from '../services/analytics_service';
import {AnnotationsService} from '../services/annotations_service';
import {ConfigService} from '../services/config_service';
import {DialogService} from '../services/dialog_service';
import {MapService} from '../services/map_service';
import {NetworkService} from '../services/network_service';
import {
  UploadResponse,
  UploadResponseMetadata,
  UploadService,
  failedWithState,
} from '../services/upload_service';
import {TagsDialog, TagsDialogData, TagsDialogResponse} from '../tags/tags_dialog';
import {type PendingUploadGroup, UploadState} from '../typings/upload';
import {
  FeatureSelectionMapDialog,
  FeatureSelectionMapDialogData,
  FeatureSelectionResponse,
} from './dialogs/feature_selection_map_dialog';

const SUCCEEDED_IMAGE_OPACITY = 0.3;
const DEFAULT_IMAGE_OPACITY = 1;
const SUCCEEDED_IMAGE_CURSOR = 'default';
const DEFAULT_IMAGE_CURSOR = 'pointer';
const FAILED_IMAGE_BORDER = '1px solid #b00020';
const DEFAULT_IMAGE_BORDER = 'none';
const PENDING_BUTTON_COLOR = 'primary';
const DEFAULT_BUTTON_COLOR = '';
const LAST_MODIFIED_TIME_MISSING = 'Last modified time missing';

interface HeaderImageStyle {
  border: string;
  cursor: string;
  opacity: number;
}

const TAGS_DIALOG_CONFIG: MatDialogConfig<TagsDialogData> = {
  data: {
    headerText: 'Add tags to image group',
    seededTags: ['Offline'],
  },
};

const FEATURE_SELECTION_MAP_DIALOG_CONFIG: MatDialogConfig<FeatureSelectionMapDialogData> = {
  data: {layerIds: [ASSETS_LAYER_ID]},
};

const FAILURE_REASON_TO_EVENT_TYPE = new Map([
  [UploadState.FAILED_LOCATION_MISSING, EventActionType.OFFLINE_UPLOAD_FAILED_LOCATION_MISSING],
  [UploadState.FAILED_INCOMPLETE, EventActionType.OFFLINE_UPLOAD_FAILED_INCOMPLETE],
  [
    // TODO(reubenn): Show individual failed images and allow them to
    // be deleted from the group before retrying.
    UploadState.FAILED_UNSUPPORTED_BLOB,
    EventActionType.OFFLINE_UPLOAD_FAILED_UNSUPPORTED_BLOB,
  ],
]);

/**
 * The upload group component.
 */
@Component({
  templateUrl: 'upload_group.ng.html',
  styleUrls: ['upload_group.scss'],
  providers: [DatePipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UploadGroup implements OnInit, OnDestroy {
  @Input() pendingUploadGroup: PendingUploadGroup | null = null;
  @Input() tags: Tag[] | null = null;
  @Output() readonly uploadStateChanged = new EventEmitter<UploadState>();
  @Output() readonly removed = new EventEmitter<void>();
  @Output() readonly updated = new EventEmitter<PendingUploadGroup>();

  // Keeps track of the upload state for a group.
  private uploadState = UploadState.PENDING;
  private uploadResponse: UploadResponse | null = null;
  private relatedAsset: Feature | null = null;
  private addedDefect: Feature | null = null;
  private mapSelectionLocation: LatLng | null = null;
  // The key for this map is the file's timestamp.
  private readonly readImageCache = new Map<number, Observable<string>>();
  private readonly destroyed = new Subject<void>();

  // The image that is displayed which represents the group.
  headerImage: Observable<string> | null = null;
  headerImageStyle: HeaderImageStyle = {
    border: DEFAULT_IMAGE_BORDER,
    cursor: DEFAULT_IMAGE_CURSOR,
    opacity: DEFAULT_IMAGE_OPACITY,
  };
  // In case of any additional action provided in response to user interaction,
  // this will be flipped to true.
  actionButtonVisible = false;
  flatButton = false;
  alt = '';
  text = '';
  buttonText = '';
  buttonColor = '';
  date = '';
  errorMessage = '';
  loading = false;

  constructor(
    readonly networkService: NetworkService, // Used in template.
    readonly datepipe: DatePipe,
    private readonly analyticsService: AnalyticsService,
    private readonly annotationsService: AnnotationsService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly configService: ConfigService,
    private readonly dialogService: DialogService,
    private readonly fileViewerService: FileViewerService,
    private readonly mapService: MapService,
    private readonly router: Router,
    private readonly uploadService: UploadService,
  ) {}

  ngOnInit() {
    this.updateProperties();
  }

  ngOnDestroy() {
    this.destroyed.next();
    this.destroyed.complete();
  }

  getAddedDefectId(): string {
    return this.addedDefect?.id ?? '';
  }

  getRelatedAssetId(): string {
    return this.relatedAsset?.id ?? '';
  }

  updateProperties() {
    this.headerImage = this.pendingUploadGroup?.files?.length
      ? this.readImage(this.pendingUploadGroup.files[0])
      : of('');
    this.alt = this.pendingUploadGroup!.files[0].name;
    this.headerImageStyle = {
      opacity: this.getHeaderImageOpacity(),
      cursor: this.getHeaderImageCursor(),
      border: this.getHeaderImageBorder(),
    };
    this.text = this.getText();
    this.buttonText = this.getButtonText();
    this.actionButtonVisible = this.buttonText !== '';
    this.buttonColor = this.getButtonColor();
    this.flatButton = this.isButtonFlat();
    this.date =
      this.datepipe.transform(
        this.pendingUploadGroup?.uploadedAt || new Date(),
        'yyyy/MM/dd HH:mm',
      ) || '';
    this.errorMessage = this.getErrorMessage();
    this.changeDetectorRef.detectChanges();
  }

  /**
   * Called by parent component.
   */
  uploadOrRetry() {
    if (this.shouldEditMap()) {
      return;
    }
    this.handleAction();
  }

  async handleAction() {
    if (!this.tags) {
      const tagsDialogResponse: TagsDialogResponse | undefined = await firstValueFrom(
        this.renderTagsDialog(),
      );
      if (!tagsDialogResponse) {
        return;
      }
      this.tags = tagsDialogResponse.tags;
    }
    if (this.shouldEditMap()) {
      const featureSelectionResponse: FeatureSelectionResponse | undefined = await firstValueFrom(
        this.renderFeatureSelectionDialog(),
      );
      if (!featureSelectionResponse) {
        return;
      }
      const {selectedFeature, locationOfPin} = featureSelectionResponse;
      const location =
        selectedFeature?.geometry?.geometry.case === 'point'
          ? selectedFeature?.geometry?.geometry.value?.location
          : null;
      this.mapSelectionLocation = location || locationOfPin;
      this.relatedAsset = selectedFeature || null;
      this.updateProperties();
    }
    if (this.shouldEditDefect()) {
      this.editDefect();
      return;
    }
    if (this.shouldUpload()) {
      this.loading = true;
      this.changeDetectorRef.detectChanges();
      try {
        await this.upload();
      } catch (err: unknown) {
        console.error(err);
        this.handleUploadState(
          UploadState.FAILED_INCOMPLETE,
          EventActionType.OFFLINE_UPLOAD_FAILED_INCOMPLETE,
          (err as Error).message,
        );
      } finally {
        this.loading = false;
        this.changeDetectorRef.detectChanges();
      }
    }
  }

  editPendingUpload() {
    if (!this.pendingUploadGroup?.pendingUploadID) {
      return;
    }
    this.router.navigate([ROUTE.PHOTO_UPLOAD], {
      queryParams: {
        [QUERY_PARAMS.PENDING_UPLOAD_ID]: this.pendingUploadGroup.pendingUploadID,
      },
    });
  }

  private renderFeatureSelectionDialog(): Observable<FeatureSelectionResponse> {
    return this.dialogService.render<FeatureSelectionMapDialog, FeatureSelectionResponse>(
      FeatureSelectionMapDialog,
      FEATURE_SELECTION_MAP_DIALOG_CONFIG,
    );
  }

  private renderTagsDialog(): Observable<TagsDialogResponse | undefined> {
    return this.dialogService.render<TagsDialog, TagsDialogResponse>(
      TagsDialog,
      TAGS_DIALOG_CONFIG,
    );
  }

  openDeleteImageConfirmationDialog() {
    this.dialogService
      .render<DeleteImageConfirmationDialog, boolean>(DeleteImageConfirmationDialog)
      .pipe(takeUntil(this.destroyed))
      .subscribe((deletionConfirmed: boolean) => {
        if (deletionConfirmed) {
          this.removeUploadGroup();
        }
      });
  }

  private removeUploadGroup() {
    this.removed.next();
  }

  removeIconVisible(): boolean {
    return !(this.loading || this.uploadState === UploadState.SUCCEEDED);
  }

  editIconVisible(): boolean {
    return (
      this.configService.editPendingUploadEnabled && !this.loading && !this.isViewButtonVisible()
    );
  }

  private async upload() {
    const uploadForm = this.pendingUploadGroup!.uploadForm;
    uploadForm.tags = new Set([...this.tags!].map((tag: Tag) => tag.name));
    // Use last modified time to dedupe failed offline uploads.
    const lastModifiedTime = String(
      this.pendingUploadGroup?.files[0]?.lastModified || LAST_MODIFIED_TIME_MISSING,
    );
    this.relatedAsset = await firstValueFrom(this.getAssociatedAsset());
    const location =
      this.relatedAsset?.geometry?.geometry.case === 'point'
        ? this.relatedAsset?.geometry?.geometry.value?.location
        : this.mapSelectionLocation;

    // Upload images.
    const uploaded = await lastValueFrom(
      this.configService.gcpUploadEnabled
        ? this.uploadService.upload(this.pendingUploadGroup!, location || null)
        : this.uploadService.uploadG3(this.pendingUploadGroup!, location || null),
    );

    if (uploaded.length === 0) {
      console.error('Failed to upload images.');
      return;
    }

    // Save annotations.
    await this.saveAnnotations();

    // Add defect.
    try {
      this.addedDefect = await firstValueFrom(
        this.uploadService.createDefect(
          uploadForm,
          uploaded.map((uploadedImage) => uploadedImage.image),
          this.relatedAsset,
        ),
      );
      this.handleUploadState(
        UploadState.SUCCEEDED,
        EventActionType.OFFLINE_UPLOAD_SUCCEEDED,
        `${this.pendingUploadGroup!.files.length} images`,
      );
    } catch (error) {
      console.error(error);
      this.handleUploadState(
        UploadState.FAILED_INCOMPLETE,
        EventActionType.OFFLINE_UPLOAD_FAILED_ADDING_DEFECT,
        lastModifiedTime,
      );
    }
  }

  /**
   * Saves annotations defined on newly uploaded files.
   */
  private async saveAnnotations() {
    if (!this.uploadResponse) {
      return;
    }
    for (const [file, uploadResponseMetadata] of this.uploadResponse) {
      const imageId = uploadResponseMetadata.image?.id;
      const fileIndex = this.getFileIndex(file);

      const allAnnotations = this.pendingUploadGroup!.annotationsPerFile;
      if (imageId && fileIndex !== -1 && allAnnotations.length > fileIndex) {
        const annotations = this.pendingUploadGroup!.annotationsPerFile[fileIndex];
        await lastValueFrom(
          this.annotationsService.saveExplicitAnnotationsForImage(imageId, annotations),
        );
      }
    }
  }

  /**
   * Retrieves index of the given file in the pending group files list.
   * Can be used to associate specific file with annotations defined on it.
   */
  private getFileIndex(searchFile: File): number {
    return this.pendingUploadGroup!.files.findIndex((file: File) => searchFile.name === file.name);
  }

  private getAssociatedAsset(): Observable<Feature | null> {
    if (this.relatedAsset) {
      return of(this.relatedAsset);
    }
    const externalId = this.pendingUploadGroup!.uploadForm.externalId;
    if (!externalId) {
      return of(null);
    }
    return this.uploadService.searchAssociatedAsset(externalId);
  }

  private handleUploadState(state: UploadState, eventType: EventActionType, message: string = '') {
    this.setUploadState(state);
    this.sendEvent(eventType, message);
  }

  private handleFailedScottyUpload(uploadResponse: UploadResponse, message: string) {
    for (const [state, eventType] of FAILURE_REASON_TO_EVENT_TYPE) {
      if (failedWithState(state, uploadResponse)) {
        this.handleUploadState(state, eventType, message);
        return;
      }
    }
  }

  private getUploadedImages(uploadResponse: UploadResponse): Image[] {
    const images: Image[] = [];
    for (const uploadResponseMetadata of uploadResponse.values()) {
      images.push(uploadResponseMetadata.image!);
    }
    return images;
  }

  private shouldEditMap() {
    return this.uploadState === UploadState.FAILED_LOCATION_MISSING && !this.mapSelectionLocation;
  }

  private shouldEditDefect() {
    return this.uploadState === UploadState.SUCCEEDED;
  }

  private shouldUpload() {
    return (
      (this.tags && this.uploadState === UploadState.PENDING) ||
      this.uploadState === UploadState.FAILED_INCOMPLETE ||
      (this.uploadState === UploadState.FAILED_LOCATION_MISSING && this.mapSelectionLocation)
    );
  }

  private getText(): string {
    const totalImageCount = this.pendingUploadGroup!.files.length;
    if (this.uploadState === UploadState.FAILED_UNSUPPORTED_BLOB) {
      const failedCount = [...this.uploadResponse!.values()].filter(
        (uploadResponseMetadata: UploadResponseMetadata) =>
          uploadResponseMetadata.uploadState === UploadState.FAILED_UNSUPPORTED_BLOB,
      ).length;

      return `${failedCount} of ${totalImageCount} ${
        totalImageCount === 1 ? 'image' : 'images'
      } failed to upload`;
    }
    return `${totalImageCount} ${totalImageCount === 1 ? 'image' : 'images'}`;
  }

  private getHeaderImageBorder() {
    switch (this.uploadState) {
      case UploadState.FAILED_LOCATION_MISSING:
      case UploadState.FAILED_INCOMPLETE:
        return FAILED_IMAGE_BORDER;
      default:
        return DEFAULT_IMAGE_BORDER;
    }
  }

  private getHeaderImageCursor(): string {
    return this.uploadState === UploadState.SUCCEEDED
      ? SUCCEEDED_IMAGE_CURSOR
      : DEFAULT_IMAGE_CURSOR;
  }

  private getHeaderImageOpacity(): number {
    return this.uploadState === UploadState.SUCCEEDED
      ? SUCCEEDED_IMAGE_OPACITY
      : DEFAULT_IMAGE_OPACITY;
  }

  private isButtonFlat(): boolean {
    return this.uploadState === UploadState.PENDING;
  }

  private getButtonText(): string {
    if (this.uploadState === UploadState.FAILED_LOCATION_MISSING && this.mapSelectionLocation) {
      return 'Retry';
    }
    switch (this.uploadState) {
      case UploadState.PENDING:
        return 'Upload';
      case UploadState.FAILED_UNSUPPORTED_BLOB:
      case UploadState.FAILED_INCOMPLETE:
        return 'Retry';
      case UploadState.FAILED_LOCATION_MISSING:
      case UploadState.SUCCEEDED:
        return 'Edit';
      default:
        return '';
    }
  }

  private getButtonColor(): string {
    return this.uploadState === UploadState.PENDING ? PENDING_BUTTON_COLOR : DEFAULT_BUTTON_COLOR;
  }

  private getErrorMessage(): string {
    if (this.uploadState === UploadState.FAILED_LOCATION_MISSING && this.mapSelectionLocation) {
      return '';
    }
    switch (this.uploadState) {
      case UploadState.FAILED_UNSUPPORTED_BLOB:
        return 'Image not supported';
      case UploadState.FAILED_INCOMPLETE:
        return 'Incomplete upload. Try again or remove.';
      case UploadState.FAILED_LOCATION_MISSING:
        return 'Location missing. Edit to add data or remove.';
      default:
        return '';
    }
  }

  private readImage(file: File): Observable<string> {
    const key = file.lastModified;
    if (!key) {
      return of('');
    }
    if (!this.readImageCache.has(key)) {
      const imageSrc$ = new Observable((subscriber: Subscriber<string>) => {
        const fileReader = new FileReader();
        fileReader.addEventListener('load', () => {
          subscriber.next(fileReader.result as string);
          subscriber.complete();
        });
        fileReader.readAsDataURL(file);
      });
      this.readImageCache.set(key, imageSrc$);
    }
    return this.readImageCache.get(key)!;
  }

  setUploadState(newUploadState: UploadState) {
    if (newUploadState === this.uploadState) {
      return;
    }
    this.uploadState = newUploadState;
    this.uploadStateChanged.next(newUploadState);
    this.updateProperties();
  }

  async openFileViewer() {
    if (this.uploadState === UploadState.SUCCEEDED) {
      return;
    }
    this.fileViewerService.open(this.pendingUploadGroup!.files);
    this.fileViewerService
      .onFileDeleted()
      .pipe(takeUntil(this.destroyed))
      .subscribe((files: File[]) => {
        if (files.length === 0) {
          this.removeUploadGroup();
          return;
        }
        this.pendingUploadGroup!.files = files;
        this.updateProperties();
        this.updated.next(this.pendingUploadGroup!);
      });
  }

  isViewButtonVisible(): boolean {
    return !this.loading && this.uploadState === UploadState.SUCCEEDED;
  }

  navigateToImageGroup() {
    this.mapService.setShouldRepositionMap(true);
    this.router.navigate([ROUTE.MAP, DEFECTS_LAYER_ID, this.getAddedDefectId()], {
      queryParams: {
        [QUERY_PARAMS.FEATURE_ID]: this.getRelatedAssetId(),
      },
    });
  }

  private editDefect() {
    this.router.navigate([ROUTE.PHOTO_UPLOAD], {
      queryParams: {
        [QUERY_PARAMS.FEATURE_ID]: this.getAddedDefectId(),
        [QUERY_PARAMS.LAYER_ID]: DEFECTS_LAYER_ID,
        [QUERY_PARAMS.EDIT]: true,
      },
    });
  }

  private sendEvent(eventActionType: EventActionType, eventLabel: string) {
    this.analyticsService.sendEvent(eventActionType, {
      event_category: EventCategoryType.IMAGE,
      event_label: eventLabel,
    });
  }
}
