import {Observable, Subject, firstValueFrom} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import {MatDialogConfig} from '@angular/material/dialog';
import {Router} from '@angular/router';

import {ROUTE} from '../constants/paths';
import {ConfigService} from '../services/config_service';
import {DialogService} from '../services/dialog_service';
import {LocalStorageService} from '../services/local_storage_service';
import {NetworkService} from '../services/network_service';
import {UploadService} from '../services/upload_service';
import {TagsDialog, TagsDialogData, TagsDialogResponse} from '../tags/tags_dialog';
import {PendingUploadGroup, UploadState} from '../typings/upload';
import {UploadGroup} from './upload_group';

const TAGS_DIALOG_CONFIG: MatDialogConfig<TagsDialogData> = {
  data: {
    headerText: 'Add tag(s) to all pending uploads',
    seededTags: ['Offline'],
  },
};

const CONFIRM_OFFLINE_MODE_DISABLED_TEXT =
  'An internet connection will be' +
  ' required to continue using the application. Do you want to continue?';

/**
 * The pending upload page.
 */
@Component({
  templateUrl: 'pending_upload.ng.html',
  styleUrls: ['pending_upload.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PendingUploadPage implements OnInit, OnDestroy {
  @ViewChild('pending', {read: ViewContainerRef})
  pendingVCRef!: ViewContainerRef;
  @ViewChild('failed', {read: ViewContainerRef}) failedVCRef!: ViewContainerRef;
  @ViewChild('succeeded', {read: ViewContainerRef})
  succeededVCRef!: ViewContainerRef;

  // Keeps track of the component refs at each stage for upload all and retry
  // all.
  pendingComponentRefs = new Set<ComponentRef<UploadGroup>>();
  failedComponentRefs = new Set<ComponentRef<UploadGroup>>();
  offlineModeDisabled = false;
  destroyed$ = new Subject<void>();

  constructor(
    readonly networkService: NetworkService, // Used in template.
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly configService: ConfigService,
    private readonly dialogService: DialogService,
    private readonly localStorageService: LocalStorageService,
    private readonly uploadService: UploadService,
    private readonly router: Router,
  ) {
    if (!this.configService.serviceWorkerEnabled) {
      this.router.navigateByUrl(ROUTE.MAP);
    }
  }

  ngOnInit() {
    this.createPendingUploadGroups();
    this.offlineModeDisabled = this.localStorageService.readOfflineModeDisabled();
    this.networkService.setLearnMoreVisibility(false);
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.networkService.setLearnMoreVisibility(true);
  }

  toggleOfflineMode() {
    if (!this.offlineModeDisabled && !window.confirm(CONFIRM_OFFLINE_MODE_DISABLED_TEXT)) {
      return;
    }
    this.offlineModeDisabled = !this.offlineModeDisabled;
    this.localStorageService.writeOfflineModeDisabled(this.offlineModeDisabled);
  }

  getImageCount(viewContainerRef: ViewContainerRef | null): string {
    if (!viewContainerRef) {
      return '';
    }
    return viewContainerRef.length === 1 ? '1 image' : `${viewContainerRef.length} images`;
  }

  async uploadAllPending() {
    const tagsDialogResponse: TagsDialogResponse | undefined = await firstValueFrom(
      this.renderTagsDialog(),
    );
    if (!tagsDialogResponse) {
      return;
    }
    for (const componentRef of this.pendingComponentRefs) {
      componentRef.instance.tags = tagsDialogResponse.tags;
      componentRef.instance.uploadOrRetry();
    }
  }

  retryAllFailed() {
    for (const componentRef of this.failedComponentRefs) {
      componentRef.instance.uploadOrRetry();
    }
  }

  /**
   * Dynamically create Upload Group components and attach them to the pending
   * container.
   */
  private async createPendingUploadGroups() {
    try {
      const pendingUploadGroupByKey = await firstValueFrom(
        this.uploadService.getAllPendingUploads().pipe(takeUntil(this.destroyed$)),
      );
      for (const [key, pendingUploadGroup] of pendingUploadGroupByKey) {
        const componentRef = this.attachPendingUploadGroupComponent();
        this.pendingComponentRefs.add(componentRef);
        let lastVCRef = this.pendingVCRef;
        componentRef.instance.pendingUploadGroup = pendingUploadGroup;
        componentRef.instance.uploadStateChanged
          .pipe(takeUntil(this.destroyed$))
          .subscribe(async (uploadState: UploadState) => {
            lastVCRef.detach(lastVCRef.indexOf(componentRef.hostView));
            if (uploadState === UploadState.SUCCEEDED) {
              await firstValueFrom(this.uploadService.deletePendingUpload(key));
              this.removeComponentRef(componentRef);
              this.succeededVCRef.insert(componentRef.hostView);
              lastVCRef = this.succeededVCRef;
              this.changeDetectorRef.detectChanges();
              return;
            }
            this.removeComponentRef(componentRef);
            this.failedComponentRefs.add(componentRef);
            this.failedVCRef.insert(componentRef.hostView);
            lastVCRef = this.failedVCRef;
            this.changeDetectorRef.detectChanges();
          });
        componentRef.instance.removed.pipe(takeUntil(this.destroyed$)).subscribe(async () => {
          await firstValueFrom(this.uploadService.deletePendingUpload(key));
          this.removeComponentRef(componentRef);
          lastVCRef.remove(lastVCRef.indexOf(componentRef.hostView));
          this.changeDetectorRef.detectChanges();
        });
        // A pending upload has been updated, eg, a file has been deleted.
        // Update the corresponding row in indexedDB.
        componentRef.instance.updated
          .pipe(takeUntil(this.destroyed$))
          .subscribe(async (pendingUploadGroup: PendingUploadGroup) => {
            await firstValueFrom(this.uploadService.updatePendingUpload(pendingUploadGroup, key));
          });
      }
      this.changeDetectorRef.detectChanges();
    } catch (error: unknown) {
      console.error('A problem occurred getting all saved uploads');
    }
  }

  private removeComponentRef(componentRef: ComponentRef<UploadGroup>) {
    this.pendingComponentRefs.has(componentRef)
      ? this.pendingComponentRefs.delete(componentRef)
      : this.failedComponentRefs.delete(componentRef);
  }

  private attachPendingUploadGroupComponent(): ComponentRef<UploadGroup> {
    const componentRef: ComponentRef<UploadGroup> = this.pendingVCRef.createComponent(UploadGroup);
    return componentRef;
  }

  private renderTagsDialog(): Observable<TagsDialogResponse> {
    return this.dialogService.render<TagsDialog, TagsDialogResponse>(
      TagsDialog,
      TAGS_DIALOG_CONFIG,
    );
  }

  goToUploadPage() {
    this.router.navigateByUrl(ROUTE.PHOTO_UPLOAD);
  }
}
