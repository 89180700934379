<ng-container>
  <filter-chips
    [filters]="selectedFilterMap"
    (editingChange)="changeFilter($event)"
    (removeChip)="removeFilter($event)">
  </filter-chips>
  @if (editing) {
    <div
      [ngClass]="{
        'form-solar-mb':
          (layerId === icpLayerId || layerId === solarInsightsLayerId) && solar2Enabled
      }">
      <filter-form
        [filterNames]="remainingFilterNames"
        [changingFilter]="changingFilter"
        [optionsByFilterName]="optionsByFilterName$ | async"
        (filterApplied)="addFilter($event)"
        (filterNameValueChanged)="fetchAndSetOptions($event)"
        (canceled)="cancelEditing()"
        [includeInactiveResults]="includeInactiveResults">
      </filter-form>
    </div>
  }
  @if (!editing) {
    <button
      mat-flat-button
      class="add-filter-button"
      [disabled]="remainingFilterNames.length === 0"
      (click)="setEditing(true)"
      color="primary">
      Add filter
    </button>
  }
</ng-container>
