import {LatLng} from '@tapestry-energy/npm-prod/google/type/latlng_pb';
import {Easing, Tween, update} from '@tweenjs/tween.js';
import {Observable, ReplaySubject, Subject, firstValueFrom, of} from 'rxjs';
import {first, mergeMap, take, takeUntil} from 'rxjs/operators';

import {DOCUMENT} from '@angular/common';
import {Inject, Injectable, OnDestroy} from '@angular/core';

import {Layer} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/layer_pb';

import {DARK_THEME, DEFAULT_THEME, NIGHT_THEME, SILVER_THEME} from '../constants/map_theme';
import {MarkerProperty} from '../constants/marker';
import {Marker, MultiMarker, VisibilityByLayerUpdate} from '../typings/map';
import {IconGenerator} from '../utils/icon_util';
import {AppService} from './app_service';
import {ConfigService} from './config_service';
import {GoogleMapsService} from './google_maps_service';
import {LayersService} from './layers_service';
import {LocalStorageService} from './local_storage_service';
import {MapPropertiesService} from './map_properties_service';

/**
 * Service for the map component.
 */
@Injectable()
export class MapService implements OnDestroy {
  map: google.maps.Map | null = null;
  mapReadyUpdates = new Subject<google.maps.Map>();
  projectionReadyUpdates = new Subject<void>();
  mapDestroyedUpdates = new Subject<void>();
  destroyed = new Subject<void>();
  pinLocation = new ReplaySubject<google.maps.LatLngLiteral>(1);
  mapCenter = new ReplaySubject<LatLng>(1);
  markerSelected = new ReplaySubject<Marker | MultiMarker>(1);
  visibilityByLayer = new ReplaySubject<VisibilityByLayerUpdate>(1);
  initialMarkerSelected = new ReplaySubject<string>(1);
  markerRemovals = new Subject<string>();
  deselectMarkerUpdates = new Subject<void>();
  offlineMapAreaSelectionTriggered = new Subject<void>();
  // Used by the details pages to determine whether or not they should request
  // the map repositioning.
  shouldRepositionMap = true;
  // The highlight area around the selected marker is implemented with another
  // marker.
  selectedHighlightMarker: google.maps.Marker | null = null;
  selectedHighlightIcon: google.maps.Icon | null = null;
  selectionArea: google.maps.Polygon | null = null;
  selectionBounds: google.maps.LatLngBounds | null = null;
  mapBoundsChangedListener: google.maps.MapsEventListener | null = null;

  cameraOptions: google.maps.CameraOptions = {
    tilt: 0,
    heading: 0,
    zoom: 3,
  };

  mapOptions!: google.maps.MapOptions;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private readonly appService: AppService,
    private readonly googleMapsService: GoogleMapsService,
    private readonly configService: ConfigService,
    private readonly localStorageService: LocalStorageService,
    private readonly layersService: LayersService,
    private readonly mapPropertiesService: MapPropertiesService,
    private readonly iconGenerator: IconGenerator,
  ) {
    this.googleMapsService
      .onGoogleMapsLoaded()
      .pipe(takeUntil(this.destroyed))
      .subscribe(() => {
        this.selectedHighlightIcon = this.iconGenerator.getSelectedHighlightIcon();

        this.cameraOptions.center = {
          lat: this.configService.mapCenter.latitude,
          lng: this.configService.mapCenter.longitude,
        };
      });

    this.appService
      .getWindowUnloadEvent()
      .pipe(takeUntil(this.destroyed))
      .subscribe(() => {
        this.saveSettings(this.map);
      });

    this.layersService
      .onLayersMetadataChanged()
      .pipe(take(1), takeUntil(this.destroyed))
      .subscribe((layers: Layer[]) => {
        const visibilityByLayer = this.buildInitialLayerVisibility(layers);
        this.setVisibilityByLayer({
          visibilityByLayerId: visibilityByLayer,
          userInitiated: false,
        });
      });
  }

  ngOnDestroy(): void {
    this.destroyed.next();
  }

  // Animate the camera with updated options.
  animateCamera(cameraOptions: google.maps.CameraOptions, durationMS: number = 15000) {
    new Tween(this.cameraOptions) // Create a new tween that modifies 'cameraOptions'.
      .to(cameraOptions, durationMS) // Move to destination.
      .easing(Easing.Quadratic.Out) // Use an easing function to make the animation smooth.
      .onUpdate(() => {
        if (!this.map) {
          return;
        }
        this.map.moveCamera(this.cameraOptions);
      })
      .start(); // Start the tween immediately.

    // Set up the animation loop.
    function animate(time: number) {
      requestAnimationFrame(animate);
      update(time);
    }

    requestAnimationFrame(animate);
  }

  private buildInitialLayerVisibility(layers: Layer[]): Map<string, boolean> {
    const visibleLayerKeysFromStorage = new Set<string>(this.readVisibleLayers());
    const visibilityByLayer = new Map<string, boolean>();
    for (const layer of layers) {
      const visible = visibleLayerKeysFromStorage.has(layer.id);
      visibilityByLayer.set(layer.id, visible);
    }
    return visibilityByLayer;
  }

  private readVisibleLayers(): string[] {
    return this.localStorageService.readMapLayers();
  }

  private async saveVisibleLayers() {
    const visibilityByLayerUpdate = await firstValueFrom(
      this.onLayerVisibilityChanged().pipe(take(1)),
    );
    const visibleLayerIds = [];
    for (const [layerId, visible] of visibilityByLayerUpdate.visibilityByLayerId) {
      if (visible) {
        visibleLayerIds.push(layerId);
      }
    }
    this.localStorageService.writeMapLayers(visibleLayerIds);
  }

  showLayer(layerId: string, show: boolean, userInitiated = false): Observable<boolean> {
    return this.onLayerVisibilityChanged().pipe(
      take(1),
      mergeMap((visibilityByLayerUpdate: VisibilityByLayerUpdate) => {
        if (!layerId) {
          return of(false);
        }
        if (visibilityByLayerUpdate.visibilityByLayerId.get(layerId) !== show) {
          const vblu: VisibilityByLayerUpdate = {
            visibilityByLayerId: new Map([
              [layerId, show],
              ...visibilityByLayerUpdate.visibilityByLayerId,
            ]),
            userInitiated,
          };
          this.setVisibilityByLayer(vblu);
        }
        return of(true);
      }),
    );
  }

  setVisibilityByLayer(visibilityByLayerUpdate: VisibilityByLayerUpdate) {
    const clone = {
      visibilityByLayerId: new Map(visibilityByLayerUpdate.visibilityByLayerId),
      userInitiated: visibilityByLayerUpdate.userInitiated ?? false,
    };
    this.visibilityByLayer.next(clone);
    this.saveVisibleLayers();
  }

  onLayerVisibilityChanged(): Observable<VisibilityByLayerUpdate> {
    return this.visibilityByLayer.asObservable();
  }

  /**
   * Deselects the selected marker if one exists on the map.
   */
  deselectMarker() {
    this.deselectMarkerUpdates.next();
  }

  onDeselectMarker(): Observable<void> {
    return this.deselectMarkerUpdates.asObservable();
  }

  setMapCenter(location: LatLng) {
    this.mapCenter.next(location);
  }

  onCenterChanged(): Observable<LatLng> {
    return this.mapCenter.asObservable();
  }

  /**
   * Drops a pin on the map when the map is ready.
   */
  setLocationPin(latLngLiteral: google.maps.LatLngLiteral) {
    this.pinLocation.next(latLngLiteral);
  }

  setMapStyles(theme: 'default' | 'silver' | 'dark' | 'night') {
    if (!this.map) {
      return;
    }

    switch (theme) {
      case 'silver':
        this.map.setOptions({styles: SILVER_THEME});
        break;
      case 'night':
        this.map.setOptions({styles: NIGHT_THEME});
        break;
      case 'dark':
        this.map.setOptions({styles: DARK_THEME});
        break;
      case 'default':
        this.map.setOptions({styles: DEFAULT_THEME});
        break;
      default:
        throw new Error(`unexpected value ${theme}!`);
    }
  }

  getLocationPin() {
    return this.pinLocation.asObservable();
  }

  updateMarkerSelectedState(marker: google.maps.Marker, select: boolean) {
    if (select) {
      marker.setIcon(marker.get(MarkerProperty.SELECTED_ICON));
      marker.setZIndex(this.mapPropertiesService.ACTIVE_MARKER_Z_INDEX);
      if (!marker.getPosition()) {
        console.error(`Marker is missing location: ${marker.getLabel()}`);
      } else {
        this.addHighlightAroundSelected(marker.getPosition()!);
      }
      return;
    }
    marker.setIcon(marker.get(MarkerProperty.DESELECTED_ICON));
    marker.setZIndex(this.mapPropertiesService.INACTIVE_MARKER_Z_INDEX);
    this.removeHighlightAroundSelected();
  }

  /**
   * Sets the map when it becomes ready for rendering to.
   * @param map: the map object that all layers render to.
   */
  mapReady(map: google.maps.Map) {
    this.map = map;
    this.mapReadyUpdates.next(map);
  }

  getMapReady(): Observable<google.maps.Map> {
    return this.mapReadyUpdates.asObservable();
  }

  /**
   * Notifies about projection becoming available.
   */
  projectionReady() {
    this.projectionReadyUpdates.next();
  }

  /**
   * Provides an update on when projection becomes available.
   */
  getProjectionReady(): Observable<void> {
    return this.projectionReadyUpdates.asObservable();
  }

  /**
   * Save settings, clear relevant map state and emit an event that the map has
   * been destroyed.
   */
  mapDestroyed() {
    this.saveSettings(this.map);
    this.pinLocation = new ReplaySubject<google.maps.LatLngLiteral>(1);
    this.mapCenter = new ReplaySubject<LatLng>(1);
    this.markerSelected = new ReplaySubject<Marker | MultiMarker>(1);
    this.mapDestroyedUpdates.next();
  }

  getMapDestroyed(): Observable<void> {
    return this.mapDestroyedUpdates.asObservable();
  }

  /**
   * Called when a user wants to save settings, eg, before the page closes.
   */
  private saveSettings(map: google.maps.Map | null): void {
    if (!map) {
      return;
    }
    const lat = map.getCenter()?.lat() || 0;
    const lng = map.getCenter()?.lng() || 0;
    this.localStorageService.writeMapCenter({lat, lng});
    this.localStorageService.writeMapZoom(
      map.getZoom() || this.mapPropertiesService.INITIAL_ZOOM_LEVEL,
    );
    this.saveVisibleLayers();
  }

  /*
   * Returns the center saved in local storage or default center
   */
  getSavedCenter(useDefault?: boolean): google.maps.LatLng {
    if (!useDefault) {
      const result = this.localStorageService.readMapCenter();
      if (result) return new google.maps.LatLng(result);
    }
    return new google.maps.LatLng(
      this.configService.mapCenter.latitude,
      this.configService.mapCenter.longitude,
    );
  }

  /*
   * Returns the zoom level saved in local storage or default zoom
   */
  getSavedZoom(useDefault?: boolean) {
    const initialZoom = this.mapPropertiesService.INITIAL_ZOOM_LEVEL;
    if (useDefault) {
      return initialZoom;
    }

    const zoom = this.localStorageService.readMapZoom();
    return zoom || initialZoom;
  }

  getShouldRepositionMap(): boolean {
    return this.shouldRepositionMap;
  }

  setShouldRepositionMap(shouldRepositionMap: boolean) {
    this.shouldRepositionMap = shouldRepositionMap;
  }

  removeHighlightAroundSelected() {
    if (this.selectedHighlightMarker) {
      this.selectedHighlightMarker.setMap(null);
      this.selectedHighlightMarker = null;
    }
  }

  addHighlightAroundSelected(position: google.maps.LatLng) {
    this.removeHighlightAroundSelected();
    this.selectedHighlightMarker = new google.maps.Marker({
      position,
      icon: this.selectedHighlightIcon!,
      map: this.map!,
      clickable: false,
      zIndex: 0,
    });
  }

  /**
   * Once the map has fully loaded, emits offline map area selection event.
   * Waits for map projection to be available first since bounding box
   * calculations will rely on projection.
   */
  selectOfflineAreaOnMapLoad() {
    this.getProjectionReady()
      .pipe(first(), takeUntil(this.destroyed))
      .subscribe(() => {
        this.selectOfflineArea();
      });
  }

  /**
   * Emits offline map area selection event.
   */
  selectOfflineArea() {
    this.offlineMapAreaSelectionTriggered.next();
  }

  /**
   * Provides a way for clients to get notified on offline map area selection
   * event.
   */
  onSelectOfflineArea(): Observable<void> {
    return this.offlineMapAreaSelectionTriggered.asObservable();
  }

  removeMarker(featureId: string) {
    this.markerRemovals.next(featureId);
  }

  onRemoveMarker(): Observable<string> {
    return this.markerRemovals.asObservable();
  }
}
