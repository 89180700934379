import {Observable, Subject, combineLatest, forkJoin, merge} from 'rxjs';
import {
  concatAll,
  defaultIfEmpty,
  filter,
  map,
  reduce,
  switchMap,
  take,
  takeUntil,
} from 'rxjs/operators';

import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Router} from '@angular/router';

import {Comment} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/comment_pb';
import {LifecycleStage} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/common_pb';
import {Feature} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/feature_pb';
import {
  RelatedFeature,
  RelatedFeaturesGroup_RelatedFeatureRole as RelatedFeatureRole,
  RelatedFeaturesGroup,
} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/related_feature_pb';

import {DISPLAY_NAME_BY_TOGO} from '../constants/image';
import {ROUTE} from '../constants/paths';
import {PropertyKey} from '../constants/properties';
import {AnalyticsService, EventActionType, EventCategoryType} from '../services/analytics_service';
import {CommentsService} from '../services/comments_service';
import {FeaturesService} from '../services/features_service';
import {LayersService} from '../services/layers_service';
import {MapService} from '../services/map_service';
import {dateStringToDate, isDifferentDate} from '../utils/date';
import {getPropertyValue} from '../utils/feature';
import {
  removeEmptyAndIgnoredProperties,
  removeSensitiveProperties,
  sortPropertiesByPriority,
} from '../utils/properties';
import {TimelineEntry, TimelineEntryAction, TimelineEntryType} from './common';

interface RelatedFeatureWithLayer {
  feature: RelatedFeature;
  layerId: string;
}

const TOAST_DURATION_MS = 2500;
const NUMBER_OF_PROPERTIES_TO_DISPLAY = 4;
// TODO(halinab): reconsider the fill-in date to use.
// The date to use in supposively rare case when the specific entry is missing
// one. This date is intentionally in the past, but not too long in the past in
// order not to stand out.
const MISSING_DATE = new Date('2015-01-01T00:00:00');

/**
 * Component for rendering feature timeline.
 */
@Component({
  selector: 'timeline',
  templateUrl: './timeline.ng.html',
  styleUrls: ['./timeline.scss'],
})
export class Timeline implements OnInit, OnDestroy {
  @Input() feature!: Observable<Feature>;

  // Alias to the enum.
  readonly TimelineEntryType = TimelineEntryType;

  // Data to be displayed in the timeline.
  timelineEntries: TimelineEntry[] = [];

  currentFeatureId: string = '';

  private readonly destroyed = new Subject<void>();

  constructor(
    private readonly analyticsService: AnalyticsService,
    private readonly commentsService: CommentsService,
    private readonly featuresService: FeaturesService,
    private readonly layersService: LayersService,
    private readonly mapService: MapService,
    private readonly router: Router,
    private readonly snackBar: MatSnackBar,
  ) {}

  ngOnInit() {
    this.getTimeline(false);
  }

  ngOnDestroy() {
    this.destroyed.next();
    this.destroyed.complete();
  }

  addCommentAndRefresh(comment: Comment) {
    const newEntry = this.extractCommentTimelineEntry(comment);
    const newTimelineEntries = [...this.timelineEntries, newEntry];
    this.timelineEntries = this.sortUpdates(newTimelineEntries);
  }

  goToRelatedFeature(entry: TimelineEntry) {
    this.sendTimelineEvent(entry, EventActionType.TIMELINE_FEATURE_NAVIGATION);
    this.mapService.setShouldRepositionMap(true);
    this.router.navigate([ROUTE.MAP, entry.layerId, entry.featureId]);
  }

  /**
   * Retrieves the timeline - updates related to the selected feature.
   */
  private getTimeline(forceFetch: boolean) {
    return this.feature
      .pipe(
        switchMap((feature: Feature) => this.requestTimelineData(feature, forceFetch)),
        takeUntil(this.destroyed),
      )
      .subscribe({
        next: (updates: TimelineEntry[]) => {
          this.timelineEntries = this.sortUpdates(updates);
        },
        error: () => {
          this.snackBar.open('Could not load timeline.', '', {
            duration: TOAST_DURATION_MS,
          });
        },
      });
  }

  /**
   * Fetches the timeline data related to the selected feature.
   */
  private requestTimelineData(feature: Feature, forceFetch: boolean): Observable<TimelineEntry[]> {
    this.currentFeatureId = feature.id;
    return combineLatest([
      this.requestComments(feature),
      this.requestImageGroups(feature, forceFetch),
      this.requestRelatedFeatures(feature, forceFetch),
    ]).pipe(
      map(([comments, imageGroups, relatedFeatures]): TimelineEntry[] => [
        ...comments,
        ...imageGroups,
        ...relatedFeatures,
      ]),
    );
  }

  /**
   * Fetches the comments related to the selected feature.
   */
  private requestComments(feature: Feature): Observable<TimelineEntry[]> {
    return this.commentsService
      .getComments(feature.id, true)
      .pipe(
        map((rawComments: Comment[]): TimelineEntry[] =>
          rawComments.map(
            (rawComment: Comment): TimelineEntry => this.extractCommentTimelineEntry(rawComment),
          ),
        ),
      );
  }

  /**
   * Fetches the image groups related to the selected feature.
   */
  private requestImageGroups(feature: Feature, forceFetch: boolean): Observable<TimelineEntry[]> {
    // Only active image features.
    const imageFeatures = this.extractRelatedFeatures(
      feature,
      (featureGroup: RelatedFeaturesGroup) => this.isImageGroup(featureGroup),
      true,
    );

    const updates: Array<Observable<TimelineEntry>> = imageFeatures.map(
      (relatedFeature: RelatedFeatureWithLayer): Observable<TimelineEntry> =>
        this.requestFeature(relatedFeature.feature, forceFetch).pipe(
          map((feature: Feature): TimelineEntry => {
            return this.extractImageGroupTimelineEntry(feature, relatedFeature.layerId);
          }),
        ),
    );

    return forkJoin(updates).pipe(
      // Default case for when there are no image groups associated with the
      // feature.
      defaultIfEmpty([]),
    );
  }

  /**
   * Fetches the non-image features related to the selected feature.
   */
  private requestRelatedFeatures(
    feature: Feature,
    forceFetch: boolean,
  ): Observable<TimelineEntry[]> {
    // Active and inactive features that are not image related.
    const relatedFeatures = this.extractRelatedFeatures(
      feature,
      (featureGroup: RelatedFeaturesGroup) => this.isNonImageFeature(featureGroup),
      false,
    );

    const updates: Array<Observable<TimelineEntry>> = relatedFeatures.map(
      (relatedFeature: RelatedFeatureWithLayer): Observable<TimelineEntry> =>
        this.requestFeature(relatedFeature.feature, forceFetch).pipe(
          map((feature: Feature): TimelineEntry[] => {
            return this.extractFeatureTimelineEntries(feature, relatedFeature.layerId);
          }),
          concatAll(),
        ),
    );

    return merge(...updates).pipe(
      // Gather all updates
      reduce((allEntries: TimelineEntry[], entry: TimelineEntry) => allEntries.concat([entry]), []),
    );
  }

  /**
   * Fetches the related feature by ID.
   */
  private requestFeature(relatedFeature: RelatedFeature, forceFetch: boolean): Observable<Feature> {
    return this.featuresService
      .getFeature(relatedFeature.layerId, relatedFeature.id, forceFetch)
      .pipe(
        filter((feature: Feature | null) => feature !== null),
        map((feature: Feature | null): Feature => feature!),
        take(1),
      );
  }

  /**
   * Sorts the timeline entries by date.
   */
  private sortUpdates(updates: TimelineEntry[]): TimelineEntry[] {
    return updates.sort((updateA: TimelineEntry, updateB: TimelineEntry): number => {
      return (updateB.date?.getTime() || 0) - (updateA.date?.getTime() || 0);
    });
  }

  /**
   * Extracts updates from the raw comment received from backend
   * for display in feature's timeline.
   */
  private extractCommentTimelineEntry(comment: Comment): TimelineEntry {
    return {
      type: TimelineEntryType.COMMENT,
      action: TimelineEntryAction.ADDED,
      record: comment,
      featureId: comment.id || '',
      layerId: '',
      details: comment.content?.body || '',
      date: comment.createdAt ? comment.createdAt!.toDate() : MISSING_DATE,
    };
  }

  /**
   * Extracts updates from the raw image group received from backend
   * for display in feature's timeline.
   */
  private extractImageGroupTimelineEntry(feature: Feature, layerId: string): TimelineEntry {
    const featureId = feature.id || '';
    return {
      type: TimelineEntryType.IMAGE,
      action: TimelineEntryAction.ADDED,
      record: feature,
      featureId,
      layerId,
      details: '',
      date: this.extractImageGroupUploadTime(feature),
    };
  }

  /**
   * Extracts updates from the raw related feature received from backend
   * for display in feature's timeline. This takes into account feature creation
   * date as well as last updated date, resulting in two entries if both update
   * and creation were detected.
   */
  private extractFeatureTimelineEntries(feature: Feature, layerId: string): TimelineEntry[] {
    const featureId = feature.id || '';
    const properties = this.extractFeatureProperties(feature, layerId);
    const creationDate = feature.createdAt?.toDate() || null;
    const closingDate = feature.closedAt?.toDate() || null;
    const lastUpdatedDate = feature.updatedAt?.toDate() || null;
    const updates: TimelineEntry[] = [];
    if (creationDate) {
      updates.push({
        type: TimelineEntryType.RELATED_FEATURE,
        action: TimelineEntryAction.ADDED,
        record: feature,
        featureId,
        layerId,
        details: properties,
        date: creationDate,
      });
    }

    if (closingDate) {
      updates.push({
        type: TimelineEntryType.RELATED_FEATURE,
        action: TimelineEntryAction.REMOVED,
        record: feature,
        featureId,
        layerId,
        details: properties,
        date: closingDate,
      });
    }

    // only show updates that are different from creation and closing
    if (
      lastUpdatedDate &&
      isDifferentDate(creationDate, lastUpdatedDate) &&
      isDifferentDate(closingDate, lastUpdatedDate)
    ) {
      updates.push({
        type: TimelineEntryType.RELATED_FEATURE,
        action: TimelineEntryAction.UPDATED,
        record: feature,
        featureId,
        layerId,
        details: properties,
        date: lastUpdatedDate,
      });
    }

    return updates;
  }

  /**
   * Formats the related feature property name.
   */
  private formatPropertyName(property: string): string {
    return DISPLAY_NAME_BY_TOGO.get(property) || property;
  }

  private extractFeatureProperties(feature: Feature, layerId: string): {[key: string]: string} {
    let properties = removeSensitiveProperties(feature.properties);
    let ignorePropertyKeys: string[] = [];
    let priorityPropertyKeys: string[] = [];
    if (layerId) {
      const layerStyle = this.layersService.getLayerStyle(layerId);
      ignorePropertyKeys = layerStyle?.ignorePropertyKeys || [];
      priorityPropertyKeys = layerStyle?.priorityPropertyKeys || [];
    }
    properties = removeEmptyAndIgnoredProperties(properties, ignorePropertyKeys);
    properties = sortPropertiesByPriority(properties, priorityPropertyKeys);
    const displayProperties = properties.slice(0, NUMBER_OF_PROPERTIES_TO_DISPLAY);

    const formattedProperties: {[key: string]: string} = {};
    for (const property of displayProperties) {
      formattedProperties[this.formatPropertyName(property.key)] =
        property.propertyValue.case === 'value' ? property.propertyValue.value : '';
    }
    return formattedProperties;
  }

  private extractRelatedFeatures(
    feature: Feature,
    filterFn: (group: RelatedFeaturesGroup) => boolean,
    activeOnly: boolean,
  ) {
    const featureGroups = feature.relatedFeaturesGroups.filter(filterFn);

    const relatedFeatures = featureGroups.flatMap(
      (featureGroup: RelatedFeaturesGroup): RelatedFeatureWithLayer[] => {
        return featureGroup.relatedFeatures
          .filter((relatedFeature: RelatedFeature) => {
            return !activeOnly || this.isFeatureActive(relatedFeature);
          })
          .map((relatedFeature: RelatedFeature): RelatedFeatureWithLayer => {
            const relatedLayerId = relatedFeature.layerId;
            return {
              feature: relatedFeature,
              layerId: relatedLayerId,
            };
          });
      },
    );

    return relatedFeatures;
  }

  private extractImageGroupUploadTime(feature: Feature): Date {
    const properties = feature.properties;
    const dateStr = getPropertyValue(PropertyKey.DATE_UPLOADED, properties);
    if (dateStr !== '') {
      return dateStringToDate(dateStr);
    }
    return feature.updatedAt?.toDate() || MISSING_DATE;
  }

  /**
   * Returns whether the feature is active. Inactive features typically signal
   * that they were closed/removed.
   */
  private isFeatureActive(feature: Feature | RelatedFeature): boolean {
    return feature.lifecycleStage === LifecycleStage.ACTIVE;
  }

  private isImageGroup(featureGroup: RelatedFeaturesGroup): boolean {
    return featureGroup.role === RelatedFeatureRole.CHILD_DEFECT;
  }

  private isNonImageFeature(featureGroup: RelatedFeaturesGroup): boolean {
    return !this.isImageGroup(featureGroup) && featureGroup.role !== RelatedFeatureRole.CHILD_IMAGE;
  }

  private sendTimelineEvent(entry: TimelineEntry, action: EventActionType) {
    // Only Feature's are currently able to be navigated to and expanded.
    const name = entry.record instanceof Feature ? entry.record.name : '';
    this.analyticsService.sendEvent(action, {
      event_category: EventCategoryType.TIMELINE,
      event_label: name,
    });
  }

  sendTimelineExpandedEvent(entry: TimelineEntry) {
    this.sendTimelineEvent(entry, EventActionType.TIMELINE_EXPANDED);
  }
}
