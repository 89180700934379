import {Observable, ReplaySubject, Subject, firstValueFrom, merge} from 'rxjs';
import {take, takeUntil} from 'rxjs/operators';

import {Injectable, OnDestroy} from '@angular/core';

import {Layer} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/layer_pb';

import {FilterMap, FilterMapByLayerId, FilterUpdate, LayerFilters} from '../typings/filter';
import {AppService} from './app_service';
import {LayersService} from './layers_service';
import {LocalStorageService} from './local_storage_service';
import {MapService} from './map_service';

/**
 * Service for handling all layer filtering related tasks.
 */
@Injectable()
export class LayersFilterService implements OnDestroy {
  // A map to the replay subject for each layers associated filters.
  // When a filter is added or removed, the relative replay subject will emit.
  private readonly filterMapByLayerId$: {
    [layerKey: string]: ReplaySubject<FilterMap>;
  } = {};
  private readonly filterMapByLayerId: FilterMapByLayerId = {};
  // Will emit a layer ID when a filter updates, indicating the layer has
  // had filters updated.
  private readonly filterUpdates = new Subject<FilterUpdate>();
  private readonly includeInactiveByLayerId = new Map<string, ReplaySubject<boolean>>();
  private readonly destroyed = new Subject<void>();

  // Emits an initial state of filters loaded from local storage.
  // Not to be used for continuous updates.
  readonly layerFiltersInitialState = new ReplaySubject<LayerFilters[]>(1);

  constructor(
    private readonly appService: AppService,
    private readonly localStorageService: LocalStorageService,
    private readonly mapService: MapService,
    private readonly layersService: LayersService,
  ) {
    merge(this.mapService.getMapDestroyed(), this.appService.getWindowUnloadEvent())
      .pipe(takeUntil(this.destroyed))
      .subscribe(() => {
        this.localStorageService.writeLayerFilters(this.filterMapByLayerId);
        this.saveIncludeInactive();
      });
    this.layersService
      .onLayersMetadataChanged()
      .pipe(take(1), takeUntil(this.destroyed))
      .subscribe((layers: Layer[]) => {
        this.initFilterState(layers);
      });
  }

  ngOnDestroy(): void {
    this.destroyed.next();
    this.localStorageService.writeLayerFilters(this.filterMapByLayerId);
    this.saveIncludeInactive();
  }

  // Read filter maps and include inactives per layer from local storage.
  private initFilterState(layers: Layer[]) {
    const filterMapByLayerId = this.localStorageService.readLayerFilters();
    const includeInactivesFromStorage = this.localStorageService.readIncludeInactive();
    const layerFilters = [];
    for (const layer of layers) {
      const layerId = layer.id;
      const filterMap = filterMapByLayerId[layerId] || {};
      const includeInactive = !!includeInactivesFromStorage.get(layerId);
      this.setFilterMapForLayer(layerId, filterMap);
      this.setIncludeInactiveForLayer(layerId, includeInactive);
      // Setting global search to false when the filters init which means
      // that the feature query will be bounded by the map coordinates and the
      // map will not reposition.
      this.filterUpdates.next({
        layerId,
        globalSearch: false,
        userInitiated: false,
      });
      layerFilters.push({
        layerId,
        filters: filterMap,
        includeInactiveResults: false,
      });
    }
    this.layerFiltersInitialState.next(layerFilters);
  }

  private async saveIncludeInactive() {
    const map = new Map<string, boolean>();
    for (const [layerId, includeInactive$] of this.includeInactiveByLayerId) {
      const includeInactive = await firstValueFrom(includeInactive$.pipe(take(1)));
      map.set(layerId, includeInactive);
    }
    this.localStorageService.writeIncludeInactive(map);
  }

  /**
   * Returns an observable that emits a layer key when the filters of a layer
   * have changed and the selection is complete
   */
  layerFiltersUpdated(): Observable<FilterUpdate> {
    return this.filterUpdates.asObservable();
  }

  updateIncludeInactive(
    layerId: string,
    includeInactive: boolean,
    userInitiated: boolean,
    globalSearch = false,
  ) {
    this.setIncludeInactiveForLayer(layerId, includeInactive);
    // Emit a layer filter updated event which will refetch a layer.
    this.filterUpdates.next({
      layerId,
      globalSearch: globalSearch,
      userInitiated,
    });
  }

  /**
   * Returns an observable that emits when include inactives checkbox is
   * toggled for a particular layer.
   */
  includeInactive(layerId: string): Observable<boolean> {
    if (!this.includeInactiveByLayerId.has(layerId)) {
      this.includeInactiveByLayerId.set(layerId, new ReplaySubject<boolean>(1));
    }
    return this.includeInactiveByLayerId.get(layerId)!.asObservable();
  }

  /**
   * Returns an observable that emits a filter dictionary for the given
   * layer key anytime an update occurs
   * @param layerId: the identifying string of a layer
   */
  getFilterMap(layerId: string): Observable<FilterMap> {
    if (!this.filterMapByLayerId$[layerId]) {
      this.filterMapByLayerId$[layerId] = new ReplaySubject<FilterMap>(1);
    }
    return this.filterMapByLayerId$[layerId].asObservable();
  }

  getAutocompleteResults$(
    layerId: string,
    name: string,
    substring: string,
    includeInactive: boolean,
    maxResults: number,
  ): Observable<string[]> {
    return this.layersService.getAutocompleteResults(
      layerId,
      name,
      substring,
      includeInactive,
      maxResults,
    );
  }

  /**
   * Adds a filter to a layer.
   * @param name: name of filter to add to layer.
   * @param value: value associated with filter name.
   */
  addFilter(layerId: string, name: string, valueSet: Set<string>): void {
    const newFilterMap: FilterMap = {
      ...this.filterMapByLayerId[layerId],
      [name]: new Set(valueSet),
    };
    const userInitiated = true;
    this.updateFilterMap(layerId, newFilterMap, userInitiated);
  }

  /**
   * Removes a filter from a layer.
   * @param name: name of filter to remove from layer.
   */
  removeFilter(layerId: string, name: string): void {
    const newFilterMap: FilterMap = {...this.filterMapByLayerId[layerId]};
    delete newFilterMap[name];
    const userInitiated = true;
    this.updateFilterMap(layerId, newFilterMap, userInitiated);
  }

  updateFilterMap(layerId: string, filterMap: FilterMap, userInitiated: boolean) {
    this.setFilterMapForLayer(layerId, filterMap);
    this.filterUpdates.next({layerId, globalSearch: true, userInitiated});
  }

  private setFilterMapForLayer(layerId: string, filters: FilterMap) {
    this.filterMapByLayerId[layerId] = filters;
    if (!this.filterMapByLayerId$[layerId]) {
      this.filterMapByLayerId$[layerId] = new ReplaySubject(1);
    }
    this.filterMapByLayerId$[layerId].next(filters);
  }

  private setIncludeInactiveForLayer(layerId: string, includeInactive: boolean) {
    if (!this.includeInactiveByLayerId.has(layerId)) {
      this.includeInactiveByLayerId.set(layerId, new ReplaySubject<boolean>(1));
    }
    this.includeInactiveByLayerId.get(layerId)!.next(includeInactive);
  }
}
