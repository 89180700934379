import {LatLng} from '@tapestry-energy/npm-prod/google/type/latlng_pb';
import {Subject, takeUntil} from 'rxjs';

import {
  ComponentFactoryResolver,
  ComponentRef,
  Injectable,
  Optional,
  ViewContainerRef,
} from '@angular/core';
import {Router} from '@angular/router';

import {
  ImageAssociationStatus,
  LifecycleStage,
} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/common_pb';
import {Feature, Property} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/feature_pb';
import {
  Point,
  Polygon,
  Polyline,
} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/geometry_pb';
import {PointMarker} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/layer_pb';

import {
  DEFAULT_MULTI_MARKER_BACK_ICON_COLOR,
  DEFAULT_MULTI_MARKER_FRONT_ICON_COLOR,
  ICON_COLOR_ATTRIBUTE,
  UNASSIGNED_ICON_COLOR,
} from '../constants/icon';
import {ASSETS_LAYER_ID} from '../constants/layer';
import {MarkerProperty} from '../constants/marker';
import {ROUTE} from '../constants/paths';
import {AnalyticsService, EventActionType, EventCategoryType} from '../services/analytics_service';
import {ConfigService} from '../services/config_service';
import {DeckGLService} from '../services/deckgl_service';
import {FeaturesService} from '../services/features_service';
import {GoogleMapsService} from '../services/google_maps_service';
import {LayersService} from '../services/layers_service';
import {MapPropertiesService} from '../services/map_properties_service';
import {MapService} from '../services/map_service';
import {SidepanelService} from '../services/sidepanel_service';
import {Icon, IconGeneratorMetadata} from '../typings/icon';
import {FeatureMetadata, Marker, MultiMarker, MultiMarkerOpenedWindowEvent} from '../typings/map';
import {getPropertyValue} from '../utils/feature';
import {
  IconGenerator,
  IconSvgForPointMaker,
  MultiMarkerIconColor,
  MultiMarkerIconProperties,
} from '../utils/icon_util';
import {sortFeatureMetadata} from '../utils/sort';
import {
  MultiMarkerDetails,
  MultiMarkerDetailsInput,
} from './multi_marker_details/multi_marker_details';

/**
 * A click function that fires when an icon is selected.
 */
export type IconSelectedFn = (marker: Marker, feature: Feature) => void;

/**
 * An empty click function.
 */
export const DEFAULT_ICON_SELECTED_FUNCTION: IconSelectedFn = (() => {}) as IconSelectedFn;

const POLYGON_STROKE_WEIGHT = 2;
const POLYGON_STROKE_WEIGHT_HOVER = 5;
const DEFAULT_POLYLINE_STROKE_COLOR = '#000';
const DEFAULT_MARKER_TOOLTIP_VERTICAL_PIXEL_OFFSET = -24;
const DEFAULT_OPACITY = '1';
const OPACITY_INACTIVE = '0.5';
const DEFAULT_BORDER_COLOR = '#000';
const DEFAULT_MULTI_MARKER_ICON_PROPERTIES = {
  fillColor: '',
  borderColor: DEFAULT_BORDER_COLOR,
  isMixedImage: false,
};

/**
 * The pattern that returns an icon's color from an SVG string.
 */
const ICON_COLOR_PATTERN = new RegExp(`${ICON_COLOR_ATTRIBUTE}="(.+)"`);

/**
 * Used to create markers for rendering on a map.
 */
@Injectable({providedIn: 'root'})
export class MarkersFactory {
  destroyed = new Subject<void>();
  gmInfoWindow: google.maps.InfoWindow | null = null;
  protected imageSourceFilterEnabled = false;
  constructor(
    private readonly analyticsService: AnalyticsService,
    private readonly componentFactorResolver: ComponentFactoryResolver,
    private readonly googleMapsService: GoogleMapsService,
    private readonly iconGenerator: IconGenerator,
    private readonly featureService: FeaturesService,
    private readonly layersService: LayersService,
    private readonly mapPropertiesService: MapPropertiesService,
    private readonly mapService: MapService,
    private readonly router: Router,
    private readonly sidepanelService: SidepanelService,
    private readonly configService: ConfigService,
    @Optional() private readonly deckglService: DeckGLService | null,
  ) {
    this.imageSourceFilterEnabled = this.configService.imageSourceFilterEnabled;
    this.googleMapsService
      .onGoogleMapsLoaded()
      .pipe(takeUntil(this.destroyed))
      .subscribe(() => {
        this.gmInfoWindow = new google.maps.InfoWindow();
      });
    this.deckglService?.multiMarkerOpenedWindow.subscribe(
      (event: MultiMarkerOpenedWindowEvent | null) => {
        if (!event) {
          return;
        }
        this.openmultiMarkerWindow(
          event.infoWindowVCRef,
          event.multiMarker,
          event.multiMarker.featuresMetadata,
          event.multiMarker.marker,
          this.mapService.map,
          false,
        );
      },
    );
  }

  updateMultiMarkerIcon(multiMarker: MultiMarker) {
    const multiMarkerIconColor = this.getMultiMarkerIconColor(multiMarker.featuresMetadata);
    const icon = this.iconGenerator.getMultiMarkerIcon(
      String(multiMarker.featuresMetadata.length),
      multiMarkerIconColor,
    );
    multiMarker.marker.setIcon(icon.deselected);
    this.setMarkerIconMetadata(multiMarker.marker, icon.deselected, icon.selected);
  }

  private createMultiMarkerComponent(vCRef: ViewContainerRef): ComponentRef<MultiMarkerDetails> {
    const factory = this.componentFactorResolver.resolveComponentFactory(MultiMarkerDetails);
    const componentRef: ComponentRef<MultiMarkerDetails> = vCRef.createComponent(factory);
    return componentRef;
  }

  private createMultiMarkerDetailsInput(
    featuresMetadata: FeatureMetadata[],
  ): MultiMarkerDetailsInput[] {
    return featuresMetadata.map(({feature, layerId}: FeatureMetadata) => {
      return {
        feature,
        layerId,
        iconUrl: this.getMarkerIconUrl(feature, layerId),
      };
    });
  }

  createMultiMarker(
    location: LatLng,
    featuresMetadata: FeatureMetadata[],
    infoWindowVCRef: ViewContainerRef,
  ): MultiMarker {
    const marker = new google.maps.Marker({
      position: new google.maps.LatLng(location.latitude, location.longitude),
      zIndex: this.mapPropertiesService.INACTIVE_MARKER_Z_INDEX,
      visible: true,
    });
    const multiMarker: MultiMarker = {
      marker,
      featuresMetadata,
    };
    this.updateMultiMarkerIcon(multiMarker);
    marker.addListener('click', () => {
      this.openmultiMarkerWindow(
        infoWindowVCRef,
        multiMarker,
        featuresMetadata,
        marker,
        this.mapService.map,
      );
    });
    return multiMarker;
  }

  private openmultiMarkerWindow(
    infoWindowVCRef: ViewContainerRef,
    multiMarker: MultiMarker,
    featuresMetadata: FeatureMetadata[],
    marker: google.maps.Marker,
    map: google.maps.Map | null,
    highlight = true,
  ) {
    infoWindowVCRef.clear();
    const multiMarkerRef = this.createMultiMarkerComponent(infoWindowVCRef);
    multiMarkerRef.instance.details = this.createMultiMarkerDetailsInput(
      multiMarker.featuresMetadata.sort(sortFeatureMetadata),
    );

    if (!multiMarker.marker.getPosition()) {
      return;
    }

    this.mapService.deselectMarker();
    if (highlight) {
      this.mapService.addHighlightAroundSelected(multiMarker.marker.getPosition()!);
    }
    this.showTooltip(
      infoWindowVCRef.element.nativeElement.parentElement,
      marker.getPosition()!,
      DEFAULT_MARKER_TOOLTIP_VERTICAL_PIXEL_OFFSET,
      (marker.getMap() as google.maps.Map) || map,
    );
    this.sidepanelService.setSidepanelOpened(true);
    this.mapService.setShouldRepositionMap(false);
  }

  private getMultiMarkerIconColor(featuresMetadata: FeatureMetadata[]): MultiMarkerIconColor {
    const featureMetadataByLayerId = new Map<string, FeatureMetadata>();
    for (const featureMetadata of [...featuresMetadata].sort(sortFeatureMetadata)) {
      featureMetadataByLayerId.set(featureMetadata.layerId, featureMetadata);
    }
    let frontColor = DEFAULT_MULTI_MARKER_ICON_PROPERTIES;
    let backColor = DEFAULT_MULTI_MARKER_ICON_PROPERTIES;
    const firstTwo = [...featureMetadataByLayerId.values()].slice(0, 2);
    if (this.hasFrontIconImageAssociationStatus(firstTwo)) {
      frontColor = this.getMultiMarkerImageAssociationStatus(featuresMetadata);
    }
    if (this.hasBackIconImageAssociationStatus(firstTwo)) {
      backColor = this.getMultiMarkerImageAssociationStatus(featuresMetadata);
    }

    const frontIconUrl = this.getMarkerIconUrl(firstTwo[0].feature, firstTwo[0].layerId);
    if (!this.hasFrontIconImageAssociationStatus(firstTwo)) {
      frontColor = {
        ...frontColor,
        fillColor: this.getColor(frontIconUrl, DEFAULT_MULTI_MARKER_FRONT_ICON_COLOR),
      };
    }
    if (!this.hasBackIconImageAssociationStatus(firstTwo)) {
      const backIconUrl = !firstTwo[1]
        ? frontIconUrl
        : this.getMarkerIconUrl(firstTwo[1].feature, firstTwo[1].layerId);

      backColor = {
        ...backColor,
        fillColor: this.getColor(backIconUrl, DEFAULT_MULTI_MARKER_BACK_ICON_COLOR),
      };
    }
    return {
      front: frontColor,
      back: backColor,
    };
  }

  getColor(iconUrl: string, defaultColor: string): string {
    const matchedBackColors = decodeURIComponent(iconUrl).match(ICON_COLOR_PATTERN);
    return matchedBackColors?.[1] ? matchedBackColors[1] : defaultColor;
  }

  private getMarkerIconUrl(feature: Feature, layerId: string): string {
    const markers = this.createFeatureMarkers([feature], layerId, DEFAULT_ICON_SELECTED_FUNCTION);
    const marker = markers[0].marker as google.maps.Marker;
    if ((marker.getIcon() as google.maps.Icon)?.url) {
      return (marker.getIcon() as google.maps.Icon).url!;
    }
    return marker.getIcon() as string;
  }

  createFeatureMarkers(
    features: Feature[],
    layerId: string,
    iconSelectedFn: IconSelectedFn,
  ): Marker[] {
    const layerName = this.layersService.getLayerName(layerId) || 'Layer name not found';
    const markers: Marker[] = [];
    for (const feature of features) {
      if (!this.featureService.checkGeometryExists(feature)) {
        // TODO(reubenn): Add some type of debug-time logging.
        continue;
      }
      let marker: Marker | null = null;
      switch (feature.geometry?.geometry.case) {
        case 'point':
          marker = this.createPointMarker(layerId, layerName, feature, iconSelectedFn);
          break;
        case 'polygon':
          marker = this.createPolygonMarker(layerId, layerName, feature);
          break;
        case 'lineString':
          marker = this.createPolylineMarker(layerId, layerName, feature);
          break;
      }
      if (marker) {
        markers.push(marker);
      }
    }
    return markers;
  }

  private layerIconStyleExists(layerId: string): boolean {
    const pointMarker = this.layersService.getLayerStyle(layerId)?.pointMarker;
    return !!(
      pointMarker?.unselectedIconSvg ||
      pointMarker?.selectedIconSvg ||
      pointMarker?.colorPalette.length ||
      pointMarker?.colorSchema['size'] ||
      pointMarker?.labelPropertyKey ||
      pointMarker?.colorPropertyKey
    );
  }

  createFeatureIcon(feature: Feature, layerId: string): Icon {
    const opacity =
      feature.lifecycleStage === LifecycleStage.INACTIVE ? OPACITY_INACTIVE : DEFAULT_OPACITY;
    if (!this.layerIconStyleExists(layerId)) {
      return this.iconGenerator.getIcon({layerId, opacity});
    }
    const iconGeneratorMetadata: IconGeneratorMetadata = {layerId, opacity};
    const pointMarker = this.layersService.getLayerStyle(layerId)!.pointMarker!;
    const properties = feature.properties;
    const label = getPropertyValue(pointMarker.labelPropertyKey, properties);
    if (label) {
      iconGeneratorMetadata.label = label;
    }
    this.fillInIconColorInMetadata(properties, pointMarker, iconGeneratorMetadata);
    if (!this.imageSourceFilterEnabled) {
      if (pointMarker.unselectedIconSvg) {
        iconGeneratorMetadata.deselectedIconSvg =
          feature.headerImage && pointMarker.unselectedIconSvgWithImage
            ? pointMarker.unselectedIconSvgWithImage
            : pointMarker.unselectedIconSvg;
      }
      if (pointMarker.selectedIconSvg) {
        iconGeneratorMetadata.selectedIconSvg =
          feature.headerImage && pointMarker.selectedIconSvgWithImage
            ? pointMarker.selectedIconSvgWithImage
            : pointMarker.selectedIconSvg;
      }
    } else {
      let svgIcon = this.getImageAssociationIconMap(pointMarker).get(
        feature.imageAssociationStatus,
      );
      if (svgIcon === undefined) {
        svgIcon = {
          selectedIcon: pointMarker.selectedIconSvgNoImage,
          unselectedIcon: pointMarker.unselectedIconSvgNoImage,
        };
      }
      if (pointMarker.unselectedIconSvg) {
        iconGeneratorMetadata.deselectedIconSvg = svgIcon.selectedIcon
          ? svgIcon.selectedIcon
          : pointMarker.unselectedIconSvg;
      }
      if (pointMarker.selectedIconSvg) {
        iconGeneratorMetadata.selectedIconSvg = svgIcon.unselectedIcon
          ? svgIcon.unselectedIcon
          : pointMarker.selectedIconSvg;
      }
    }
    return this.iconGenerator.getIcon(iconGeneratorMetadata);
  }

  /**
   * Populates the color-related fields in provided icon generator metadata.
   * Please note: this method overrides fields in provided metadata object.
   */
  private fillInIconColorInMetadata(
    featureProperties: Property[],
    pointMarker: PointMarker,
    iconGeneratorMetadata: IconGeneratorMetadata,
  ) {
    const colorSchema = pointMarker.colorSchema;
    if (colorSchema && colorSchema['size']) {
      const propertyValue = getPropertyValue(pointMarker.labelPropertyKey, featureProperties);
      iconGeneratorMetadata.colorHex = colorSchema[propertyValue] || UNASSIGNED_ICON_COLOR.hex;
    } else {
      const colorKey = getPropertyValue(pointMarker.colorPropertyKey, featureProperties);
      if (colorKey) {
        iconGeneratorMetadata.colorKey = colorKey;
      }
      if (pointMarker.colorPalette?.length) {
        iconGeneratorMetadata.colorPalette = pointMarker.colorPalette;
      }
    }
  }

  private createPointMarker(
    layerId: string,
    layerName: string,
    feature: Feature,
    iconSelectedFn: IconSelectedFn,
  ): Marker | null {
    if (
      !feature.geometry ||
      !feature.geometry.geometry.value ||
      feature.geometry.geometry.case !== 'point'
    ) {
      // TODO(reubenn): Add some type of debug-time logging.
      return null;
    }
    const point = feature.geometry.geometry.value;
    const latLng = new google.maps.LatLng(point.location!.latitude, point.location!.longitude);
    const icon = this.createFeatureIcon(feature, layerId);
    const featureMarker = new google.maps.Marker({
      icon: icon.deselected,
      position: latLng,
      visible: true,
      zIndex: this.mapPropertiesService.INACTIVE_MARKER_Z_INDEX,
    });
    this.setMarkerIconMetadata(featureMarker, icon.deselected, icon.selected);
    const marker: Marker = {
      marker: featureMarker,
      featureMetadata: {
        feature,
        layerId,
      },
    };
    featureMarker.addListener('click', () => {
      this.sendSelectedFeatureEvent(layerName);
      iconSelectedFn(marker, feature);
    });
    featureMarker.addListener('mouseover', ({latLng}: google.maps.MapMouseEvent) => {
      if (!latLng) {
        return;
      }

      const pointMarker = this.layersService.getLayerStyle(layerId)?.pointMarker;
      const hoverValue = getPropertyValue(pointMarker?.hoverPropertyKey || '', feature.properties);
      this.showTooltip(
        hoverValue || feature.name,
        latLng,
        DEFAULT_MARKER_TOOLTIP_VERTICAL_PIXEL_OFFSET,
        featureMarker.getMap() as google.maps.Map,
      );
    });
    featureMarker.addListener('mouseout', () => {
      this.hideTooltip();
    });
    return marker;
  }

  private createPolygonMarker(layerId: string, layerName: string, feature: Feature): Marker | null {
    if (
      !feature.geometry ||
      !feature.geometry.geometry.value ||
      feature.geometry.geometry.case !== 'polygon' ||
      (feature.geometry.geometry.value as Polygon).loops.length === 0
    ) {
      // TODO(reubenn): Add some type of debug-time logging.
      return null;
    }
    // Take the first loop in the polygon. At some point, we might have
    // polygons with multiple loops. Currently, the expectation is that the
    // polygon will only have a single loop.
    const points = (feature.geometry!.geometry!.value as Polygon).loops[0].points;
    const polygonMarker = new google.maps.Polygon({
      paths: this.getCoordinates(points),
      strokeWeight: POLYGON_STROKE_WEIGHT,
      zIndex: this.mapPropertiesService.INACTIVE_MARKER_Z_INDEX,
      visible: true,
    });
    const marker: Marker = {
      marker: polygonMarker,
      featureMetadata: {
        feature,
        layerId,
      },
    };
    polygonMarker.addListener('click', () => {
      this.sendSelectedFeatureEvent(layerName);
      this.sidepanelService.setSidepanelOpened(true);
      this.mapService.setShouldRepositionMap(false);
      this.router.navigate([ROUTE.MAP, layerId, feature.id]);
    });
    polygonMarker.addListener('mouseover', ({latLng}: google.maps.MapMouseEvent) => {
      const polygonMarkerMap = polygonMarker.getMap();
      if (!latLng || !polygonMarkerMap) {
        return;
      }

      polygonMarker.setOptions({strokeWeight: POLYGON_STROKE_WEIGHT_HOVER});
      this.showTooltip(
        feature.name,
        latLng,
        DEFAULT_MARKER_TOOLTIP_VERTICAL_PIXEL_OFFSET,
        polygonMarkerMap,
      );
    });
    polygonMarker.addListener('mouseout', () => {
      polygonMarker.setOptions({strokeWeight: POLYGON_STROKE_WEIGHT});
      this.hideTooltip();
    });

    return marker;
  }

  private createPolylineMarker(
    layerId: string,
    layerName: string,
    feature: Feature,
  ): Marker | null {
    if (
      !feature.geometry ||
      !feature.geometry.geometry.value ||
      feature.geometry.geometry.case !== 'lineString' ||
      (feature.geometry.geometry.value as Polyline).points.length === 0
    ) {
      // TODO(reubenn): Add some type of debug-time logging.
      return null;
    }
    const strokeColor =
      this.layersService.getLayerStyle(layerId)?.polyline?.strokeColor ||
      DEFAULT_POLYLINE_STROKE_COLOR;
    const points = (feature.geometry!.geometry!.value as Polyline).points;
    const polylineMarker = new google.maps.Polyline({
      path: this.getCoordinates(points),
      strokeWeight: POLYGON_STROKE_WEIGHT,
      strokeColor,
      zIndex: this.mapPropertiesService.INACTIVE_MARKER_Z_INDEX,
      visible: true,
    });
    const marker: Marker = {
      marker: polylineMarker,
      featureMetadata: {
        feature,
        layerId,
      },
    };
    polylineMarker.addListener('click', () => {
      this.sendSelectedFeatureEvent(layerName);
      this.sidepanelService.setSidepanelOpened(true);
      this.mapService.setShouldRepositionMap(false);
      this.router.navigate([ROUTE.MAP, layerId, feature.id]);
    });
    polylineMarker.addListener('mouseover', ({latLng}: google.maps.MapMouseEvent) => {
      const polylineMarkerMap = polylineMarker.getMap();
      if (!latLng || !polylineMarkerMap) {
        return;
      }
      polylineMarker.setOptions({strokeWeight: POLYGON_STROKE_WEIGHT_HOVER});
      this.showTooltip(
        feature.name,
        latLng,
        DEFAULT_MARKER_TOOLTIP_VERTICAL_PIXEL_OFFSET,
        polylineMarkerMap,
      );
    });
    polylineMarker.addListener('mouseout', () => {
      polylineMarker.setOptions({strokeWeight: POLYGON_STROKE_WEIGHT});
      this.hideTooltip();
    });

    return marker;
  }

  /**
   * Sets the selected and deselected icons as well as the selected state on
   * a marker.
   */
  private setMarkerIconMetadata(
    marker: google.maps.Marker,
    deselectedIcon: google.maps.Icon | string,
    selectedIcon: google.maps.Icon | string,
  ) {
    marker.set(MarkerProperty.DESELECTED_ICON, deselectedIcon);
    marker.set(MarkerProperty.SELECTED_ICON, selectedIcon);
  }

  private showTooltip(
    content: string | HTMLElement,
    position: google.maps.LatLng,
    verticalPixelOffset: number,
    map: google.maps.Map,
  ) {
    if (this.gmInfoWindow) {
      this.gmInfoWindow.setOptions({
        disableAutoPan: true,
        content,
        position,
        pixelOffset: new google.maps.Size(0, verticalPixelOffset),
      });
      this.gmInfoWindow.open(map);
    }
  }

  hideTooltip() {
    if (this.gmInfoWindow) {
      this.gmInfoWindow.close();
    }
  }

  private getCoordinates(points: Point[]): google.maps.LatLng[] {
    const coordinates = [];
    for (const point of points) {
      if (!point.location) {
        continue;
      }
      const latLng = new google.maps.LatLng(point.location!.latitude, point.location!.longitude);
      coordinates.push(latLng);
    }
    return coordinates;
  }

  private sendSelectedFeatureEvent(featureType: string): void {
    // The following line is to silence linter errors, it should be removed when the below is uncommented.
    featureType;
    this.analyticsService.sendEvent(EventActionType.FEATURE_SELECTED, {
      event_category: EventCategoryType.MAP,
      event_label: featureType,
    });
  }

  createCurrentLocationMarker(
    position: google.maps.LatLngLiteral,
    map: google.maps.Map,
  ): google.maps.Marker {
    // TODO(dpaukov): Update the marker design similarly to the Google Maps.
    return new google.maps.Marker({
      map,
      position,
      zIndex: this.mapPropertiesService.LOCATION_PIN_Z_INDEX,
      icon: {
        path: google.maps.SymbolPath.CIRCLE,
        scale: 10,
        fillColor: 'black',
        fillOpacity: 0.3,
        strokeWeight: 3,
        strokeOpacity: 0.4,
      },
    });
  }

  /**
   * Map ImageSourceStatus enum values to proto selected and unselected svg icon.
   */
  private getImageAssociationIconMap(pointMarker: PointMarker) {
    return new Map<ImageAssociationStatus, IconSvgForPointMaker>([
      [
        ImageAssociationStatus.NO_IMAGES,
        {
          selectedIcon: pointMarker.selectedIconSvgNoImage,
          unselectedIcon: pointMarker.unselectedIconSvgNoImage,
        },
      ],
      [
        ImageAssociationStatus.USER_IMAGES_ONLY,
        {
          selectedIcon: pointMarker.selectedIconSvgWithUserImage,
          unselectedIcon: pointMarker.unselectedIconSvgWithUserImage,
        },
      ],
      [
        ImageAssociationStatus.TAPESTRY_IMAGES_ONLY,
        {
          selectedIcon: pointMarker.selectedIconSvgWithTapestryImage,
          unselectedIcon: pointMarker.unselectedIconSvgWithTapestryImage,
        },
      ],
      [
        ImageAssociationStatus.USER_AND_TAPESTRY_IMAGES,
        {
          selectedIcon: pointMarker.selectedIconSvgWithUserTapestryImage,
          unselectedIcon: pointMarker.unselectedIconSvgWithUserTapestryImage,
        },
      ],
    ]);
  }

  // Returns true if the front feature of a multi-marker has an imageAssociationStatus,
  // which is used to update the icon's properties.
  private hasFrontIconImageAssociationStatus(firstTwo: FeatureMetadata[]): boolean {
    return this.imageSourceFilterEnabled && firstTwo[0].layerId === ASSETS_LAYER_ID;
  }

  // Returns true if (1) there is only one feature on the asset layer
  // or (2) the second feature is on the asset layer. When true, this indicates
  // the back feature of a multi-marker has an imageAssociationStatus, which
  // is used to update the icon's properties.
  private hasBackIconImageAssociationStatus(firstTwo: FeatureMetadata[]): boolean {
    return (
      this.imageSourceFilterEnabled &&
      ((!firstTwo[1] && firstTwo[0].layerId === ASSETS_LAYER_ID) ||
        (firstTwo[1] && firstTwo[1].layerId === ASSETS_LAYER_ID))
    );
  }

  private getMultiMarkerImageAssociationStatus(
    featuresMetadata: FeatureMetadata[],
  ): MultiMarkerIconProperties {
    let mixedImage = false;
    let withUserImage = false;
    let withTapestryImage = false;

    for (const featureMetadata of featuresMetadata) {
      if (featureMetadata.layerId === ASSETS_LAYER_ID) {
        switch (featureMetadata.feature.imageAssociationStatus) {
          case ImageAssociationStatus.USER_AND_TAPESTRY_IMAGES:
            mixedImage = true;
            break;
          case ImageAssociationStatus.TAPESTRY_IMAGES_ONLY:
            withTapestryImage = true;
            break;
          case ImageAssociationStatus.USER_IMAGES_ONLY:
            withUserImage = true;
            break;
          default:
            break;
        }
      }
    }
    // All no image assets.
    if (!mixedImage && !withUserImage && !withTapestryImage) {
      return {
        fillColor: '#FFFFFF',
        borderColor: DEFAULT_MULTI_MARKER_FRONT_ICON_COLOR,
        isMixedImage: false,
      };
      // User image assets.
    } else if (withUserImage && !withTapestryImage && !mixedImage) {
      return {
        fillColor: DEFAULT_MULTI_MARKER_FRONT_ICON_COLOR,
        borderColor: DEFAULT_BORDER_COLOR,
        isMixedImage: false,
      };
      // Tapestry image assets.
    } else if (withTapestryImage && !withUserImage && !mixedImage) {
      return {
        fillColor: '#FEC44F',
        borderColor: DEFAULT_BORDER_COLOR,
        isMixedImage: false,
      };
    } // Mixed images.
    return {
      fillColor: DEFAULT_MULTI_MARKER_FRONT_ICON_COLOR,
      borderColor: DEFAULT_BORDER_COLOR,
      isMixedImage: true,
    };
  }
}
