<div class="rounded-edges border-box">
  <img class="thumbnail top-rounded-edges" [src]="annotatedImageUrl || url" [alt]="name" />
  <button
    mat-icon-button
    class="remove-button"
    [attr.aria-label]="'Remove ' + name"
    matTooltip="Remove"
    (click)="deleteImage()"
    type="button">
    <mat-icon class="close">delete</mat-icon>
  </button>
  <div class="annotations-container">
    <span class="annotations-count">
      <ng-container [ngPlural]="annotationCount">
        <ng-template ngPluralCase="=1"> 1 annotation </ng-template>
        <ng-template ngPluralCase="other"> {{ annotationCount }} annotations </ng-template>
      </ng-container>
    </span>
    @if (!this.offline) {
      <button
        mat-stroked-button
        (click)="goToImageAnnotation()"
        class="annotate-button"
        matTooltip="Annotate photo"
        type="button"
        i18n-aria-label
        aria-label="Annotate photo">
        {{ annotationCount === 0 ? 'Add' : 'Edit' }}
      </button>
    }
  </div>
</div>
