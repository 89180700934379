import {LatLng} from '@tapestry-energy/npm-prod/google/type/latlng_pb';

import {Feature, Property} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/feature_pb';
import {Image} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/image_pb';
import {GridawareConfig_UploadFormType as UploadFormType} from '@tapestry-energy/npm-prod/tapestry/gridaware/config/v1/gridaware_config_pb';

import {
  FieldType,
  FormStep,
  GlobalFormPropertyName,
  PendingFile,
  UploadFormTemplate,
} from '../../typings/upload';

// Properties that are used repeatedly across a form.
const VTS_FORM = {
  conditionRating: ['C1', 'C2', 'C3', 'C4'],
  conditionRatingPlaceholder: 'Condition rating (if applicable)',
};

// Each form's name. The relevant name(s) will be displayed in a dropdown on
// the upload page.
enum FormName {
  IMAGE_UPLOAD = 'Image upload',
  VTS_ASSET_SITE_VISIT = 'Asset site visit',
  VTS_POLE_INSPECTION = 'Pole - QA inspections',
  CMS_POLE_INSPECTIONS = 'Pole inspections',
  CMS_SECURITY_ASSESSMENT = 'Security assessment',
}

/**
 * The fields with their respective values for pre filling the editing form.
 * TODO(reubenn): This interface is being used to set initial form data as well
 * and shouldn't be. Create a new interface of initial form data.
 */
export interface UploadFormResponses {
  pendingFiles: PendingFile[];
  tags: Set<string> | null;
  location: LatLng | null;
  asset: Feature | null;
  // Only provided if in offline mode or prepopulated.
  externalId: string;
  // Additional properties.
  extraProperties?: Property[];
  // Images that already exist as part of the defect. There will only be
  // existing images when in edit mode.
  existingImages?: Image[];
  // Existing images that need to be deleted.
  imagesToDelete?: Image[];
}

/**
 * Property names for image form.
 */
enum ImageFormPropertyName {
  DESCRIPTION = 'Description',
  PRIORITY = 'Priority',
}

/**
 * Default image upload form.
 */
export const IMAGE_UPLOAD_FORM: UploadFormTemplate = {
  name: FormName.IMAGE_UPLOAD,
  initialTags: [],
  sections: [
    {
      formStep: FormStep.INFORMATION,
      fields: [
        {
          propertyName: GlobalFormPropertyName.ANNOTATION_SUMMARY,
          placeholder: '',
          type: FieldType.ANNOTATION_SUMMARY,
        },
        {
          propertyName: GlobalFormPropertyName.MAP,
          placeholder: '',
          required: true,
          type: FieldType.MAP,
        },
      ],
    },
    {
      formStep: FormStep.ASSESSMENT,
      fields: [
        {
          propertyName: ImageFormPropertyName.PRIORITY,
          placeholder: 'Select a priority',
          type: FieldType.SELECTION,
          options: [
            'C0 Fix on site',
            'C1 Expected remedy within 7 days',
            'C2 Expected remedy within 6 months',
            'C3 Expected remedy within 2 years',
            'C4 Expected remedy within 5 years',
            'C5 - Other',
          ],
          required: true,
          default: 'C5 - Other',
        },
        {
          propertyName: ImageFormPropertyName.DESCRIPTION,
          placeholder: 'Add a description (optional)',
          type: FieldType.TEXT,
        },
        {
          propertyName: GlobalFormPropertyName.TAGS,
          placeholder: '',
          type: FieldType.TAGS,
        },
      ],
    },
  ],
};

/**
 * Property names for asset site visit form.
 */
enum VTSAssetSiteVisitFormPropertyName {
  ROLE_OF_REPORTER = 'Role of reporter',
  REASON_FOR_VISIT = 'Reason for visit',
  DESCRIPTION_OF_WORK = 'Description of work',
}

/**
 * Asset site visit form.
 */
export const VTS_ASSET_SITE_VISIT_FORM: UploadFormTemplate = {
  name: FormName.VTS_ASSET_SITE_VISIT,
  initialTags: ['SITE_VISIT'],
  sections: [
    {
      fields: [
        {
          header: 'Reporter Details',
          propertyName: VTSAssetSiteVisitFormPropertyName.ROLE_OF_REPORTER,
          placeholder: 'Role of reporter',
          type: FieldType.TEXT,
          required: true,
        },
        {
          header: 'Asset information',
          propertyName: GlobalFormPropertyName.MAP,
          placeholder: '',
          type: FieldType.MAP,
          required: true,
        },
        {
          propertyName: VTSAssetSiteVisitFormPropertyName.REASON_FOR_VISIT,
          placeholder: 'Reason for visit',
          type: FieldType.SELECTION,
          options: [
            'Asset investigation',
            'Customer request',
            'Work in progress - observation',
            'Other - describe',
          ],
        },
        {
          propertyName: VTSAssetSiteVisitFormPropertyName.DESCRIPTION_OF_WORK,
          placeholder: 'Description of work (if applicable)',
          type: FieldType.TEXTAREA,
        },
        {
          propertyName: GlobalFormPropertyName.TAGS,
          placeholder: '',
          type: FieldType.TAGS,
        },
      ],
    },
  ],
};

/**
 * Property names for VTS pole inspection form.
 */
enum VTSPoleInspectionFormPropertyName {
  ROLE_OF_REPORTER = 'Role of reporter',
  OBSERVATIONS = 'Observations',
  DESCRIPTION_OF_WORK = 'Description of work',
  OBSERVATIONS_CORRECT = 'Observations correct',
  OBSERVATIONS_DESCRIPTION = 'Observations description',
  CONDITION_OF_POLE = 'Condition of pole',
  CONDITION_OF_POLE_RATING = 'Condition of pole rating',
  HV_STRUCTURE_NETWORK_STORAGE = 'HV structure network storage',
  CONDITION_OF_HV_CROSSARMS = 'Condition of HV crossarms',
  CONDITION_OF_HV_CROSSARMS_RATING = 'Condition of HV crossarms rating',
  CONDITION_OF_HV_INSULATORS = 'Condition of HV insulators',
  CONDITION_OF_HV_INSULATORS_RATING = 'Condition of HV insulators rating',
  CONDITION_OF_HV_CONNECTORS = 'Condition of HV connectors',
  CONDITION_OF_HV_CONNECTORS_RATING = 'Condition of HV connectors rating',
  MV_STRUCTURE_NETWORK_STORAGE = 'MV structure network storage',
  CONDITION_OF_MV_CROSSARMS = 'Condition of MV crossarms',
  CONDITION_OF_MV_CROSSARMS_RATING = 'Condition of MV crossarms rating',
  CONDITION_OF_MV_INSULATORS = 'Condition of MV insulators',
  CONDITION_OF_MV_INSULATORS_RATING = 'Condition of MV insulators rating',
  CONDITION_OF_MV_CONNECTORS = 'Condition of MV connectors',
  CONDITION_OF_MV_CONNECTORS_RATING = 'Condition of MV connectors rating',
  CONDITION_OF_LV_CROSSARMS = 'Condition of LV crossarms',
  CONDITION_OF_LV_CROSSARMS_RATING = 'Condition of LV crossarms rating',
  CONDITION_OF_LV_INSULATORS = 'Condition of LV insulators',
  CONDITION_OF_LV_INSULATORS_RATING = 'Condition of LV insulators rating',
  CONDITION_OF_LV_CONNECTORS = 'Condition of LV connectors',
  CONDITION_OF_LV_CONNECTORS_RATING = 'Condition of LV connectors rating',
  CONDITION_OF_LV_FUSE_LINKS = 'Condition of LV fuse links',
  CONDITION_OF_LV_FUSE_LINKS_RATING = 'Condition of LV fuse links rating',
  CONDITION_OF_EARTHING_SYSTEM = 'Condition of earthing system',
  CONDITION_OF_EARTHING_SYSTEM_RATING = 'Condition of earthing system rating',
  CONDITION_OF_CABLE_RISER = 'Condition of cable riser',
  CONDITION_OF_CABLE_RISER_RATING = 'Condition of cable riser rating',
  CONDITION_OF_LIGHTNING_ARRESTOR = 'Condition of lightning arrestor',
  CONDITION_OF_LIGHTNING_ARRESTOR_RATING = 'Condition of lightning arrestor rating',
  CONDITION_OF_STAY = 'Condition of stay',
  CONDITION_OF_STAY_RATING = 'Condition of stay rating',
  DOES_THE_INSPECTION_OUTCOME_CORRELATE = 'Does the inspection outcome correlate',
}

/**
 * Pole inspection form.
 */
export const VTS_POLE_INSPECTIONS_FORM: UploadFormTemplate = {
  name: FormName.VTS_POLE_INSPECTION,
  initialTags: ['POLE_INSPECTION'],
  sections: [
    {
      fields: [
        {
          header: 'Reporter Details',
          propertyName: VTSPoleInspectionFormPropertyName.ROLE_OF_REPORTER,
          placeholder: 'Role of reporter',
          type: FieldType.TEXT,
          required: true,
        },
        {
          header: 'Asset information',
          propertyName: GlobalFormPropertyName.MAP,
          placeholder: '',
          type: FieldType.MAP,
          required: true,
        },
        {
          header: 'Existing conditions',
          propertyName: VTSPoleInspectionFormPropertyName.OBSERVATIONS,
          placeholder: '',
          propertiesToLookup: [
            new Property({
              key: 'Notification Type',
              propertyValue: {case: 'value', value: 'N1'},
            }),
            new Property({
              key: 'Notification Type',
              propertyValue: {case: 'value', value: 'N2'},
            }),
          ],
          type: FieldType.PROPERTY_LOOKUP,
        },
        {
          propertyName: VTSPoleInspectionFormPropertyName.OBSERVATIONS_CORRECT,
          placeholder: 'Were the existing open observations correct? (if applicable)',
          options: ['Yes', 'No'],
          type: FieldType.SELECTION,
        },
        {
          propertyName: VTSPoleInspectionFormPropertyName.OBSERVATIONS_DESCRIPTION,
          placeholder: 'Description (if applicable)',
          type: FieldType.TEXT,
        },
        {
          header: 'Asset assessment',
          subheader: 'Condition of pole',
          propertyName: VTSPoleInspectionFormPropertyName.CONDITION_OF_POLE,
          placeholder: '',
          type: FieldType.CHECKBOX,
          options: [
            'Corrosion',
            'Crack',
            'Damage',
            'Exposure steel',
            'Lean',
            'Rot',
            'Vege proximity',
          ],
        },
        {
          propertyName: VTSPoleInspectionFormPropertyName.CONDITION_OF_POLE_RATING,
          placeholder: VTS_FORM.conditionRatingPlaceholder,
          options: VTS_FORM.conditionRating,
          type: FieldType.SELECTION,
        },
      ],
    },
    {
      fields: [
        {
          header: 'HV structure',
          propertyName: VTSPoleInspectionFormPropertyName.HV_STRUCTURE_NETWORK_STORAGE,
          placeholder: 'Select network storage',
          options: ['110kV', '33kV'],
          type: FieldType.SELECTION,
        },
        {
          subheader: 'Condition of HV crossarms',
          propertyName: VTSPoleInspectionFormPropertyName.CONDITION_OF_HV_CROSSARMS,
          placeholder: '',
          type: FieldType.CHECKBOX,
          options: ['Corrosion', 'Sag balance', 'Sag height', 'Strand damage', 'Vege proximity'],
        },
        {
          propertyName: VTSPoleInspectionFormPropertyName.CONDITION_OF_HV_CROSSARMS_RATING,
          placeholder: VTS_FORM.conditionRatingPlaceholder,
          options: VTS_FORM.conditionRating,
          type: FieldType.SELECTION,
        },
        {
          subheader: 'Condition of HV insulators',
          propertyName: VTSPoleInspectionFormPropertyName.CONDITION_OF_HV_INSULATORS,
          placeholder: '',
          type: FieldType.CHECKBOX,
          options: ['BiMetal corrosion', 'LL clamp/P&G', 'Overheating'],
        },
        {
          propertyName: VTSPoleInspectionFormPropertyName.CONDITION_OF_HV_INSULATORS_RATING,
          placeholder: VTS_FORM.conditionRatingPlaceholder,
          options: VTS_FORM.conditionRating,
          type: FieldType.SELECTION,
        },
        {
          subheader: 'Condition of HV connectors',
          propertyName: VTSPoleInspectionFormPropertyName.CONDITION_OF_HV_CONNECTORS,
          placeholder: '',
          type: FieldType.CHECKBOX,
          options: ['BiMetal corrosion', 'LL clamp/P&G', 'Overheating'],
        },
        {
          propertyName: VTSPoleInspectionFormPropertyName.CONDITION_OF_HV_CONNECTORS_RATING,
          placeholder: VTS_FORM.conditionRatingPlaceholder,
          options: VTS_FORM.conditionRating,
          type: FieldType.SELECTION,
        },
      ],
    },
    {
      fields: [
        {
          header: 'MV structure',
          propertyName: VTSPoleInspectionFormPropertyName.MV_STRUCTURE_NETWORK_STORAGE,
          placeholder: 'Select network storage',
          options: ['22kV', '11kV'],
          type: FieldType.SELECTION,
        },
        {
          subheader: 'Condition of MV crossarms',
          propertyName: VTSPoleInspectionFormPropertyName.CONDITION_OF_MV_CROSSARMS,
          placeholder: '',
          type: FieldType.CHECKBOX,
          options: ['Corrosion', 'Sag balance', 'Sag height', 'Strand damage', 'Vege proximity'],
        },
        {
          propertyName: VTSPoleInspectionFormPropertyName.CONDITION_OF_MV_CROSSARMS_RATING,
          placeholder: VTS_FORM.conditionRatingPlaceholder,
          options: VTS_FORM.conditionRating,
          type: FieldType.SELECTION,
        },
        {
          subheader: 'Condition of MV insulators',
          propertyName: VTSPoleInspectionFormPropertyName.CONDITION_OF_MV_INSULATORS,
          placeholder: '',
          type: FieldType.CHECKBOX,
          options: ['BiMetal corrosion', 'LL clamp/P&G', 'Overheating'],
        },
        {
          propertyName: VTSPoleInspectionFormPropertyName.CONDITION_OF_MV_INSULATORS_RATING,
          placeholder: VTS_FORM.conditionRatingPlaceholder,
          options: VTS_FORM.conditionRating,
          type: FieldType.SELECTION,
        },
        {
          subheader: 'Condition of MV connectors',
          propertyName: VTSPoleInspectionFormPropertyName.CONDITION_OF_MV_CONNECTORS,
          placeholder: '',
          type: FieldType.CHECKBOX,
          options: ['BiMetal corrosion', 'LL clamp/P&G', 'Overheating'],
        },
        {
          propertyName: VTSPoleInspectionFormPropertyName.CONDITION_OF_MV_CONNECTORS_RATING,
          placeholder: VTS_FORM.conditionRatingPlaceholder,
          options: VTS_FORM.conditionRating,
          type: FieldType.SELECTION,
        },
      ],
    },
    {
      fields: [
        {
          header: 'LV structure',
          subheader: 'Condition of LV crossarms',
          propertyName: VTSPoleInspectionFormPropertyName.CONDITION_OF_LV_CROSSARMS,
          placeholder: '',
          type: FieldType.CHECKBOX,
          options: ['Corrosion', 'Sag balance', 'Sag height', 'Strand damage', 'Vege proximity'],
        },
        {
          propertyName: VTSPoleInspectionFormPropertyName.CONDITION_OF_LV_CROSSARMS_RATING,
          placeholder: VTS_FORM.conditionRatingPlaceholder,
          options: VTS_FORM.conditionRating,
          type: FieldType.SELECTION,
        },
        {
          subheader: 'Condition of LV insulators',
          propertyName: VTSPoleInspectionFormPropertyName.CONDITION_OF_LV_INSULATORS,
          placeholder: '',
          type: FieldType.CHECKBOX,
          options: ['BiMetal corrosion', 'LL clamp/P&G', 'Overheating'],
        },
        {
          propertyName: VTSPoleInspectionFormPropertyName.CONDITION_OF_LV_INSULATORS_RATING,
          placeholder: VTS_FORM.conditionRatingPlaceholder,
          options: VTS_FORM.conditionRating,
          type: FieldType.SELECTION,
        },
        {
          subheader: 'Condition of LV connectors',
          propertyName: VTSPoleInspectionFormPropertyName.CONDITION_OF_LV_CONNECTORS,
          placeholder: '',
          type: FieldType.CHECKBOX,
          options: ['BiMetal corrosion', 'LL clamp/P&G', 'Overheating'],
        },
        {
          propertyName: VTSPoleInspectionFormPropertyName.CONDITION_OF_LV_CONNECTORS_RATING,
          placeholder: VTS_FORM.conditionRatingPlaceholder,
          options: VTS_FORM.conditionRating,
          type: FieldType.SELECTION,
        },
        {
          subheader: 'Condition of LV/Fuse links',
          propertyName: VTSPoleInspectionFormPropertyName.CONDITION_OF_LV_FUSE_LINKS,
          placeholder: '',
          type: FieldType.CHECKBOX,
          options: ['Bolts corroded', 'Floating', 'Loose'],
        },
        {
          propertyName: VTSPoleInspectionFormPropertyName.CONDITION_OF_LV_FUSE_LINKS_RATING,
          placeholder: VTS_FORM.conditionRatingPlaceholder,
          options: VTS_FORM.conditionRating,
          type: FieldType.SELECTION,
        },
      ],
    },
    {
      fields: [
        {
          header: 'Stand-alone asset',
          subheader: 'Condition of earthing system',
          propertyName: VTSPoleInspectionFormPropertyName.CONDITION_OF_EARTHING_SYSTEM,
          placeholder: '',
          type: FieldType.CHECKBOX,
          options: ['Conduit loose', 'Damaged', 'Insecure', 'Missing'],
        },
        {
          propertyName: VTSPoleInspectionFormPropertyName.CONDITION_OF_EARTHING_SYSTEM_RATING,
          placeholder: VTS_FORM.conditionRatingPlaceholder,
          options: VTS_FORM.conditionRating,
          type: FieldType.SELECTION,
        },
        {
          subheader: 'Condition of cable riser',
          propertyName: VTSPoleInspectionFormPropertyName.CONDITION_OF_CABLE_RISER,
          placeholder: '',
          type: FieldType.CHECKBOX,
          options: ['Conduit loose/damaged', 'Loose'],
        },
        {
          propertyName: VTSPoleInspectionFormPropertyName.CONDITION_OF_CABLE_RISER_RATING,
          placeholder: VTS_FORM.conditionRatingPlaceholder,
          options: VTS_FORM.conditionRating,
          type: FieldType.SELECTION,
        },
        {
          subheader: 'Condition of lightning arrestor',
          propertyName: VTSPoleInspectionFormPropertyName.CONDITION_OF_LIGHTNING_ARRESTOR,
          placeholder: '',
          type: FieldType.CHECKBOX,
          options: ['Surface contamination', 'Tail floatin'],
        },
        {
          propertyName: VTSPoleInspectionFormPropertyName.CONDITION_OF_LIGHTNING_ARRESTOR_RATING,
          placeholder: VTS_FORM.conditionRatingPlaceholder,
          options: VTS_FORM.conditionRating,
          type: FieldType.SELECTION,
        },
        {
          subheader: 'Condition of stay',
          propertyName: VTSPoleInspectionFormPropertyName.CONDITION_OF_STAY,
          placeholder: '',
          type: FieldType.CHECKBOX,
          options: ['Conduit loose/damaged', 'Fence missing', 'Slack stay', 'Strand damaged'],
        },
        {
          propertyName: VTSPoleInspectionFormPropertyName.CONDITION_OF_STAY_RATING,
          placeholder: VTS_FORM.conditionRatingPlaceholder,
          options: VTS_FORM.conditionRating,
          type: FieldType.SELECTION,
        },
      ],
    },
    {
      fields: [
        {
          propertyName: VTSPoleInspectionFormPropertyName.DOES_THE_INSPECTION_OUTCOME_CORRELATE,
          placeholder: 'Does the inspection outcome correlate with FSP inspection?',
          options: ['Yes', 'No'],
          type: FieldType.SELECTION,
        },
        {
          propertyName: GlobalFormPropertyName.TAGS,
          placeholder: '',
          type: FieldType.TAGS,
        },
      ],
    },
  ],
};

/**
 * Property names for the CMS security assessment form.
 */
enum CMSSecurityAssessmentFormPropertyName {
  PRIORITY = 'Priority',
  DESCRIPTION = 'Description',
  WORK_REQUIRED = 'Work required',
  MATERIAL = 'Material',
}

/**
 * Security assessment form.
 */
export const CMS_SECURITY_ASSESSMENT_FORM: UploadFormTemplate = {
  name: FormName.CMS_SECURITY_ASSESSMENT,
  initialTags: ['INSP23'],
  includeLocationDescription: true,
  sections: [
    {
      fields: [
        {
          propertyName: GlobalFormPropertyName.ANNOTATION_SUMMARY,
          placeholder: '',
          type: FieldType.ANNOTATION_SUMMARY,
        },
        {
          propertyName: GlobalFormPropertyName.MAP,
          placeholder: '',
          type: FieldType.MAP,
          required: true,
        },
        {
          propertyName: CMSSecurityAssessmentFormPropertyName.MATERIAL,
          placeholder: 'Select a material',
          type: FieldType.MATERIAL_SELECTION,
        },
        {
          propertyName: CMSSecurityAssessmentFormPropertyName.PRIORITY,
          placeholder: 'Select a priority',
          type: FieldType.SELECTION,
          options: [
            'For record',
            'RID',
            'P1A - Safety Code Violation',
            'P1B - Unusual Public Hazard',
            'P1 - Other',
            'P2A - Floating Phase/Neutral',
            'P2B - Broken/Cracked Crossarm',
            'P2C - Damaged/Cracked Cutout',
            'P2D - Damaged/Cracked Insulators',
            'P2E - Pole: Needing Immediate Replacement',
            'P2F - Pin Pulling From or Through Crossarm/Pole',
            'P2G - Other Failed Structure or Equipment',
            'P2 - Other',
            'P3A - Broken Guy - Leaning Pole',
            'P3B - Pole: Damaged',
            'P3 - Other',
            'P4A - Broken/Missing Crossarm Braces',
            'P4B - Broken Guy: Non-Leaning Pole',
            'P4C - Damaged Equipment',
            'P4D - Lightning/Flashover Burn Marks',
            'P4E - Poorly Sagged Line',
            'P4 - Other',
            'P5A - Short Crossarm',
            'P5B - Brown Arrestors',
            'P5C - Non-Standard Insulators (14.4 kV Only)',
            'P5D - Missing Guy Guards',
            'P5 - Other',
            'P5 - Forestry',
          ],
          required: true,
          default: 'P5 - Other',
        },
        {
          propertyName: CMSSecurityAssessmentFormPropertyName.DESCRIPTION,
          placeholder: 'Add a description (optional)',
          type: FieldType.TEXTAREA,
        },
        {
          propertyName: CMSSecurityAssessmentFormPropertyName.WORK_REQUIRED,
          placeholder: 'Work required',
          type: FieldType.TEXTAREA,
        },
        {
          propertyName: GlobalFormPropertyName.TAGS,
          placeholder: '',
          type: FieldType.TAGS,
        },
      ],
    },
  ],
};

/**
 * Property names for the CMS pole inspection form.
 */
enum CMSPoleInspectionPropertyName {
  PRIORITY = 'Priority',
  DESCRIPTION = 'Description',
  WORK_REQUIRED = 'Work required',
  GIS_CHANGES_NEEDED = 'GIS Changes Needed',
  GIS_CHANGES_DESCRIPTION = 'GIS Changes Needed Description',
  ACCESSIBLE = 'Accessible',
  ACCESSIBLE_DESCRIPTION = 'Accessible Description',
  MINIMUM_THICKNESS = 'Minimum Thickness',
  MINIMUM_THICKNESS_HEIGHT = 'Height',
  CIRCUMFERENCE_REDUCTION = 'Reduction',
  TREATMENT_TYPE = 'Treatment type',
  TRUSS_QTY = 'Truss Qty',
  TRUSS_TYPE = 'Truss Type',
  REPLACEMENT_PRIORITY = 'Replacement Priority',
  REHABILITATION_NEEDED = 'Rehabilitation Needed',
  REHABILITATION_DESCRIPTION = 'Rehabilitation Needed Description',
  COMMENTS = 'Comments',
}

/**
 * Pole inspection form.
 */
export const CMS_POLE_INSPECTIONS_FORM: UploadFormTemplate = {
  name: 'Pole inspections',
  initialTags: ['POLE21'],
  sections: [
    {
      fields: [
        {
          propertyName: GlobalFormPropertyName.ANNOTATION_SUMMARY,
          placeholder: '',
          type: FieldType.ANNOTATION_SUMMARY,
        },
        {
          propertyName: CMSPoleInspectionPropertyName.GIS_CHANGES_NEEDED,
          placeholder:
            'Are GIS Changes Needed for Pole Location, Pole Number, Pole Year, Class, Height or Wood Type?',
          type: FieldType.BINARY,
          required: true,
        },
        {
          propertyName: CMSPoleInspectionPropertyName.GIS_CHANGES_DESCRIPTION,
          placeholder: 'If yes, please describe.',
          type: FieldType.TEXTAREA,
        },
        {
          propertyName: CMSPoleInspectionPropertyName.ACCESSIBLE,
          placeholder: 'Accessible for inspection?',
          type: FieldType.BINARY,
          required: true,
        },
        {
          propertyName: CMSPoleInspectionPropertyName.ACCESSIBLE_DESCRIPTION,
          placeholder: 'If no, please describe.',
          type: FieldType.TEXTAREA,
        },
        {
          propertyName: CMSPoleInspectionPropertyName.MINIMUM_THICKNESS,
          placeholder: 'Minimum thickness of shell (inches)',
          type: FieldType.NUMBER,
          required: true,
        },
        {
          propertyName: CMSPoleInspectionPropertyName.MINIMUM_THICKNESS_HEIGHT,
          placeholder: 'Height of Minimum Thickness (inches from Groundline)',
          type: FieldType.NUMBER,
          required: true,
        },
        {
          propertyName: CMSPoleInspectionPropertyName.CIRCUMFERENCE_REDUCTION,
          placeholder: 'Reduction in Circumference (Inches - Original minus Current Circumference)',
          type: FieldType.NUMBER,
          required: true,
        },
        {
          propertyName: CMSPoleInspectionPropertyName.TREATMENT_TYPE,
          placeholder: 'Treatment type',
          type: FieldType.SELECTION,
          options: [
            'External Only',
            'Internal and External',
            'Replace - No Treatment',
            'Treated and Reinforce',
            'Reinforce - Not Treated',
            'No Work Required',
          ],
          required: true,
          default: 'No Work Required',
        },
        {
          propertyName: CMSPoleInspectionPropertyName.TRUSS_QTY,
          placeholder: 'Number of trusses (0-2)',
          type: FieldType.NUMBER,
          required: true,
        },
        {
          propertyName: CMSPoleInspectionPropertyName.TRUSS_TYPE,
          placeholder: 'Type of truss',
          type: FieldType.SELECTION,
          options: [
            'Standard - 5100x10',
            'Standard - C2-3610',
            'Standard - C2-4910',
            'Standard - C2-5610',
            'Standard - C2-7110',
            'Standard - 980x10',
            'Standard - 1080x11',
            'Standard - 1180x11',
            'Standard - 1280x11',
            'Standard - 1380x11',
            'Standard - 1480x13',
            'Standard - 1580x13',
            'Standard - 1680x13',
            'Tall - 5100x13',
            'Tall - C2-3613',
            'Tall - C2-4913',
            'Tall - C2-5613',
            'Tall - C2-7113',
            'Tall - 980x13',
            'Tall - 1080x13',
            'Tall - 1180x13',
            'Tall - 1280x13',
            'Tall - 1380x13',
          ],
        },
        {
          propertyName: CMSPoleInspectionPropertyName.REPLACEMENT_PRIORITY,
          placeholder: 'Priority of replacement',
          type: FieldType.SELECTION,
          options: ['Immediate - Called In', 'Planned - Rush', 'Planned - Design', 'None'],
          required: true,
          default: 'None',
        },
        {
          propertyName: CMSPoleInspectionPropertyName.REHABILITATION_NEEDED,
          placeholder: 'Emergent Pole Top Structure Rehabilitation Needed?',
          type: FieldType.BINARY,
          required: true,
        },
        {
          propertyName: CMSPoleInspectionPropertyName.REHABILITATION_DESCRIPTION,
          placeholder: 'If yes, please describe.',
          type: FieldType.TEXTAREA,
        },
        {
          propertyName: CMSPoleInspectionPropertyName.COMMENTS,
          placeholder: 'Additional comments',
          type: FieldType.TEXTAREA,
        },
        {
          propertyName: GlobalFormPropertyName.TAGS,
          placeholder: '',
          type: FieldType.TAGS,
        },
        {
          propertyName: GlobalFormPropertyName.MAP,
          placeholder: '',
          type: FieldType.MAP,
        },
      ],
    },
  ],
};

/**
 * Associations between inspections form types and templates.
 */
// LINT.IfChange
export const UPLOAD_FORMS_BY_TYPE = new Map<UploadFormType, UploadFormTemplate>([
  [UploadFormType.DEFAULT_IMAGE_UPLOAD, IMAGE_UPLOAD_FORM],
  [UploadFormType.VTS_ASSET_SITE_VISIT, VTS_ASSET_SITE_VISIT_FORM],
  [UploadFormType.VTS_POLE_INSPECTIONS, VTS_POLE_INSPECTIONS_FORM],
  [UploadFormType.CMS_SECURITY_ASSESSMENT, CMS_SECURITY_ASSESSMENT_FORM],
  [UploadFormType.CMS_POLE_INSPECTIONS, CMS_POLE_INSPECTIONS_FORM],
  // STANDARD, SHORT, and POLE deprecated. Slated for removal.
  [UploadFormType.STANDARD, CMS_SECURITY_ASSESSMENT_FORM],
  [UploadFormType.SHORT, IMAGE_UPLOAD_FORM],
  [UploadFormType.POLE, CMS_POLE_INSPECTIONS_FORM],
]);
// LINT.ThenChange(//depot/google3/googlex/refinery/viaduct/proto/gridaware/gridaware_config.proto)

// TODO(b/215208465): Remove after feature is well-tested.
/**
 * New forms that should be visible based on the feature flag NEW_FORMS_ENABLED.
 */
export const NEW_FORMS = new Set([
  UploadFormType.VTS_ASSET_SITE_VISIT,
  UploadFormType.VTS_POLE_INSPECTIONS,
]);
