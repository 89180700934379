class Input {
  constructor(element, callback, options) {
    this.element = element;
    this.callback = callback;
    this.options = {
      enable: true,
      ...options
    };
  }
}

export { Input as default };