import {BehaviorSubject, Observable} from 'rxjs';

import {Injectable} from '@angular/core';
import {ParamMap, Router} from '@angular/router';

import {Image} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/image_pb';

import {ASSETS_LAYER_ID, AUTOTOP_LAYER_ID} from '../constants/layer';
import {QUERY_PARAMS, ROUTE} from '../constants/paths';
import {AnnotationEditorMode} from '../typings/annotations';
import {ColorScheme} from '../typings/common';
import {prefetchLidarImage} from '../utils/image';
import {UserPreferencesService} from './user_preferences_service';

/**
 * Handles getting, posting and sorting asset photos.
 */
@Injectable()
export class GalleryService {
  featureId = '';
  layerId = '';
  imageId = '';

  // Whether the metadata can be edited. If true, an icon will be visible that
  // routes to the upload page.
  canEditMetadata = new BehaviorSubject<boolean>(false);
  // The route for editing metadata.
  editMetadataRoute = new BehaviorSubject<string>('');
  // Updates on editor mode.
  editorMode = new BehaviorSubject<AnnotationEditorMode>(AnnotationEditorMode.OFF);

  images = new BehaviorSubject<Image[]>([]);
  selectedImage = new BehaviorSubject<Image | null>(null);

  newImageSelected = new BehaviorSubject<string>('');

  // A map of images by their respective IDs. When an image ID is read through
  // the URL, this cache will be checked first before fetching a new image.
  imageById = new Map<string, Image>();

  // A map of indexes by image IDs. The index represents the position in an
  // array of images. This is used for getting the next or previous image when
  // requested via the lightbox and replacing an image when it is modified.
  indexById = new Map<string, number>();

  // A map of image group label by group IDs. Labels are derived from image
  // group's upload time and can be in the format "MMM yyyy" or "MMM dd, yyyy".
  // Labels are displayed under grouped images in the asset timeline.
  imageGroupLabelById = new Map<string, string>();

  // A set of all image ids that have successfully prefetched a LiDAR image.
  lidarExistsById = new Set<string>();

  // If specified as 'true' via a URL query parameter, hide the carousel. Note
  // this differs from enabling the carousel entirely.
  hideCarousel: string | null = null;

  private layersWithAssetTimelineEnabled = [ASSETS_LAYER_ID, AUTOTOP_LAYER_ID];

  private previousEditorMode: AnnotationEditorMode = AnnotationEditorMode.OFF;

  // Emits when a LiDAR image is loaded.
  readonly lidarImageLoaded = new BehaviorSubject<string>('');

  // Updates on the indicator of the new upload. New upload in gallery means
  // that the target image has not yet been uploaded and instead comes from the
  // pending store.
  private readonly isNewUpload = new BehaviorSubject<boolean>(false);

  // Color scheme set by user.
  private previousColorScheme: ColorScheme | null = null;

  // Color scheme of the editor.
  private colorScheme!: ColorScheme;

  constructor(
    private readonly router: Router,
    private readonly userPreferencesService: UserPreferencesService,
  ) {
    this.userPreferencesService.getColorScheme().subscribe((colorScheme) => {
      this.colorScheme = colorScheme;
    });
  }

  setProperties(queryParamMap: ParamMap) {
    this.layerId = queryParamMap.get(QUERY_PARAMS.LAYER_ID)!;
    this.featureId = queryParamMap.get(QUERY_PARAMS.FEATURE_ID)!;
    this.hideCarousel = queryParamMap.get(QUERY_PARAMS.HIDE_CAROUSEL);
    this.imageId = queryParamMap.get(QUERY_PARAMS.IMAGE_ID)!;
  }

  updateImages(images: Image[]) {
    this.images.next(images);
    this.cacheImagesAndIndexes(images);
  }

  updateSelectedImage(image: Image | null) {
    this.selectedImage.next(image);
  }

  selectImage(image: Image, sourceUrl: string) {
    this.updateSelectedImage(image);
    this.newImageSelected.next(sourceUrl);
  }

  selectNextImage(sourceUrl: string) {
    if (!this.indexById.has(this.selectedImage.getValue()!.id)) {
      return;
    }
    const newIndex =
      (this.indexById.get(this.selectedImage.getValue()!.id)! + 1) % this.images.getValue().length;
    this.selectImage(this.images.getValue()[newIndex], sourceUrl);
  }

  selectPreviousImage(sourceUrl: string) {
    if (!this.indexById.has(this.selectedImage.getValue()!.id)) {
      return;
    }
    const currentIndex = this.indexById.get(this.selectedImage.getValue()!.id)!;
    const newIndex = currentIndex === 0 ? this.images.getValue().length - 1 : currentIndex - 1;
    this.selectImage(this.images.getValue()[newIndex], sourceUrl);
  }

  cacheImagesAndIndexes(images: Image[]) {
    this.imageById = new Map<string, Image>();
    this.indexById = new Map<string, number>();
    for (const [i, image] of images.entries()) {
      this.imageById.set(image.id, image);
      this.indexById.set(image.id, i);
    }
  }

  updateCanEditMetadata(canEditMetadata: boolean) {
    this.canEditMetadata.next(canEditMetadata);
  }

  /**
   * Builds the upload route for the defect. Updating the metadata from there
   * will update the metadata for all the images associated with said defect.
   */
  buildEditImageMetadataRoute() {
    const urlTree = this.router.createUrlTree([ROUTE.PHOTO_UPLOAD], {
      queryParams: {
        [QUERY_PARAMS.EDIT]: true,
        [QUERY_PARAMS.LAYER_ID]: this.layerId,
        [QUERY_PARAMS.FEATURE_ID]: this.featureId,
      },
    });
    this.editMetadataRoute.next(this.router.serializeUrl(urlTree));
  }

  prefetchLidarImages(images: Image[]) {
    const observables = images.map((image) => prefetchLidarImage(image));
    observables.forEach((obs) => {
      obs.subscribe({
        next: (id: string) => {
          this.lidarExistsById.add(id);
          this.lidarImageLoaded.next(id);
        },
        error: () => {
          console.error('failed to prefetch LiDAR imagery');
        },
      });
    });
  }

  setEditorMode(mode: AnnotationEditorMode) {
    if (this.previousEditorMode !== mode) {
      if (
        this.previousEditorMode === AnnotationEditorMode.OFF &&
        this.colorScheme === ColorScheme.LIGHT
      ) {
        // Only change theme to dark on entering edit mode.
        this.previousColorScheme = this.colorScheme;
        this.toggleTheme();
      } else if (
        mode === AnnotationEditorMode.OFF &&
        this.previousColorScheme &&
        this.colorScheme !== this.previousColorScheme
      ) {
        // Only change theme back to light on exiting the edit mode if it was configured initially.
        this.toggleTheme();
        this.previousColorScheme = null;
      }
    }
    this.previousEditorMode = mode;
    this.editorMode.next(mode);
  }

  getEditorMode(): Observable<AnnotationEditorMode> {
    return this.editorMode.asObservable();
  }

  setIsNewUpload(isNewUpload: boolean) {
    this.isNewUpload.next(isNewUpload);
  }

  getIsNewUpload(): Observable<boolean> {
    return this.isNewUpload.asObservable();
  }

  checkLayerSupportsAssetTimeline() {
    return this.layersWithAssetTimelineEnabled.includes(this.layerId);
  }

  private toggleTheme() {
    this.userPreferencesService.setColorScheme(
      this.colorScheme === ColorScheme.DARK ? ColorScheme.LIGHT : ColorScheme.DARK,
    );
  }
}
