@if (!feature) {
  <mat-progress-spinner mode="indeterminate" color="primary" diameter="48" class="spinner">
  </mat-progress-spinner>
} @else {
  <div class="container">
    @if (breadcrumbsEnabled) {
      <breadcrumb-navigation></breadcrumb-navigation>
    } @else {
      <button
        mat-icon-button
        class="close"
        [routerLink]="'../..'"
        aria-label="Back"
        i18n-aria-label
        matTooltip="Back">
        <mat-icon>arrow_back</mat-icon>
      </button>
    }
    @if (!thumbnailCarouselEnabled && image) {
      <div class="hero-photo">
        <image-thumbnail [image]="image" (click)="openLightbox()"> </image-thumbnail>
      </div>
    }
    @if (thumbnailCarouselEnabled && images.length) {
      <div class="hero-photo">
        <lightbox
          [image]="images[currentImageIndex]"
          [images]="images"
          [imageUrlOptions]="thumbnailUrlOptions"
          [isThumbnail]="true"
          [backgroundSize]="'contain'"
          [thumbnailMetadata]="thumbnailMetadata"
          (previousPressed)="selectImage('prev')"
          (nextPressed)="selectImage('next')"
          (imagePressed)="openLightbox()"
          (onEdit)="editImage()">
        </lightbox>
      </div>
    }
    @if (!thumbnailCarouselEnabled && !image && isOffline) {
      <div class="hero-photo offline-container">
        <mat-icon class="offline-icon">cloud_off</mat-icon>
      </div>
    }
    @if (thumbnailCarouselEnabled && !images.length && isOffline) {
      <div class="hero-photo offline-container">
        <mat-icon class="offline-icon">cloud_off</mat-icon>
      </div>
    }
    @if (!isOffline && !thumbnailCarouselEnabled && !showSolarInsights) {
      <div class="satellite-map" [hidden]="image" #heroSatellite></div>
    }
    @if (!isOffline && thumbnailCarouselEnabled && !showSolarInsights) {
      <div class="satellite-map" [hidden]="images.length" #heroSatellite></div>
    }
    @if (showSolarInsights) {
      <div class="panel">
        <div class="panel-header panel-header-border-bottom">Solar Analysis by Feeder</div>
        <solar-insights [feature]="feature" [featureProperties]="featureProperties">
        </solar-insights>
      </div>
    }
    @if (!showSolarInsights) {
      <div class="panel">
        @if (layerName) {
          <div class="panel-header first-header">{{ layerName }}</div>
        }
        <div class="feature-name">
          @if (feature.name) {
            <div class="title">
              <div class="title-name">{{ feature.name }}</div>
              <button
                mat-icon-button
                class="icon-button copy"
                color="primary"
                (click)="copyFeatureName()"
                aria-label="Copy ID"
                i18n-aria-label
                matTooltip="Copy ID">
                <mat-icon class="feature-details-icon">content_copy</mat-icon>
              </button>
            </div>
          }
          @if (editsEnabled) {
            <button
              mat-icon-button
              class="icon-button edit-defect"
              color="primary"
              (click)="editDefect()"
              aria-label="Edit"
              i18n-aria-label
              matTooltip="Edit">
              <mat-icon class="feature-details-icon">create_black</mat-icon>
            </button>
          }
        </div>
        @if (lastUpdatedAt) {
          <div class="last-updated" [ngClass]="{'feature-inactive': featureInactive}">
            {{ featureInactive ? 'Closed' : 'Last updated' }}
            {{ lastUpdatedAt | relativeDateTime }}
          </div>
        }
        @if (address$ | async; as address) {
          <div>
            <mat-icon color="primary">location_on</mat-icon>
            <a
              class="address"
              href="https://www.google.com/maps/search/?api=1&query={{ address }}"
              target="_blank">
              {{ address }}
            </a>
          </div>
        }
        <div class="street-view-photo-container">
          @if (!isOffline) {
            <div class="street-view" #streetViewContainer></div>
            @if (thumbnailCarouselEnabled && images.length) {
              <div class="thumbnail" (click)="openLightbox()" role="button">
                <div class="icon-container">
                  <mat-icon class="icon-input">collections</mat-icon>
                  <div class="icon-text">More photos</div>
                </div>
                <image-thumbnail [image]="images[0]" [imageUrlOptions]="thumbnailUrlOptions">
                </image-thumbnail>
              </div>
            }
            @if (!thumbnailCarouselEnabled && image) {
              <div class="thumbnail" (click)="openLightbox()" role="button">
                <div class="icon-container">
                  <mat-icon class="icon-input">collections</mat-icon>
                  <div class="icon-text">More photos</div>
                </div>
                <image-thumbnail [image]="image" [imageUrlOptions]="thumbnailUrlOptions">
                </image-thumbnail>
              </div>
            }
          }
          @if (isOffline) {
            <div class="street-view offline-container">
              <mat-icon class="offline-icon">cloud_off</mat-icon>
            </div>
          }
        </div>
        @if (uploadsEnabled) {
          <button
            class="feature-details-button upload"
            color="primary"
            (click)="goToImageUpload()"
            mat-stroked-button
            aria-label="Add photos"
            i18n-aria-label>
            <mat-icon class="icon">add_a_photo</mat-icon>
            Add photos
          </button>
        }
        <!--    <button *ngIf="!commentFormVisible"-->
        <!--            class="feature-details-button"-->
        <!--            color="primary"-->
        <!--            (click)="commentFormVisible = true"-->
        <!--            [disabled]="isOffline"-->
        <!--            mat-stroked-button-->
        <!--            aria-label="Add comment"-->
        <!--            i18n-aria-label>-->
        <!--      <mat-icon class="icon">comment</mat-icon>-->
        <!--      Add comment-->
        <!--    </button>-->
        <!--    <div *ngIf="commentFormVisible" class="comment-form">-->
        <!--      <comment-form [featureId]="feature.id"-->
        <!--                    (commentComplete)="refreshOnNewComment()">-->
        <!--      </comment-form>-->
        <!--    </div>-->
      </div>
    }
    @if (shouldEnableInspectionStatus()) {
      <div class="panel">
        <div class="panel-header">Inspection status</div>
        <inspection-status [featureId]="feature.id" [properties]="featureProperties">
        </inspection-status>
      </div>
    }
    @if (!isOffline && !showSolarInsights) {
      <div class="panel">
        <div class="panel-header">Activity timeline</div>
        <timeline [feature]="features"></timeline>
      </div>
      @for (relatedFeatureDisplay of relatedFeatureDisplays; track relatedFeatureDisplay) {
        <div class="panel">
          <div class="panel-header">{{ relatedFeatureDisplay.layerName }}</div>
          @for (related of relatedFeatureDisplay.relatedFeatures; track related) {
            <div class="related-features">
              <span class="related-feature-title">{{ related.displayName }}</span>
              <button
                mat-icon-button
                class="icon-button related-nav-button"
                color="primary"
                (click)="goToRelatedFeature(related)"
                aria-label="Open"
                i18n-aria-label="Open"
                [matTooltip]="'Open'"
                [matTooltipPosition]="'above'">
                <mat-icon class="feature-details-icon">open_in_new</mat-icon>
              </button>
            </div>
          }
        </div>
      }
      @if (tagsEnabled && !showSolarInsights) {
        <div class="panel">
          <div class="panel-header">Tags</div>
          <tags [tags]="tags" [layerId]="layerId" (tagsUpdated)="doUpdateTags($event)"> </tags>
        </div>
      }
    }
    <div class="panel last-panel">
      <div class="panel-header">Details</div>
      <metadata
        [layerId]="layerId"
        [properties]="featureProperties"
        [selectedFeature]="feature.name">
      </metadata>
    </div>
  </div>
}
