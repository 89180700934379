// eslint-disable-next-line node/no-extraneous-import
import {HexagonLayer} from '@deck.gl/aggregation-layers';
// import {DataFilterExtension} from '@deck.gl/extensions';
// eslint-disable-next-line node/no-extraneous-import
import {CollisionFilterExtension} from '@deck.gl/extensions';
// eslint-disable-next-line node/no-extraneous-import
import {GoogleMapsOverlay} from '@deck.gl/google-maps';
// eslint-disable-next-line node/no-extraneous-import
import {IconLayer, ScatterplotLayer} from '@deck.gl/layers';
import {Layer, PickingInfo} from 'deck.gl';
import {BehaviorSubject} from 'rxjs';

import {Injectable} from '@angular/core';
import {Router} from '@angular/router';

import {Feature} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/feature_pb';
import {Point} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/geometry_pb';

import {ICON_COLORS, SHAPE} from '../constants/icon';
import {ASSETS_LAYER_ID} from '../constants/layer';
import {ROUTE} from '../constants/paths';
import {LayersService} from '../services/layers_service';
import {MapService} from '../services/map_service';
import {SidepanelService} from '../services/sidepanel_service';
import {DeckGLFeature} from '../typings/deck_gl';
import {Marker, MultiMarker, MultiMarkerOpenedWindowEvent} from '../typings/map';
import {ICON_MAPPING, getIconKey, getMultiMarkerKey} from '../utils/icon_mapping';

// Text to display if no content exists for a tooltip.
const TOOLTIP_PLACEHOLDER_TEXT = '';

// Offset for Google Maps tooltip.
const DEFAULT_MARKER_TOOLTIP_VERTICAL_PIXEL_OFFSET = -24;

// Layer ID for selection layer.
const SELECTION_LAYER_ID = 'selection-layer';

// Dark gray color.
const DEFAULT_COLOR_RGBA: [number, number, number] = [90, 90, 90];

// When no color exists, use this color.
const DEFAULT_FILL_COLOR: [number, number, number] = [0, 0, 0];

// Minimum size in px even when zoomed far out.
const SIZE_MIN_PIXELS = 16;

// Maximum size in px when zoomed all the way in.
const SIZE_MAX_PIXELS = 18;

// This color is used it no other color is specified.
const DEFAULT_ICON_COLOR = 'blue';

// Dark gray color with 50% transparency.
const DEFAULT_TRANSPARENT_COLOR_RGBA = [90, 90, 90, 128];

// Text used to identify if an SVG is image_untagged or image_tagged.
const UNIQUE_UNTAGGED_TEXT = 'pattern';

// Suffix for identifying multi-marker layers and features.
const MULTI_MARKER_LAYER_ID_SUFFIX = '-multi-marker';

// See icon_mapping.ts.
enum ICON {
  SQUARE = 'blue_square', // Default solid square.
  ASSET_TAGGED = 'image_tagged', // Solid circle.
  ASSET_UNTAGGED = 'image_untagged', // Circle with stripes.
}

/**
 * Service for rendering and managing DeckGL related assets.
 *
 * DeckGL (https://deck.gl/) is a GPU-powered, highly performant large-scale
 * data visualization layer. Currently it's a layer on top of GridAware's
 * Google Map but it should other base map providers.
 *
 * It's an essential part of serving more than 2000 features at 60FPS in the
 * map because otherwise Google Maps native APIs for markers starts to lag
 * at 14000 features.
 */
@Injectable()
export class DeckGLService {
  /**
   * Emits feature of multi marker when clicked.
   */
  multiMarkerClickedFeature = new BehaviorSubject<Feature | null>(null);

  /**
   * Emits with data to open a google.maps.InfoWindow.
   */
  multiMarkerOpenedWindow = new BehaviorSubject<MultiMarkerOpenedWindowEvent | null>(null);

  iconLayer!: IconLayer<DeckGLFeature>;
  scatterplotLayer!: ScatterplotLayer<DeckGLFeature>;
  hexagonLayer!: HexagonLayer<DeckGLFeature>;
  gmInfoWindow = new google.maps.InfoWindow();
  zoom: number | null = null;

  // If true then layers are combined into a single layer that
  // represents an aggregated view (e.g. a heatmap).
  // TODO(b/324435112): Improve aggregation UX.
  showAggregationLayer = false;

  // Reference to map component multiMarkerByFeatureId.
  multiMarkerByFeatureId = new Map<string, MultiMarker>();

  private readonly dataByLayerId = new Map<string, DeckGLFeature[]>();
  private readonly multiDataByLayerId = new Map<string, DeckGLFeature[]>();

  // All DeckGL single feature layers keyed by layer ID.
  private readonly layersByLayerId = new Map<string, Layer<DeckGLFeature>>();

  // All DeckGL multi marker layers keyed by layer ID.
  private readonly multiMarkerLayersByLayerId = new Map<string, Layer<DeckGLFeature>>();

  // All rendered features keyed by feature id.
  private readonly featureByFeatureId = new Map<string, Feature>();

  // Track all features by layer so they can be removed.
  private readonly featuresByLayerId = new Map<string, Feature[]>();

  // Track all multi markers by layer so they can be removed.
  private readonly multiMarkerByLayerId = new Map<string, MultiMarker[]>();

  // Track all icons by Layer ID for faster lookup.
  private readonly iconByLayerId = new Map<string, string>();

  // Track all colors by Layer ID for faster lookup.
  private readonly colorByLayerId = new Map<string, string>();

  // Scatter plot selection circle placed underneath other layers.
  // This is needed because the Google Maps Overlay cannot be used.
  private selectionLayer!: ScatterplotLayer<DeckGLFeature> | null;

  // Specifies default icon layer properties that can be overridden.
  private readonly defaultIconLayerProps = {
    position: [0, 0, 0] as [number, number, number],
    getPosition: (d: DeckGLFeature) => d.position || [0, 0, 0],
    onHover: this.onHover.bind(this),
    // getFilterValue: this.getFilterValue.bind(this),
    // filterRange: [0, 1],
    extensions: [
      // new DataFilterExtension({filterSize: 1}),
      new CollisionFilterExtension(),
    ],
    sizeMinPixels: SIZE_MIN_PIXELS,
    sizeMaxPixels: SIZE_MAX_PIXELS,
    iconAtlas: '/assets/images/icon-atlas.webp',
    iconMapping: ICON_MAPPING,
    pickable: true,
    visible: true,
  };

  // Initialized by MapComponent.
  markerByFeatureId = new Map<string, Marker>();
  deckOverlay = new GoogleMapsOverlay({
    layers: [],
  });

  constructor(
    private readonly mapService: MapService,
    private readonly router: Router,
    private readonly sidepanelService: SidepanelService,
    private readonly layersService: LayersService,
  ) {
    this.init();
  }

  init() {
    this.mapService.mapReadyUpdates.subscribe((map: google.maps.Map) => {
      if (map) {
        this.updateLayers();
        this.attachToGoogleMap();
      }
    });
  }

  // Clean up WebGL resources.
  destroy() {
    this.layersByLayerId.clear();
    this.multiMarkerLayersByLayerId.clear();
    this.deckOverlay.setMap(null);
  }

  // Render icons for features that appear at unique locations.
  renderSingleLocationFeatures(features: Feature[], layerId: string) {
    const iconLayerData = this.addSingleLocationFeatures(features, layerId);
    this.layersByLayerId.set(layerId, this.createIconLayer(iconLayerData, layerId));
    this.updateLayers();
  }

  // Render multiMarkers for features that appear at the same location.
  renderDuplicateLocationFeatures(markers: MultiMarker[], layerId: string) {
    const multiMarkerLayerId = `${layerId}${MULTI_MARKER_LAYER_ID_SUFFIX}`;
    const data = this.addDuplicateLocationFeatures(markers, multiMarkerLayerId);
    this.multiMarkerLayersByLayerId.set(
      multiMarkerLayerId,
      this.createMultiMarkerLayer(data, multiMarkerLayerId),
    );
    this.updateLayers();
  }

  // Core rendering logic.
  updateLayers() {
    if (this.zoom === null) {
      return;
    }
    const showAggregationLayer = this.showAggregationLayer && this.zoom < 12;

    // The first layer is displayed below subsequent layers.
    const layers = [
      this.selectionLayer,
      ...this.layersByLayerId.values(),
      ...this.multiMarkerLayersByLayerId.values(),
    ].filter((layer) => layer);

    if (showAggregationLayer) {
      this.deckOverlay.setProps({
        layers: [this.createHexagonLayer(true)],
      });
    } else {
      this.deckOverlay.setProps({
        layers,
      });
    }
  }

  // Remove layers either by hiding or completely deleting the layer.
  removeLayers(layerIds: string[], hide = false) {
    for (const layers of [this.layersByLayerId, this.multiMarkerLayersByLayerId]) {
      layers.forEach((layer, layerId) => {
        const idWithoutSuffix = this.withoutSuffix(layerId);
        const isMultiMarkerLayer = layerId.includes(MULTI_MARKER_LAYER_ID_SUFFIX);
        if (layerIds.includes(idWithoutSuffix)) {
          let layerData =
            this.dataByLayerId.get(layerId) || this.multiDataByLayerId.get(layerId) || [];
          layerData = layerData.map((feature: DeckGLFeature) => {
            return {
              ...feature,
              visible: false,
            };
          });

          if (hide && !isMultiMarkerLayer) {
            this.layersByLayerId.set(layerId, this.createIconLayer(layerData, layerId, false));
            this.dataByLayerId.set(layerId, layerData);
          } else if (hide && isMultiMarkerLayer) {
            this.multiMarkerLayersByLayerId.set(
              layerId,
              this.createMultiMarkerLayer(layerData, layerId, false),
            );
            this.multiDataByLayerId.set(layerId, layerData);
          } else {
            layers.delete(layerId);
            this.dataByLayerId.delete(layerId);
            this.multiDataByLayerId.delete(layerId);
          }

          // Remove saved features.
          const features = this.featuresByLayerId.get(layerId) || [];
          [...features].forEach((feature) => {
            this.featureByFeatureId.delete(feature.id);
          });
          this.featuresByLayerId.delete(layerId);
        }
      });
    }
    this.updateLayers();
  }

  updateVisibleLayers(zoom: number) {
    this.zoom = zoom;
    this.updateLayers();
  }

  // Add a selection for a marker or multi-marker.
  selectFeature(featureId: string) {
    const feature = this.featureByFeatureId.get(featureId);
    const multiMarker = this.multiMarkerByFeatureId.get(featureId);
    const point = feature?.geometry?.geometry?.value as Point;
    const location = point?.location || null;

    if (feature && location) {
      const position: [number, number, number] = [
        location?.longitude || 0,
        location?.latitude || 0,
        0,
      ];
      this.addSelectionLayer(feature, position);
      this.updateLayers();
    } else if (!feature && multiMarker) {
      // Use the multiMarker's first feature for positioning.
      this.addSelectionLayer(multiMarker.featuresMetadata[0].feature, [
        multiMarker?.marker?.getPosition()?.lng() || 0,
        multiMarker?.marker?.getPosition()?.lat() || 0,
        0,
      ]);
      this.updateLayers();
    }
  }

  deselectFeature() {
    this.selectionLayer = null;
    this.updateLayers();
  }

  // Adds a transparent circle around a position  to highlight a feature.
  addSelectionLayer(feature: Feature, position: [number, number, number]) {
    this.selectionLayer = this.createScatterplotLayer(
      [
        {
          feature,
          color: DEFAULT_COLOR_RGBA,
          position,
          visible: true,
        },
      ],
      {
        id: SELECTION_LAYER_ID,
        getFillColor: DEFAULT_TRANSPARENT_COLOR_RGBA,
        getRadius: 6,
        radiusUnits: 'pixels',
      },
    );
  }

  // Add features to icon layer if they don't already exist and have a location.
  private addSingleLocationFeatures(features: Feature[], layerId: string): DeckGLFeature[] {
    const newFeatures = features.filter(
      (feature) =>
        !this.featureByFeatureId.has(feature.id) &&
        (feature?.geometry?.geometry.value as Point)?.location,
    );

    const existingFeatures = this.featuresByLayerId.get(layerId) || [];
    this.featuresByLayerId.set(layerId, [...existingFeatures, ...newFeatures]);

    const newDeckFeatures = newFeatures.map((feature: Feature) => {
      this.featureByFeatureId.set(feature.id, feature);
      const location = (feature?.geometry?.geometry.value as Point)?.location;
      const position: [number, number, number] = [
        location?.longitude || 0,
        location?.latitude || 0,
        0,
      ];
      return {
        position,
        icon: this.getIcon(layerId, feature),
        tooltip: feature.name,
        feature,
        layerId,
        visible: true,
      };
    });

    const layerData = this.dataByLayerId.get(layerId) || [];
    this.dataByLayerId.set(layerId, [...layerData, ...newDeckFeatures]);
    let iconLayerData: DeckGLFeature[] = [];
    for (const data of this.dataByLayerId.values()) {
      iconLayerData = [...iconLayerData, ...data];
    }
    return iconLayerData;
  }

  // Add multi markers.
  private addDuplicateLocationFeatures(markers: MultiMarker[], layerId: string): DeckGLFeature[] {
    const existingMarkers = this.multiMarkerByLayerId.get(layerId) || [];
    this.multiMarkerByLayerId.set(layerId, [...existingMarkers, ...markers]);

    const newDeckFeatures = markers.map((multiMarker: MultiMarker) => {
      let length = multiMarker.featuresMetadata.length;
      // Clamp length to 10 because icon atlas only supports 10 numbers.
      // If showing more than 10 then the UI will show "10+".
      length = length > 10 ? 10 : length;
      return {
        position: [
          multiMarker.marker.getPosition()?.lng() || 0,
          multiMarker.marker.getPosition()?.lat() || 0,
          0,
        ] as [number, number, number],
        icon: this.getIcon(layerId),
        tooltip: `${layerId}: ${multiMarker.featuresMetadata.length} features.`,
        layerId,
        // Save the first feature so multi-marker can be found on click.
        feature: multiMarker.featuresMetadata[0].feature,
        text: String(length),
        visible: true,
      };
    });

    const layerData = this.multiDataByLayerId.get(layerId) || [];
    this.multiDataByLayerId.set(layerId, [...layerData, ...newDeckFeatures]);
    let iconLayerData: DeckGLFeature[] = [];
    for (const data of this.multiDataByLayerId.values()) {
      iconLayerData = [...iconLayerData, ...data];
    }
    return iconLayerData;
  }

  private createHexagonLayer(visible: boolean) {
    return new HexagonLayer({
      id: 'heatmap',
      elevationRange: [0, 3000],
      extruded: true,
      // getPosition: (d: DeckGLFeature) => {
      //   return d.position;
      // },
      pickable: true,
      radius: 1000,
      upperPercentile: 100,
      visible,
      material: {
        ambient: 0.64,
        diffuse: 0.6,
        shininess: 32,
        specularColor: [51, 51, 51],
      },
      transitions: {
        elevationScale: 3000,
      },
    });
  }

  private attachToGoogleMap() {
    if (this.mapService.map) {
      this.deckOverlay.setMap(this.mapService.map);
    }
  }

  private createIconLayer(
    data: DeckGLFeature[],
    layerId: string,
    visible = true,
  ): Layer<DeckGLFeature> {
    return new IconLayer({
      ...this.defaultIconLayerProps,
      onClick: this.onClick.bind(this),
      onHover: this.onHoverWithTooltip.bind(this),
      data,
      visible,
      id: layerId,
    });
  }

  private createMultiMarkerLayer(
    data: DeckGLFeature[],
    layerId: string,
    visible = true,
  ): Layer<DeckGLFeature> {
    return new IconLayer({
      ...this.defaultIconLayerProps,
      getIcon: this.getMultiMarkerIcon.bind(this),
      onClick: this.onMultiMarkerClick.bind(this),
      data,
      visible,
      id: layerId,
    });
  }

  private createScatterplotLayer(
    data: DeckGLFeature[],
    customProps: object,
  ): ScatterplotLayer<DeckGLFeature> {
    return new ScatterplotLayer<DeckGLFeature>({
      getPosition: (d: DeckGLFeature) => [d.position[0], d.position[1], 0],
      getFillColor: (d: DeckGLFeature) =>
        d.color ? [d.color[0], d.color[1], d.color[2]] : DEFAULT_FILL_COLOR,
      data,
      radiusScale: 6,
      ...customProps,
    });
  }

  private setMapCursor(cursor: string) {
    this.mapService?.map?.setOptions({draggableCursor: cursor});
  }

  private onHover(info: PickingInfo<DeckGLFeature>) {
    if (info.picked) {
      this.setMapCursor('pointer');
    } else {
      this.setMapCursor(''); // Default hand cursor.
    }
  }

  // Updates on hover.
  private onHoverWithTooltip(info: PickingInfo<DeckGLFeature>) {
    this.onHover(info);

    if (!this.mapService.map) {
      return;
    }

    if (!info?.object?.tooltip) {
      this.gmInfoWindow.close();
      return;
    }

    const coordinate =
      info?.coordinate?.length === 2 ? [info.coordinate[1], info.coordinate[0]] : [0, 0];

    this.gmInfoWindow.setOptions({
      disableAutoPan: false,
      content: info?.object?.tooltip || TOOLTIP_PLACEHOLDER_TEXT,
      position: new google.maps.LatLng(coordinate[0], coordinate[1]),
      pixelOffset: new google.maps.Size(0, DEFAULT_MARKER_TOOLTIP_VERTICAL_PIXEL_OFFSET),
    });
    this.gmInfoWindow.open(this.mapService.map);
  }

  // Select feature on click.
  private onClick(info: PickingInfo<DeckGLFeature>) {
    if (!this.mapService.map) {
      return;
    }
    const feature = info.object?.feature;
    const layerId = info.object?.layerId;

    this.sidepanelService.setSidepanelOpened(true);
    this.mapService.setShouldRepositionMap(false);
    this.router.navigate([ROUTE.MAP, layerId, feature?.id]);

    if (!feature?.id) {
      this.deselectFeature();
    } else {
      this.selectFeature(feature.id);
    }
  }

  private onMultiMarkerClick(info: PickingInfo<DeckGLFeature>) {
    if (!this.mapService.map) {
      return;
    }
    const feature = info.object?.feature;
    if (!feature?.id) {
      this.multiMarkerClickedFeature.next(null);
      return;
    } else {
      this.multiMarkerClickedFeature.next(feature);
    }
  }

  /**
   * Look up a layer's icon style (i.e. color and shape) and return
   * a string in the icon atlas that matches.
   */
  private getIcon(layerId: string, feature?: Feature): string {
    // Determine if the asset icon is tagged or untagged based on the saved SVG.
    // See google3/googlex/refinery/viaduct/gridaware/frontend/assets/images/image_untagged.svg
    // and google3/googlex/refinery/viaduct/gridaware/frontend/assets/images/image_tagged.svg
    if (layerId === ASSETS_LAYER_ID && feature) {
      const marker = this.markerByFeatureId.get(feature.id);
      if (marker?.marker instanceof google.maps.Marker) {
        const markerIcon = marker.marker.getIcon() as google.maps.Icon;
        if (!markerIcon?.url?.includes(UNIQUE_UNTAGGED_TEXT)) {
          return ICON.ASSET_TAGGED;
        }
      }

      return ICON.ASSET_UNTAGGED;
    }

    if (!this.iconByLayerId.has(layerId)) {
      const colorString = this.getStringColor(layerId);
      const layerStyle = this.layersService.getLayerStyle(layerId);
      const iconSvgString = layerStyle?.pointMarker?.unselectedIconSvg;

      // Default to icon square if nothing else matches.
      let shape = SHAPE.SQUARE;
      if (iconSvgString?.includes(SHAPE.CIRCLE)) {
        shape = SHAPE.CIRCLE;
      } else if (iconSvgString?.includes(SHAPE.SQUARE)) {
        shape = SHAPE.SQUARE;
      } else if (iconSvgString?.includes(SHAPE.DIAMOND)) {
        shape = SHAPE.DIAMOND;
      } else if (iconSvgString?.includes(SHAPE.TAG)) {
        shape = SHAPE.TAG;
      } else if (iconSvgString?.includes(SHAPE.TRIANGLE)) {
        shape = SHAPE.TRIANGLE;
      }
      const iconKey = getIconKey(colorString, shape);
      this.iconByLayerId.set(layerId, iconKey);
    } else {
      return this.iconByLayerId.get(layerId) || ICON.SQUARE;
    }
    return this.iconByLayerId.get(layerId) || ICON.SQUARE;
  }

  private getMultiMarkerIcon(d: DeckGLFeature): string {
    const layerId = this.withoutSuffix(d.layerId || '');
    const colorString = this.getStringColor(layerId);
    return getMultiMarkerKey(colorString, Number(d?.text || 2));
  }

  private getStringColor(layerId: string): string {
    if (this.colorByLayerId.get(layerId)) {
      return this.colorByLayerId.get(layerId) || DEFAULT_ICON_COLOR;
    }
    const layerStyle = this.layersService.getLayerStyle(layerId);
    const firstHexColor = layerStyle?.pointMarker?.colorPalette[0] || '';
    const iconColor = ICON_COLORS.find((color) => color.hex === firstHexColor);
    const iconStringColor =
      iconColor?.label.toLowerCase().replaceAll(' ', '_') || DEFAULT_ICON_COLOR;
    if (iconStringColor) {
      this.colorByLayerId.set(layerId, iconStringColor);
    }
    return iconStringColor;
  }

  // If a feature is visible return 1 to show it, otherwise 0 to hide it.
  private getFilterValue(d: DeckGLFeature): number {
    return d.visible ? 1 : 0;
  }

  private withoutSuffix(layerId: string): string {
    return layerId.replace(MULTI_MARKER_LAYER_ID_SUFFIX, '');
  }
}
