import {Injectable} from '@angular/core';

const IMAGE_BASE_PATH = 'assets/images/';

/**
 * Service for map properties
 */
@Injectable({providedIn: 'root'})
export class MapPropertiesService {
  INACTIVE_MARKER_Z_INDEX = 100;
  ACTIVE_MARKER_Z_INDEX = 101;
  LOCATION_PIN_Z_INDEX = 103;
  // The minimum zoom that the map can have.
  MIN_ZOOM_LEVEL = 8;
  // The zoom level to use if a saved zoom is not found.
  INITIAL_ZOOM_LEVEL = 8;
  // The zoom level to use when repositioning the map.
  MAP_REPOSITION_ZOOM_LEVEL = 19;
  // The zoom level to use when prompting user for area selection on the map.
  MAP_AREA_SELECTION_ZOOM = 13;
  // The minimum zoom that the map can have while in cache area selection mode.
  MIN_AREA_SELECTION_ZOOM_LEVEL = 10;

  ASSET_ICON = {
    taggedInactive: IMAGE_BASE_PATH + 'image_tagged.svg',
    taggedActive: IMAGE_BASE_PATH + 'image_tagged_active.svg',
    untaggedInactive: IMAGE_BASE_PATH + 'image_untagged.svg',
    untaggedActive: IMAGE_BASE_PATH + 'image_untagged_active.svg',
  };

  ICON_SELECTED_STATE = {
    [this.ASSET_ICON.taggedInactive]: {
      icon: this.ASSET_ICON.taggedActive,
      zIndex: this.ACTIVE_MARKER_Z_INDEX,
    },
    [this.ASSET_ICON.taggedActive]: {
      icon: this.ASSET_ICON.taggedInactive,
      zIndex: this.INACTIVE_MARKER_Z_INDEX,
    },
    [this.ASSET_ICON.untaggedInactive]: {
      icon: this.ASSET_ICON.untaggedActive,
      zIndex: this.ACTIVE_MARKER_Z_INDEX,
    },
    [this.ASSET_ICON.untaggedActive]: {
      icon: this.ASSET_ICON.untaggedInactive,
      zIndex: this.INACTIVE_MARKER_Z_INDEX,
    },
  };

  /**
   * Additional styles to apply to the map if obfuscation is needed.
   */
  OBFUSCATION_STYLES: google.maps.MapTypeStyle[] = [
    {
      elementType: 'labels',
      stylers: [{visibility: 'off'}],
    },
    {
      featureType: 'administrative.land_parcel',
      stylers: [{visibility: 'off'}],
    },
    {
      featureType: 'administrative.neighborhood',
      stylers: [{visibility: 'off'}],
    },
  ];
}
