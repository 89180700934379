import {ApolloModule} from 'apollo-angular';
import {NgxJsonViewerModule} from 'ngx-json-viewer';

import {OverlayModule} from '@angular/cdk/overlay';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {Injector, LOCALE_ID, NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {
  DateAdapter,
  MAT_DATE_LOCALE,
  MatNativeDateModule,
  NativeDateAdapter,
} from '@angular/material/core';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatDialogModule} from '@angular/material/dialog';
import {MatMenuModule} from '@angular/material/menu';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatSliderModule} from '@angular/material/slider';
import {MatTooltipModule} from '@angular/material/tooltip';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {Router} from '@angular/router';

import {AuthGuard} from '../../src/services/auth_guard';
import {AuthService} from '../../src/services/auth_service';
import {BreadcrumbNavigationModule} from '../breadcrumb_navigation/breadcrumb_navigation_module';
import {DateRangePickerModule} from '../date_range_picker/date_range_picker_module';
import {MultiMarkerDetailsModule} from '../factories/multi_marker_details/multi_marker_details_module';
import {FeatureDetailsModule} from '../feature_details/feature_details_module';
import {FeederDetailsModule} from '../feeder_details/feeder_details_module';
import {FileViewerModule} from '../file_viewer/file_viewer_module';
import {GalleryInfoModule} from '../gallery/gallery_info/gallery_info_module';
import {GalleryModule} from '../gallery/gallery_module';
import {LayerConfigContainerModule} from '../layer_config/layer_config_container_module';
import {LoginModule} from '../login/login_module';
import {MapModule} from '../map/map_module';
import {MapLayersModule} from '../map_layers/map_layers_module';
import {NavigationSnackBarModule} from '../navigation_snackbar/navigation_snackbar_module';
import {PageErrorsModule} from '../page_errors/page_errors_module';
import {PendingUploadModule} from '../pending_upload/pending_upload_module';
import {AnalyticsService} from '../services/analytics_service';
import {ApiService} from '../services/api_service';
import {AppService} from '../services/app_service';
import {CommentsService} from '../services/comments_service';
import {ConfigService} from '../services/config_service';
import {DeckGLService} from '../services/deckgl_service';
import {GoogleMapsService} from '../services/google_maps_service';
import {HttpErrorHandler} from '../services/http_error_handler_service';
import {LayersFilterService} from '../services/layers_filter_service';
import {LayersService} from '../services/layers_service';
import {LocalStorageService} from '../services/local_storage_service';
import {MapService} from '../services/map_service';
import {MessageService} from '../services/message_service';
import {SidepanelService} from '../services/sidepanel_service';
import {SolarInsightsService} from '../services/solar_insights_service';
import {StreetViewRecencyService} from '../services/streetview_recency_service';
import {TagsService} from '../services/tags_service';
import {SunroofModule} from '../sunroof_details/sunroof_details_module';
import {TableModule} from '../table/table_module';
import {TagsModule} from '../tags/tags_module';
import {UploadModule} from '../upload/upload_module';
import {UserTypesModule} from '../user_types/user_types_module';
import {IconGenerator} from '../utils/icon_util';
import {App} from './app.component';
import {AppRoutingModule} from './app_routing.module';

// Do not inject the DeckGLService if the deck global lib is not available.
function conditionalServiceFactory(injector: Injector) {
  const mapService = injector.get(MapService);
  const router = injector.get(Router);
  const sidePanelService = injector.get(SidepanelService);
  const layersService = injector.get(LayersService);
  const configService = injector.get(ConfigService);
  return configService.mapPaginationEnabled
    ? new DeckGLService(mapService, router, sidePanelService, layersService)
    : null;
}

@NgModule({
  declarations: [App],
  imports: [
    ApolloModule,
    AppRoutingModule,
    BreadcrumbNavigationModule,
    BrowserAnimationsModule,
    BrowserModule,
    CommonModule,
    DateRangePickerModule,
    FeatureDetailsModule,
    FeederDetailsModule,
    FileViewerModule,
    GalleryInfoModule,
    GalleryModule,
    HttpClientModule,
    LayerConfigContainerModule,
    LoginModule,
    MapLayersModule,
    MapModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    MatDialogModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatSliderModule,
    MatTooltipModule,
    MultiMarkerDetailsModule,
    NavigationSnackBarModule,
    NgxJsonViewerModule,
    OverlayModule,
    PageErrorsModule,
    PendingUploadModule,
    ReactiveFormsModule,
    ScrollingModule,
    SunroofModule,
    TableModule,
    TagsModule,
    UploadModule,
    UserTypesModule,
  ],
  providers: [
    {provide: MAT_DATE_LOCALE, useValue: LOCALE_ID},
    {
      provide: DateAdapter,
      useClass: NativeDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    AnalyticsService,
    ApiService,
    AppService,
    AuthGuard,
    AuthService,
    ConfigService,
    {
      provide: DeckGLService,
      useFactory: conditionalServiceFactory,
      deps: [Injector],
    },
    CommentsService,
    GoogleMapsService,
    HttpErrorHandler,
    IconGenerator,
    LayersFilterService,
    LayersService,
    LocalStorageService,
    MapService,
    MessageService,
    StreetViewRecencyService,
    SolarInsightsService,
    TagsService,
  ],
  bootstrap: [App],
})
export class AppModule {}
