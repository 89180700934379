<sidepanel-layout>
  <div header>
    <search [isGlobalSearch]="true" [layerIds]="visibleLayerIds()"></search>
    <!-- (featureSelected)="onFeatureSelected($event)"> -->
  </div>
  <div sidepanel>
    <router-outlet></router-outlet>
  </div>
  <div main class="map-container">
    <div class="map-table-toggle-container mat-elevation-z2">
      <map-table-toggle></map-table-toggle>
    </div>
    <div class="map">
      <div class="map" #map></div>
      <!-- <div #areaSelectionOverlay
      *ngIf="isAreaSelectionInProgress"
      class="map-overlay">
    </div> -->
    </div>
    <!--     <!~~#myLocationControl is selected with ViewChild and dynamically attached to the map~~>
  <div #myLocationControl
    class="location-control">
    <button mat-icon-button
      class="my-location-control"
      (click)="repositionMapToMyLocation()"
      aria-label="Jump to my location"
      i18n-aria-label
      matTooltip="Jump to my location">
      <mat-icon class="icon">my_location</mat-icon>
    </button>
  </div>
  <!~~#areaSelectionControl is selected with ViewChild and dynamically attached to the map~~>
  <div #areaSelectionControl
    *ngIf="isAreaSelectionInProgress"
    class="area-control">
    <div class="area-control-header">Set asset download area</div>
    <div class="area-control-info">{{selectionAreaControlMessage}}</div>
    <div class="area-control-buttons-container">
      <button mat-button
        color="primary"
        class="area-control-button"
        (click)="cancelAreaSelection()">
        Cancel
      </button>
      <button mat-flat-button
        color="primary"
        class="area-control-button"
        (click)="confirmAreaSelection()">
        Next
      </button>
    </div>
  </div>-->
    @if (fetchingCount > 0) {
      <mat-progress-spinner mode="indeterminate" color="primary" diameter="36" class="spinner">
      </mat-progress-spinner>
    }
  </div>
</sidepanel-layout>
<div><ng-template #infoWindow></ng-template></div>
