import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatTooltipModule} from '@angular/material/tooltip';
import {RouterModule} from '@angular/router';

import {HeaderModule} from '../header/header_module';
import {TagsModule} from '../tags/tags_module';
import {DialogsModule} from './dialogs/dialogs_module';
import {UploadProgressModule} from './progress/progress_module';
import {UploadPage} from './upload';
import {UploadFormModule} from './upload_form/upload_form_module';

@NgModule({
  declarations: [UploadPage],
  imports: [
    CommonModule,
    DialogsModule,
    FormsModule,
    HeaderModule,
    MatButtonModule,
    MatCardModule,
    MatIconModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSelectModule,
    MatSnackBarModule,
    MatTooltipModule,
    RouterModule,
    TagsModule,
    UploadFormModule,
    UploadProgressModule,
  ],
  exports: [UploadPage],
})
export class UploadModule {}
