import {
  BehaviorSubject,
  EMPTY,
  Observable,
  ReplaySubject,
  Subject,
  combineLatest,
  firstValueFrom,
  forkJoin,
  merge,
  mergeMap,
  of,
} from 'rxjs';
import {catchError, filter, map, switchMap, take, takeUntil, tap} from 'rxjs/operators';

import {DatePipe} from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatSort, Sort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {Router} from '@angular/router';

import {Feature} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/feature_pb';
import {Image} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/image_pb';
import {Tag} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/tag_pb';

import {ROUTE} from '../constants/paths';
import {AnalyticsService, EventActionType, EventCategoryType} from '../services/analytics_service';
import {CSVService} from '../services/csv_service';
import {FeaturesCountService} from '../services/features_count_service';
import {FeaturesPaginationService, Page} from '../services/features_pagination_service';
import {FeaturesService, QueryFeaturesCount} from '../services/features_service';
import {LayersFilterService} from '../services/layers_filter_service';
import {LayersService} from '../services/layers_service';
import {PaginationCachingService} from '../services/pagination_caching_service';
import {PhotosService} from '../services/photos_service';
import {SidepanelService} from '../services/sidepanel_service';
import {TableService} from '../services/table_service';
import {TagsDialogResponse} from '../tags/tags_dialog';
import {FilterMap, FilterUpdate, LayerFilters} from '../typings/filter';
import {PrintableMetadata} from '../typings/printing';
import {
  getAllRelatedFeatures,
  getChildImageCount,
  getPropertyValue,
  getRelatedFeaturesNames,
} from '../utils/feature';
import {ImageUrlOptions, THUMBNAIL_SIZE_PX} from '../utils/image';

interface FeatureResponse {
  features: Feature[];
  properties: string[];
}

/**
 * These columns correspond to fields on the Feature proto that should
 * be displayed as columns. These static columns are opposed to the dynamic
 * columns that are generated from the features properties.
 */
export enum StaticFeatureColumn {
  THUMBNAIL = 'Thumbnail',
  NAME = 'Name',
  IMAGE_COUNT = 'Images',
  LAST_UPDATED_AT = 'Date last updated',
  TAGS = 'Tags',
}

const SORTABLE_STATIC_FEATURE_COLUMNS = [
  StaticFeatureColumn.NAME,
  StaticFeatureColumn.IMAGE_COUNT,
  StaticFeatureColumn.LAST_UPDATED_AT,
  StaticFeatureColumn.TAGS,
];

/**
 * How long to display informational banners for.
 */
const TOAST_DURATION_MS = 3500;

/**
 * How long to display error banners for.
 */
const ERROR_TOAST_DURATION = 1000;

/**
 * The maximum number or results to query.
 */
const TABLE_QUERY_MAX_RESULTS = 20000;

/**
 * The suggested maximum number of results for efficient table performance.
 */
const TABLE_QUERY_MAX_SUGGESTED_RESULTS = 50000;

/**
 * The prefix to use for related feature layer name.
 */
const RELATED_FEATURE_LAYER_PREFIX = 'Related';

const SELECTED_COLUMNS_COUNT = 5;

const EDIT_TAGS_COLUMN_ID = 'EDIT_TAGS_COLUMN_ID';

const COLLATOR = new Intl.Collator('en-US', {numeric: true});

const MAX_MOBILE_WIDTH_PX = 640;

/**
 * Component for displaying rows of data.
 */
@Component({
  selector: 'data-table',
  templateUrl: 'data_table.ng.html',
  styleUrls: ['data_table.scss'],
  providers: [FeaturesPaginationService, DatePipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class DataTable implements OnInit, AfterViewInit, OnDestroy {
  // ID of the layer to display the data for.
  @Input({required: true}) layerId = '';

  // Updates on when the table completes rendering a set of data rows.
  @Output() readonly onContentChanged = new EventEmitter<void>();

  // Updates on when the requested rows exceed the recommended range.
  @Output() readonly maxEntriesReached = new EventEmitter();

  @ViewChild(MatPaginator, {static: false}) paginator!: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort!: MatSort;

  // Alias to the enum.
  readonly staticFeatureColumn = StaticFeatureColumn;

  tableData = new MatTableDataSource<Feature>([]);
  // ID of the selected feature if any.
  selectedFeatureId = '';
  allColumnNames: string[] = [];
  selectedColumnNames: string[] = [];
  // Options for the thumbnail image URL.
  thumbnailImageUrlOptions: ImageUrlOptions = {
    height: THUMBNAIL_SIZE_PX,
    width: THUMBNAIL_SIZE_PX,
  };

  layerName = '';
  // Needed in order to maintain a different order between the select-menu
  // dropdown and the table.
  selectedColumnNamesForDropdown: string[] = [];
  sortedColumnNamesForDropdown: string[] = [];
  imageByFeatureId = new Map<string, ReplaySubject<Image | null>>();
  relatedLayerIdByName = new Map<string, string>();
  editTagsColumnId = EDIT_TAGS_COLUMN_ID;
  featureToTagEditById = new Map<string, Feature>();
  editingTags = false;

  protected readonly itemsPerPageOptions = [10, 20, 50];
  page: Page = {
    pageIndex: 0,
    pageSize: this.itemsPerPageOptions[0],
  };
  private sortableColumnNames = new Set<string>();

  private readonly viewReady = new ReplaySubject<void>(1);
  private readonly destroyed = new Subject<void>();

  readonly featuresLoading = new BehaviorSubject<boolean>(false);
  readonly countLoading = new BehaviorSubject<boolean>(false);
  readonly featuresCount = new BehaviorSubject<number>(0);
  readonly exportLoading = new BehaviorSubject<boolean>(false);

  constructor(
    readonly datepipe: DatePipe,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly analyticsService: AnalyticsService,
    private readonly csvService: CSVService,
    private readonly featuresCountService: FeaturesCountService,
    private readonly layersService: LayersService,
    private readonly layersFilterService: LayersFilterService,
    private readonly featuresPaginationService: FeaturesPaginationService,
    private readonly featuresService: FeaturesService,
    private readonly paginationCachingService: PaginationCachingService,
    private readonly photosService: PhotosService,
    private readonly router: Router,
    private readonly tableService: TableService,
    private readonly sidepanelService: SidepanelService,
    private readonly snackBar: MatSnackBar,
  ) {}

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.resizeThumbnails();
  }

  ngOnInit() {
    this.layersService
      .onLayersReady()
      .pipe(takeUntil(this.destroyed))
      .subscribe(() => {
        this.layerName = this.layersService.getLayerName(this.layerId) || '';
      });
    this.paginationCachingService
      .getSavedPaginationForLayer(this.layerId)
      .pipe(takeUntil(this.destroyed))
      .subscribe((page: Page | null) => {
        this.page = {
          pageIndex: page?.pageIndex || 0,
          pageSize: page?.pageSize || this.itemsPerPageOptions[0],
          length: page?.length || 0,
        };
      });
    this.fetchAndRender();
    merge(this.layerFiltersUpdates(), this.photosService.onImageUpdated())
      .pipe(takeUntil(this.destroyed))
      .subscribe(() => {
        // Reset cache.
        this.paginationCachingService.setFeaturesForLayer(this.layerId, []);
        this.fetchAndRender();
      });
    this.featuresPaginationService
      .onDataFetchTriggered()
      .pipe(takeUntil(this.destroyed))
      .subscribe((featuresCount: number) => {
        if (featuresCount > TABLE_QUERY_MAX_SUGGESTED_RESULTS) {
          this.maxEntriesReached.emit();
        }
      });
    this.tableService
      .getSelectedFeature()
      .pipe(takeUntil(this.destroyed))
      .subscribe(({featureId, layerId}) => {
        this.selectedFeatureId = this.layerId === layerId ? featureId : '';
        this.changeDetectorRef.detectChanges();
      });
  }

  ngAfterViewInit() {
    this.viewReady.next();
    this.onPaginationChange();
  }

  ngOnDestroy() {
    this.destroyed.next();
    this.destroyed.complete();
  }

  fetchAndRender() {
    this.editingTags = false;
    this.getLayerFilters(this.layerId)
      .pipe(
        switchMap((filters: LayerFilters) => {
          return combineLatest([
            this.getFeaturesByPage(
              filters,
              // The flag indicates that data to be refreshed from the server
              // and the page to be reset to the start of the pagination
              true,
            ),
            this.getFeaturesCount(filters),
          ]);
        }),
        takeUntil(this.destroyed),
      )
      .subscribe();
  }

  getFeaturesByPage(filters: LayerFilters, isInitialLoad = false) {
    const {layerId, includeInactiveResults} = filters;
    const resetToFirstPage = isInitialLoad && !this.hasCachedFeatures();
    this.featuresLoading.next(true);

    const featuresRequest = resetToFirstPage
      ? this.featuresPaginationService.getFeaturesFirstPage({
          ...filters,
          pagination: {pageSize: this.page.pageSize, pageToken: ''},
        })
      : this.featuresPaginationService.getFeaturesByPage(this.page, filters);

    return forkJoin({
      features: featuresRequest,
      properties: this.layersService
        .getLayerPropertyKeys(layerId, false, includeInactiveResults)
        .pipe(take(1)),
    }).pipe(
      catchError((err) => {
        console.error(err);
        this.displayLayerFailedToLoad(this.layerName);
        return of({features: [], properties: []});
      }),
      tap(({features, properties}: FeatureResponse) => {
        this.featuresLoading.next(false);
        // TODO Decide how to set dynamic "Related layer" column with
        // pagination logic. Previously it is defined based on entire data
        // set.
        if (isInitialLoad) {
          const excludedColumns = this.buildExcludedFeatureColumns(features);
          this.relatedLayerIdByName = this.getRelatedLayerIdByName(features);
          this.setColumnNames(
            [...properties, ...this.relatedLayerIdByName.keys()],
            excludedColumns,
          );
          this.setSelectedColumnNames();
        }

        // TODO(b/270224485) Add an ability to return to the previously
        // selected feature. That was implemented before in b/227625729,
        // but should be reconsider because of implementation pagination
        // on the server side.
        this.tableData = new MatTableDataSource<Feature>(features.map((f: Feature) => sortTags(f)));
        this.page.pageIndex = resetToFirstPage ? 0 : this.page.pageIndex;
      }),
    );
  }

  getAllFeatures(): Observable<Feature[]> {
    const featuresCount = this.featuresCount.getValue();
    return this.getLayerFilters(this.layerId).pipe(
      switchMap((layerFilters: LayerFilters) => {
        return this.featuresPaginationService.getFeaturesByPage(
          {pageIndex: 0, pageSize: featuresCount},
          {...layerFilters, maxResults: featuresCount},
        );
      }),
    );
  }

  getLayerFilters(layerId: string): Observable<LayerFilters> {
    return combineLatest([
      this.layersFilterService.getFilterMap(layerId).pipe(take(1)),
      this.layersFilterService.includeInactive(layerId).pipe(take(1)),
    ]).pipe(
      map(([filters, includeInactiveResults]: [FilterMap, boolean]) => {
        return {layerId, filters, includeInactiveResults};
      }),
    );
  }

  getFeaturesCount(layerFilters: LayerFilters): Observable<QueryFeaturesCount> {
    const {layerId, filters, includeInactiveResults} = layerFilters;
    this.countLoading.next(true);
    return this.featuresCountService.fetchFeaturesCount(layerId, filters).pipe(
      tap((queryFeaturesCount: QueryFeaturesCount) => {
        const count = this.featuresCountService.getTotalCount(
          queryFeaturesCount,
          includeInactiveResults,
        );

        this.featuresCount.next(count);
        this.countLoading.next(false);
      }),
    );
  }

  /**
   * Returns the count to use in the paginator.
   *
   * -   If the count is not yet known, or if the count from the
   *     server is known to be unreliable (e.g. because we already
   *     received more than that many values), returns Infinity.
   * -   If we have received all results from the server, returns
   *     the number received.
   * -   Otherwise, returns the GetQueryFeaturesCount result from
   *     the server.
   */
  getCountForPaginator(): number {
    const nextPageToken = this.paginationCachingService.getNextPageTokenForLayer(this.layerId);
    const cachedFeatures = this.paginationCachingService.getFeaturesForLayer(this.layerId);
    if (nextPageToken === '') {
      // We have reached the end of the list. What if we haven't received the
      // first response? Seems like it'd be better to show "many" (or loading or
      // something?) in the UI instead of 0.
      return cachedFeatures.length;
    }
    // There are results left - if the count from the server is loading or
    // lower than the actual count return an arbitrarily large value,
    // otherwise return the count from the server.
    if (this.countLoading.getValue() || this.featuresCount.getValue() < cachedFeatures.length) {
      return Infinity;
    }
    return this.featuresCount.getValue();
  }

  onColumnMenuOpen() {
    this.analyticsService.sendEvent(EventActionType.TABLE_CUSTOMIZED, {
      event_category: EventCategoryType.TABLE,
      event_label: `${this.layerName} table`,
    });
  }

  onRowClick(feature: Feature) {
    this.sidepanelService.setSidepanelOpened(true);
    this.router.navigate([ROUTE.TABLE, this.layerId, feature.id]);
  }

  onSort(sort: Sort) {
    this.analyticsService.sendEvent(EventActionType.TABLE_SORTED, {
      event_category: EventCategoryType.TABLE,
      event_label: `Sort ${sort.active} column on ${this.layerName} table`,
    });
    this.featuresLoading.next(true);
    this.getAllFeatures()
      .pipe(
        take(1),
        tap(() => {
          this.featuresLoading.next(false);
          const features = this.sortFeatures(sort).slice(0, this.page.pageSize).map(sortTags);
          this.tableData = new MatTableDataSource<Feature>(features);
          this.paginator.pageIndex = 0;
        }),
      )
      .subscribe();
  }

  sortFeatures({active, direction}: Sort): Feature[] {
    const features = this.paginationCachingService.getFeaturesForLayer(this.layerId);
    if (!direction) {
      return features;
    }

    features.sort((featureA: Feature, featureB: Feature) => {
      const cellA = this.getCell(active, featureA);
      const cellB = this.getCell(active, featureB);
      if (!cellA && !cellB) {
        return 0;
      }
      if (!cellA || !cellB) {
        return !cellA ? 1 : -1;
      }
      const val = COLLATOR.compare(cellA, cellB);
      return direction === 'asc' ? val : val * -1;
    });

    return features;
  }

  isSortable(name: string): boolean {
    return this.sortableColumnNames.has(name);
  }

  getCell(columnName: string, feature: Feature): string {
    if (columnName === StaticFeatureColumn.NAME) {
      return feature.name || '';
    }
    if (columnName === StaticFeatureColumn.THUMBNAIL) {
      return '';
    }
    if (columnName === StaticFeatureColumn.LAST_UPDATED_AT) {
      const updatedAt = feature.updatedAt;
      if (!updatedAt) {
        return '';
      }
      return this.datepipe.transform(updatedAt.toDate(), 'yyyy/MM/dd') || '';
    }
    if (columnName === StaticFeatureColumn.IMAGE_COUNT) {
      const count = getChildImageCount(feature);
      if (!count) {
        return '';
      }
      return count > 1 ? `${count} images` : `${count} image`;
    }
    if (columnName === StaticFeatureColumn.TAGS) {
      return feature.tags.map((t) => t.name).join(', ');
    }
    if (this.relatedLayerIdByName.has(columnName)) {
      return getRelatedFeaturesNames(feature, this.relatedLayerIdByName.get(columnName)!);
    }

    return getPropertyValue(columnName, feature.properties);
  }

  onPaginationChange() {
    merge(this.paginator.page)
      .pipe(
        map((page: PageEvent) => {
          const {pageSize, length} = page;
          const isPageSizeChanged = this.page.pageSize !== pageSize;
          this.paginationCachingService.setPaginationForLayer(
            this.layerId,
            length,
            isPageSizeChanged ? 0 : page.pageIndex,
            pageSize,
          );
          return {...page, pageIndex: this.page.pageIndex};
        }),
        switchMap((): Observable<LayerFilters> => this.getLayerFilters(this.layerId)),
        switchMap((filters: LayerFilters) => this.getFeaturesByPage(filters)),
        takeUntil(this.destroyed),
      )
      .subscribe();
  }

  updateSelectedColumnNames(newColumns: string[]) {
    this.selectedColumnNames = this.sortSelectedColumnNamesForTable(newColumns);
    this.sortColumnNamesForDropdown();
    this.saveSettings();
    if (this.editingTags) {
      this.selectedColumnNames.push(this.editTagsColumnId);
    }
  }

  exportTableToCsv() {
    this.tableService
      .getPrintableMetadata(this.allColumnNames, this.selectedColumnNames, false, false)
      .pipe(
        take(1),
        switchMap((meta: PrintableMetadata | null) => {
          if (meta === null) {
            return EMPTY;
          }
          this.exportLoading.next(true);
          const featuresCount = this.featuresCount.getValue();
          if (featuresCount >= TABLE_QUERY_MAX_RESULTS) {
            this.displayFeatureExportCount(TABLE_QUERY_MAX_RESULTS, featuresCount);
          }

          return this.getAllFeatures().pipe(
            take(1),
            tap((features: Feature[]) => {
              this.exportLoading.next(false);

              const {columnNames} = meta;
              const rows = this.getRows(columnNames, features);
              const filename = this.csvService.makeFilename(this.layerName, new Date());

              this.csvService.getCSVFile(filename, columnNames, rows);
              this.sendPrintingTableAnalytics(EventActionType.TABLE_CSV_EXPORTED, columnNames);
            }),
          );
        }),
      )
      .subscribe();
  }

  sendPrintingTableAnalytics(actionType: EventActionType, columns: string[]) {
    this.analyticsService.sendEvent(actionType, {
      event_category: EventCategoryType.TABLE,
      event_label: `${this.layerName} table`,
    });

    this.sendExportOptionsEvent(columns);
  }

  getFirstImage(feature: Feature): Observable<Image | null> {
    if (this.imageByFeatureId.has(feature.id)) {
      return this.imageByFeatureId.get(feature.id)!.asObservable();
    }
    this.imageByFeatureId.set(feature.id, new ReplaySubject<Image | null>(1));
    this.featuresService
      .getFirstImageOfFeature(feature)
      .pipe(take(1), takeUntil(this.destroyed))
      .subscribe((image: Image | null) => {
        this.imageByFeatureId.get(feature.id)!.next(image);
      });
    return this.imageByFeatureId.get(feature.id)!.asObservable();
  }

  /**
   * Toggles bulk tag editing.
   */
  toggleEditTags(toggleOn: boolean) {
    if (toggleOn) {
      this.editingTags = true;
      this.selectedColumnNames = [...this.selectedColumnNames, this.editTagsColumnId];
      return;
    }
    this.featureToTagEditById.clear();
    this.editingTags = false;
    this.selectedColumnNames = this.selectedColumnNames.filter(
      (name: string) => name !== this.editTagsColumnId,
    );
  }

  /**
   * Add or remove a feature from bulk tag editing.
   */
  toggleFeatureFromTagEdit(shouldAdd: boolean, feature: Feature) {
    if (shouldAdd) {
      this.featureToTagEditById.set(feature.id, feature);
      return;
    }
    this.featureToTagEditById.delete(feature.id);
  }

  isSelectedForBulkEditTags(feature: Feature): boolean {
    return this.featureToTagEditById.has(feature.id);
  }

  /**
   * Returns the set of tags associated with a set of features.
   */
  private getTags(features: Feature[]): string[] {
    const tags = features.flatMap((feature: Feature) => feature.tags).map((tag: Tag) => tag.name);
    return [...new Set(tags)];
  }

  private updateFeatureTags(tags: Tag[]): Observable<void> {
    const updates = [];
    for (const featureId of this.featureToTagEditById.keys()) {
      updates.push(this.featuresService.updateTags(this.layerId, featureId, tags));
    }
    return combineLatest(updates).pipe(
      take(1),
      mergeMap(() => this.refreshFeatures()),
    );
  }

  private refreshFeatures(): Observable<void> {
    const updates = [];
    for (const featureId of this.featureToTagEditById.keys()) {
      updates.push(this.featuresService.getFeature(this.layerId, featureId, false));
    }
    return combineLatest(updates).pipe(
      take(1),
      map((features: (Feature | null)[]) => {
        this.paginationCachingService.updateFeaturesForLayer(
          this.layerId,
          features.filter((f) => f !== null) as Feature[],
        );
      }),
    );
  }

  async bulkEditTags() {
    // TODO(b/174520147): Get tags by ID as they won't exist on the Feature.
    const seededTags = this.getTags([...this.featureToTagEditById.values()]);
    const tagsDialogResponse: TagsDialogResponse | undefined = await firstValueFrom(
      this.tableService.renderTagsDialog(this.layerId, seededTags),
    );
    if (!tagsDialogResponse) {
      return;
    }
    this.featuresLoading.next(true);
    this.updateFeatureTags(tagsDialogResponse.tags)
      .pipe(
        take(1),
        catchError((error) => {
          this.featuresLoading.next(false);
          this.snackBar.open('Could not update tags.', '', {
            duration: ERROR_TOAST_DURATION,
          });
          console.error('Failed to update tags: ', error);
          return of(undefined);
        }),
      )
      .subscribe(() => {
        this.featuresLoading.next(false);
        this.fetchAndRender();
        this.toggleEditTags(false);
      });
  }

  private setColumnNames(propertyKeys: string[], excludedStaticColumns: Set<string>) {
    const staticColumns = Object.values(StaticFeatureColumn).filter(
      (column: string) => !excludedStaticColumns.has(column),
    );
    this.sortableColumnNames = new Set([...SORTABLE_STATIC_FEATURE_COLUMNS, ...propertyKeys]);
    this.allColumnNames = [...new Set([...staticColumns, ...propertyKeys])];
  }

  /**
   * Loads the selected columns from local storage and sets them. The selected
   * columns are the columns that will be rendered in the table.
   */
  private setSelectedColumnNames() {
    const preSelectedColumns = this.tableService.getSelectedColumnsForLayer(this.layerId);
    this.selectedColumnNames =
      preSelectedColumns.length > 0
        ? preSelectedColumns
        : this.allColumnNames.slice(0, SELECTED_COLUMNS_COUNT);
    this.selectedColumnNames = this.removeOutdatedColumnNames(
      this.selectedColumnNames,
      this.allColumnNames,
    );
    this.selectedColumnNames = this.sortSelectedColumnNamesForTable(this.selectedColumnNames);
    this.selectedColumnNamesForDropdown = [...this.selectedColumnNames];
    this.sortColumnNamesForDropdown();
  }

  /**
   * Columns can become outdated after they are selected and the component is
   * destroyed. When the component is destroyed, the column name is written to
   * local storage so that it can be automatically selected when the component
   * is reloaded. If the underlying data changes, and the selected column no
   * longer exists in the all column set, the selected column must be removed
   * or the table will throw an error.
   */
  private removeOutdatedColumnNames(
    selectedColumnNames: string[],
    allColumnNames: string[],
  ): string[] {
    const allColumnSet = new Set(allColumnNames);
    return selectedColumnNames.filter((selectedColumnName: string) =>
      allColumnSet.has(selectedColumnName),
    );
  }

  private buildExcludedFeatureColumns(features: Feature[]): Set<string> {
    const excludedFeatureColumns = new Set<string>([StaticFeatureColumn.IMAGE_COUNT]);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    for (const feature of features) {
      if (Number(getChildImageCount(feature)) > 0) {
        excludedFeatureColumns.delete(StaticFeatureColumn.IMAGE_COUNT);
        break;
      }
    }
    return excludedFeatureColumns;
  }

  /**
   * Sort the selected column names. This is the order that the columns will
   * appear in the table. This is different than the order (alphabetically) that
   * the column names appear in the select dropdown.
   */
  sortSelectedColumnNamesForTable(selectedColumnNames: string[]) {
    const selectedColumns = new Set(selectedColumnNames);
    return this.allColumnNames.filter((columnName) => selectedColumns.has(columnName));
  }

  sortColumnNamesForDropdown() {
    const selectedColumnNames = new Set(this.selectedColumnNames);
    const unselectedColumnNames = this.allColumnNames.filter(
      (columnName: string) => !selectedColumnNames.has(columnName),
    );
    this.sortedColumnNamesForDropdown = [
      ...[...this.selectedColumnNames].sort(caseInsensitiveAlphaSort),
      ...unselectedColumnNames.sort(caseInsensitiveAlphaSort),
    ];
  }

  private displayFeatureExportCount(showingCount: number, totalCount: number) {
    const message = `Exporting ${showingCount} of ${totalCount}`;
    this.snackBar.open(message, '', {duration: TOAST_DURATION_MS});
  }

  private displayLayerFailedToLoad(layerName: string | null) {
    this.snackBar.open(`Could not load ${layerName || 'requested'} layer`, '', {
      duration: TOAST_DURATION_MS,
    });
  }

  private getRows(columns: string[], features: Feature[]): string[][] {
    const rows = [];
    for (const row of features) {
      const newRow = [];
      for (const columnName of columns) {
        newRow.push(this.getCell(columnName, row));
      }
      rows.push(newRow);
    }
    return rows;
  }

  private saveSettings() {
    if (this.selectedColumnNames.length === 0) {
      return;
    }
    this.tableService.setSelectedColumnsForLayer(this.layerId, this.selectedColumnNames);
    this.tableService.saveSelectedColumnState();
  }

  private hasCachedFeatures(): boolean {
    return this.paginationCachingService.getFeaturesForLayer(this.layerId).length > 0;
  }

  private sendExportOptionsEvent(columnNames: string[]) {
    for (const columnName of columnNames) {
      this.analyticsService.sendEvent(EventActionType.TABLE_EXPORT_COLUMN_SELECTED, {
        event_category: EventCategoryType.TABLE,
        event_label: columnName,
      });
    }
  }

  private resizeThumbnails() {
    if (window.innerWidth < MAX_MOBILE_WIDTH_PX) {
      this.thumbnailImageUrlOptions = {
        height: 96,
        width: 96,
      };
    } else {
      this.thumbnailImageUrlOptions = {
        height: THUMBNAIL_SIZE_PX,
        width: THUMBNAIL_SIZE_PX,
      };
    }
  }

  private layerFiltersUpdates(): Observable<FilterUpdate> {
    return this.layersFilterService.layerFiltersUpdated().pipe(
      filter((update: FilterUpdate) => update.layerId === this.layerId),
      takeUntil(this.destroyed),
    );
  }

  private getRelatedLayerIdByName(features: Feature[]): Map<string, string> {
    const relatedLayerIdByName = new Map<string, string>();
    for (const feature of features) {
      const relatedFeatures = getAllRelatedFeatures(feature);
      for (const relatedFeature of relatedFeatures) {
        const layerName = this.layersService.getLayerName(relatedFeature.layerId);
        // The case where a layer name isn't found from a related feature
        // is when said related feature is of LayerType IMAGE. The IMAGE
        // layer has essentially been replaced by the image group layer
        // which is why it is filtered out here:
        // http://google3/googlex/refinery/viaduct/gridaware/frontend/services/layers_service.ts;l=168;rcl=387836888
        // TODO(b/226392944): Decide if old image layer related features
        // should be shown in the table.
        if (!layerName) {
          continue;
        }
        relatedLayerIdByName.set(
          `${RELATED_FEATURE_LAYER_PREFIX} ${layerName}`,
          relatedFeature.layerId,
        );
      }
    }
    return relatedLayerIdByName;
  }
}

function sortTags(feature: Feature): Feature {
  const cloned = feature.clone();
  cloned.tags = cloned.tags.slice().sort((tagA: Tag, tagB: Tag) => {
    if (tagA.name < tagB.name) return -1;
    if (tagA.name > tagB.name) return 1;
    return 0;
  });
  return cloned;
}

function caseInsensitiveAlphaSort(a: string, b: string) {
  a = a.toLowerCase();
  b = b.toLowerCase();
  return a < b ? -1 : a > b ? 1 : 0;
}
