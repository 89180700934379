@if (!isImageBlock) {
  <ng-container class="asset-confirmation">
    <div class="row-confirmation">
      <div class="asset-confirmation-header">{{ header }}</div>
      <div class="asset-edit-link">
        <a role="button" class="edit-link" (click)="editFeature()"> Edit </a>
      </div>
    </div>
    @for (property of featureProperties; track property) {
      <div>
        <div class="row-confirmation">
          <div class="asset-key">{{ property.key }}</div>
          <div class="asset-value">{{ property.propertyValue.value }}</div>
        </div>
      </div>
    }
  </ng-container>
} @else {
  <ng-container class="image-confirmation">
    <div class="asset-confirmation-header">{{ header }}</div>
    @for (image of images; track image; let i = $index) {
      <div class="row-confirmation">
        <div class="asset-key">Image {{ i + 1 }}</div>
        <div class="asset-image-box">
          <img class="thumbnail" [src]="image.url" [alt]="image.name" />
        </div>
        <div class="asset-edit-link">
          <a role="button" class="edit-link" (click)="editFeature()"> Edit </a>
        </div>
      </div>
    }
  </ng-container>
}
