<ng-container class="section">
  <views
    [currentView]="currentView"
    [selectedViewId]="selectedViewId"
    (onClearFilters)="clearAllFilters()">
  </views>
</ng-container>
@if (visibilityByLayerId) {
  @for (layer of layers; track layer) {
    <div class="section">
      <div class="layer-name-toggle-container">
        <div class="layer-name">
          {{ layer.name }}
          @if (showUploadButton(layer)) {
            <button
              mat-stroked-button
              color="accent"
              class="upload-photo layer-action-button"
              aria-label="Upload images"
              matTooltip="Upload images"
              i18n-aria-label
              [routerLink]="goToPhotoUpload()">
              <mat-icon>add_a_photo</mat-icon>
              Upload
            </button>
          }
        </div>
        <div class="layer-button-group">
          @if (showZoomToResultsButton(layer).visible) {
            <button
              mat-icon-button
              class="location-searching"
              matTooltip="Zoom to results"
              (click)="zoomToResults(layer)"
              [disabled]="!showZoomToResultsButton(layer).enabled">
              <mat-icon fontSet="material-icons-outlined" class="icon">location_searching</mat-icon>
            </button>
          }
          <mat-slide-toggle
            (change)="toggleLayer(layer)"
            [name]="layer.id"
            [aria-label]="setAriaLabel(layer.name, !!layer.id)"
            [checked]="isVisible(layer)"
            class="toggle"
            color="primary">
          </mat-slide-toggle>
        </div>
      </div>
      @if (layer.id === assetLayerId && isVisible(layer)) {
        <div class="legend">
          <asset-image-filters
            [layerId]="getLayerId(layer)"
            (editingChange)="onEditingChanged($event)">
          </asset-image-filters>
        </div>
      }
      @if (isVisible(layer)) {
        <div class="legend">
          <map-layers-legend [layerName]="layer.name" [layerType]="layer.layerType">
          </map-layers-legend>
        </div>
        @if (layerFiltersEnabled(layer)) {
          <div class="filter-section">
            <layer-filters
              [layerId]="layer.id"
              [layerStyle]="layer.layerStyle || null"
              (editingChange)="onEditingChanged($event)">
            </layer-filters>
          </div>
        }
        @if (configService.solar2Enabled && layer.id === solarInsightsLayerId) {
          <div>
            <range-filter></range-filter>
          </div>
        }
        @if (shouldEnableTags(layer)) {
          <div class="filter-section border-top">
            <tag-filters [layerId]="layer.id"></tag-filters>
          </div>
        }
        @if (shouldShowRecencyLegend(layer)) {
          <div class="streetview-recency-container">
            <div class="labels">
              <div class="label-left">Older</div>
              <div class="label-right">Recent</div>
            </div>
            <div class="streetview-recency-legend"></div>
            <div class="controls">
              <div class="label">Opacity</div>
              <mat-slider min="0" step="0.1" max="1" discrete>
                <input
                  matSliderThumb
                  [value]="recencyService.getOpacity()"
                  (valueChange)="recencyService.onOpacityChange($event)" />
              </mat-slider>
            </div>
            <div class="controls">
              <div class="label">Radius</div>
              <mat-slider min="10" step="1" max="100" discrete>
                <input
                  matSliderThumb
                  [value]="recencyService.getRadius()"
                  (valueChange)="recencyService.onRadiusChange($event)" />
              </mat-slider>
            </div>
          </div>
        }
        @if (!(networkService.offline$ | async) && shouldEnableAnnotations(layer)) {
          <div class="filter-section border-top">
            <annotation-filters [layerId]="layer.id" [reset]="reset"> </annotation-filters>
          </div>
        }
      }
    </div>
  }
}
